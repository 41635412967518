body {
  position: relative; /* or any other positioning value you want */
  /* Other CSS styles for the body */
}

.custom-non-flex {
  display: block; /* or any other non-flex display type */
}

.myCalender {
  display: flex;
  align-items: center;
  padding: 12px 18px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-top: 24px;
  padding-bottom: 1px;
  height: 43vh;

  .img {
    width: 150px;
    pointer-events: none;
  }

  .content {
    padding: 12px 5px;

    h3 {
      color: #000;
      font-size: 24px;
      font-weight: 600;
    }
    
.card-member {
  transition: background-color 0.3s;
}

.card-member:hover {
  background-color: #f0f0f0; 
}

.card-member.selected {
  background-color: #d0d0ff; 
}

    .actions {
      :global(.btn) {
        color: #b1afaf !important;
      }
    }
    .vl{
        border: 10px solid #F0F0F2;
    }

    ul {
      color: #545454;
      font-size: 18px;
      margin: 12px 0;

      li {
        display: block;
        color: #545454;
        font-size: 16px;
        font-weight: 400;
      }
    }

    p {
      color: #545454;
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.appointments {
  margin-top: 24px;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .actions {
      display: flex;
      align-items: center;
      //z-index: 750;
      margin-right: 5rem;

      .calendarSwitch {
        margin-top: 12px;
        margin-right: 10px;

        :global(.btn) {
          background: #efefef !important;
          border-color: #ccc !important;

          &:global(.active) {
            background: #d2d2d2 !important;
          }
        }
      }
    }
  }

  .datatable {
    margin-top: 18px;
  }

  .calenderView {
    :global(.fc-button-primary) {
      background-color: #00b598 !important;
      border-color: #00b598 !important;
    }

    :global(.fc-header-toolbar) {
      position: absolute;
      top: 0;
      z-index: 500;
      width: 50%;
    }

    :global(.fc-today-button),
    :global(.fc-toolbar-chunk):nth-child(3) {
      display: none;
    }

    :global(.fc-toolbar-chunk):nth-child(2) {
      position: absolute;
      top: 5px;
      left: 18%;
    }

    :global(.fc-daygrid-event.fc-event) {
      background-color: #01987f !important;
      border-color: #01987f !important;
    }
  }
}

.userCalender {
  display: flex;
  flex-direction: column;
}
.carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-control-prev,
  .carousel-control-next {
    flex: 0 0 auto;
    width: 35px; 
    height: 35px; 
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa; 
    color: #007bff;
    border: none; 
    outline: none; 
    cursor: pointer;
    margin-bottom: 10px;
  }

.carousel-control-prev {
  left: 10px;
}
.custom-next-prev{
    left: -10px;
    background-color: #f8f9fa;
}
.custom-card-title {
  font-weight: bold; 
  font-size: large;
}

.carousel-control-next {
  right: 10px;
}

.carousel-inner {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.prev-Data{
    top: -20px;
}

.card-member {
  padding: 10px;
  background-color: #1212ff; /* Default background color */
  transition: background-color 0.3s;
}

.card-member:hover {
  background-color: #f0f0f0; /* Highlight color on hover */
}

.card-member.selected {
  background-color: #d0d0ff; /* Highlight color for selected card */
}

#searchAppointment {
  margin-left: 4rem;
  display: flex;
}

.appointmentsCalendar{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .appointmentCard{
    &:hover{
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    .appointmentCardHeader{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 6px;
    }
    
    .appointmentCardBody{
      padding: 8px 10px;
      .availableDates, .availableTime, .unavailableDates{
        display: flex;
        // align-items: center;
        font-size: smaller;
        font-weight: 500;
        margin-bottom: 8px;
        color: #464b51d0;
      }
      
      .noUnavailableDates{
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #464b51d0;
      }
    }
  }
}


.appointmentHeader{
  margin-top: 1rem;
}