$dark-theme: #41465e;
$primary-dark: #00b598;

.Draft_card {
  border: 2px solid rgba(250, 194, 42, 0.412);
  border-radius: 4px;
}

.Publish_card {
  border: 2px solid rgba(110, 199, 123, 0.509); /* Make border transparent and use border-image for gradient */
  border-radius: 4px;
}

.Paused_card {
  border: 2px solid rgba(217, 11, 11, 0.236); /* Make border transparent and use border-image for gradient */
  border-radius: 4px;
}

@media only screen and (min-width: 315px) and (max-width: 480px) {
  .sequenceContainer{
    .sequence-header{
      padding: 0;
      width: 100% !important;
      
      .form-handlers{
        button{
          font-size: smaller !important;
          width: fit-content !important;
          padding: 4px;
  
          #cancel-icon{
            display: none !important;
          }
        }
      }
    }


    .sequence-main-header{
      padding: 0.5rem !important;

      #seq-profile-icon{
        display: none !important;
      }
    }

    .sequence-event{
      margin: 1rem 0 0 0 !important;
    }

    .condition-button{
      display: block !important;
    }

    
    .addFlow-accordian{
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
  }
}

@media only screen and (min-width: 748px) and (max-width: 1024px) {
  .sequence-header{
    width: 100% !important;
  }
  .custom-field{
    width: 10rem !important;
  }

  .sequence-main-header{
    padding: 1rem !important;

    #seq-profile-icon{
      display: none !important;
    }
  }

  .addFlow-accordian{
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

}
/////////Sequence Dub
.sequenceContainer {
  padding-bottom: 5rem;
  list-style: none;
  .x-mark{
    color:$primary-dark;
    cursor: pointer;
    z-index: 10;
  }

  #assignOption{
    margin-top: 9px;
  }


  input[type="time"] {
    font-size: smaller !important;
    height: 1.8rem;
  }

  .sequence-form {
    height: max-content;

    .sequence-header{
      position: fixed;
      background: white;
      z-index: 100;
      width: 82%;
      padding-top: 1rem;

      .form-headers {
        display: flex;
      }


      










  
      .form-handlers {
        display: flex;
        gap: 0.5rem;
  
        .form-handler-icon {
          color: white;
          background: $primary-dark;
          border-radius: 8px;
          cursor: pointer;
          width: 9rem;
          font-size: small;
          border: none;
  
          svg {
            margin-right: 4px;
          }
        }
  
        .form-handler-icon-cancel {
          color: black;
          background: #fbf8f8;
          border: 1px solid #e2e2e2 !important;
          border-radius: 8px;
          cursor: pointer;
          width: 9rem;
          font-size: small;
          border: none;
  
          svg {
            margin-right: 4px;
          }
        }
      }

    }

    // input[type="radio"] {
    //   appearance: none;
    //   background-color: #f8f9fa;
    //   border: 1px solid #ced4da;
    //   border-radius: 50%;
    //   width: 12px;
    //   height: 12px;
    //   margin-right: 8px;
    //   outline: none;
    //   transition: background-color 0.15s, border-color 0.15s;
    // }

    // input[type="radio"]:checked {
    //   background-color: $primary-dark;
    //   border-color: $primary-dark;
    // }

    // input[type="radio"]:hover {
    //   border-color: $primary-dark;
    // }

    // input[type="radio"]:focus {
    //   box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    // }
    input[type="radio"] {
      width: 15px;
      height: 15px;
      border: 1px solid #e2e2e2;
      border-radius: 100%;
      background-color: #fbfbfb;
    }

    input[type="radio"]:before {
      content: "";
      display: block;
      width: 30%;
      height: 30%;
      border-radius: 100%;
    }

    input[type="radio"]:checked:before {
      background-color: #ffffff;
      padding: 4px;
      border: 4px solid #0172cb;
      width: 15px;
      height: 15px;
    }

    input[type="checkbox"] {
      accent-color: #009980;
    }

    .custom-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 1rem;

      label {
        margin: 0;
      }
    }

    .custom-field {
      width: 15rem;
    }

    .sequence-main-header {
      background-color: white;
      padding-left: 5rem;
      padding-right: 5rem;
      margin-top: 4rem;

      .accordion-button {
        background-color: #fffff0 !important;
        color: $dark-theme;
        padding: 0.5rem 1rem;
      }

      .accordion-button:focus {
        border-color: white !important;
        box-shadow: white !important;
      }
      .collapsed {
        background-color: #fffff0 !important;
        color: $dark-theme !important;
      }

      .accordian-header {
        h6 {
          margin: 0;
        }
        small {
          font-size: small;
        }
      }

      .sequence {
        border-bottom: 1px solid rgb(224, 224, 224);
      }

      .sequence-event {
        margin-top: 1rem;
        padding: 1rem;
        background: none;
        border-radius: 1rem;
        border: 1px solid rgb(224, 224, 224);
        margin-left: 2rem;
        margin-right: 2rem;

        .accordion-button:not(.collapsed)::before,
        .accordion-button:not(.collapsed)::after {
          pointer-events: all;
        }

        .conditions {
          border-radius: 30px;

          .custom-label {
            font-size: smaller;
          }

          .condition-button {
            display: flex;
            justify-content: center;
            align-items: center;
            button {
              background: $primary-dark;
              border: none;
              color: white;
              padding: 4px 8px;
              font-size: smaller;
              border-radius: 4px;
            }
          }

          .sequence-variables {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .variables {
              overflow-x: scroll;
              background: #edf2f7;
              padding: 4px;
              border-radius: 4px;
              margin-right: 6px;
              font-size: smaller;

              .field {
                color: #00b5d8;
                margin-right: 4px;
              }

              .filter {
                color: #00b598;
                margin-right: 4px;
              }

              .value {
                white-space: nowrap;
                overflow-x: auto; /* Enable horizontal scrolling */
              }

              .remove-variable {
                cursor: pointer;
                margin-left: 2px;
              }
            }
          }

          .funnel-icon {
            // margin-left: 2.2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .funnel-button {
              padding: 3px 18px;
              background: none;
              border: none;
              display: flex;
              width: max-content;
              padding: 0;

              &:hover {
                background: #c6f6d5;
              }
            }
          }
        }
      }

      //delivery-preference
      .delivery-preference {
        margin-top: 1rem;
        margin-left: 1.3rem;
      }
    }

    .sequenceFlows {
      .accordion-button {
        background-color: white !important;
        color: $dark-theme;
        padding: 0.5rem 1rem;

        :focus {
          border-color: none !important;
          box-shadow: none !important;
        }
      }
      .collapsed {
        background-color: white !important;
        color: $dark-theme !important;
      }

      .flow-arrow {
        .col-12 {
          display: flex;
          justify-content: center;
          align-items: center;

          .sequence-time {
            background: #00b598;
            color: white;
            padding: 4px 8px;
            border-radius: 20px;
            margin: 0;
          }
        }
      }

      .addFlow-accordian {
        padding-left: 4.2rem;
        padding-right: 4.2rem;

        .waring-alert{
          padding: 5px;
          background-color: #FFF5DC;
          color: #A88300;
          overflow-wrap: break-word;
          display: flex;
          align-items: center;
          margin: 0px;
          border-radius: 5px;
          font-size: 12px;
        }

        #accordion_header{
          button{
            cursor: default;
          }
        }


        .flow-accordian-header {
          display: flex;
          justify-content: space-between;
          width: 95%;

          .operations {
            display: flex;
            align-items: center;

            .form-err{
              color: #ff6565;

              svg{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 3px;
              }
              .err-text{
                font-size: x-small;
                margin-top: 2px;

              }
            }

          }
        }

        .addflow-form {
          padding: 0.5rem;
          .select-forms {
            padding: 0.5rem;

            .send-after,
            .send-message,
            .delivery {
              margin-bottom: 1rem;
            }

            .template-button {
              background: $primary-dark;
              color: white;
              font-size: smaller;
              display: flex;
              border: none;
              padding: 6px 6px;
            }
          }

          .bizContentShow {
            max-height: 248px;
            overflow: scroll;
            min-height: 248px;
            margin: 0;
          }
        }
      }

      .add-squence-flow {
        margin-top: 0.5rem;
        .col-12 {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  //col-1
  // .form-handler {
  //   height: 45vh;
  //   overflow: hidden;
  //   position: fixed; /* Make the div fixed */
  //   top: 0;
  //   right: 0;

  //   .row {
  //     padding: 1rem;
  //     // margin-right: 1rem;
  //     .col-12 {
  //       display: flex;
  //       justify-content: end;
  //       align-items: center;
  //       margin-bottom: 8px;

  //       .icon {
  //         color: white;
  //         background: $primary-dark;
  //         border-radius: 8px;
  //         cursor: pointer;
  //         width: 9rem;
  //         font-size: small;
  //         border: none;

  //         svg {
  //           margin-right: 4px;
  //         }
  //       }

  //       .icon-cancel {
  //         color: black;
  //         background: #fbf8f8;
  //         border: 1px solid #e2e2e2 !important;
  //         border-radius: 8px;
  //         cursor: pointer;
  //         width: 9rem;
  //         font-size: small;
  //         border: none;

  //         svg {
  //           margin-right: 4px;
  //         }
  //       }
  //     }
  //   }
  // }
}
.sequenceLogsEdit {
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(224, 224, 224);
  background: $primary-dark;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  line-height: 2px;
  margin-bottom: 6px;
  padding: 8px;

}
