@mixin cancelBtnStyle {
    $global-bg-color: #FBF8F8;
    $global-border-color: #E2E2E2;

    background-color: $global-bg-color !important;
    border: 1px solid $global-border-color !important;
    color: #000 !important;
    border-radius: 4px;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    font-size: 1em !important;
    width: 148px;
    margin-right: 10px;
    color: #3B3E45 !important;

    &:hover {
        color: #000;
        border: 2px solid $global-border-color;
        background-color: $global-bg-color;
    }

    &:focus {
        color: #000 !important;
        box-shadow: none !important;
        border: 2px solid $global-bg-color;
        background: $global-bg-color;
    }

    &:active {
        background: $global-bg-color !important;
        color: #000 !important;
        border: 1px solid $global-border-color !important;
    }
}

@mixin cancelBtn {
    :global(.btn.btn-outline-dark) {
        @include cancelBtnStyle;
    }
}