.row {
    display: flex;
}

.row .typeBtn {
    display: flex;
    margin-right: 12px;
}

.typeBtn:hover,
.typeBtn:focus {
    background-color:#458d80;
    outline: none;
}

.typeBtn .dummyRadio :global(.form-check-input) {
    position: static !important;
    margin-left: 5px;
    margin-right: 18px;
}

.typeBtn .dummyRadio :global(.form-check-input:checked) {
    background-color: #458d80 !important;
    border-color: #458d80 !important;
}

.typeBtn:global(.btn-outline-danger) {
    border-color: rgba(201, 201, 201, 1) !important;
    color: rgba(201, 201, 201, 1) !important;
}

.typeBtn:global(.btn-outline-danger) .dummyRadio :global(.form-check-input:checked) {
    background-color: rgba(201, 201, 201, 1) !important;
    border-color: rgba(201, 201, 201, 1) !important;
}

#linkButton {
    margin-left: 10px;
    width: 30%;
}

#uploadButton {
    margin-left: 10px;
    width: 30%;
}

#linkButton:focus {
    background-color: #458d80 !important;
}

#uploadButton:focus {
    background-color: #458d80 !important;
}

#highLight {
    background-color: #458d80 !important;
    margin-left: 10px;
    width: 30%;
}