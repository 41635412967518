@import "../styles/mixins";

$sidebar-width: 400px;

.sidebarContainer {
  position: relative;
  padding: 0 10px;
  background-color: #fcfcfc;

  .sidebarCaption {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .caption {
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
      }

      .caption {
        font-weight: bolder;
        font-size: 16px;
      }
    }

    button {
      cursor: pointer;
      border: 0;
      background-color: transparent;
      border-radius: 50%;
      font-size: 1.5em;
      transition: all ease-out 500ms;
      padding: 5px;
      width: 45px;
      height: 45px;
      margin-left: 6px;

      &:hover {
        background-color: #f8f8f8;
        transition: all 300ms ease;
      }

      &.opened {
        background-color: #efefef;
      }
    }
  }

  .collapseIcon {
    position: absolute;
    top: 80px;
    right: 0;
    color: #FF7342;
    border: 0;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    font-size: 1.7em;
    padding: 4px 10px;
    z-index: 1000;
    transition: all ease-out 600ms;
  }

  .sidebar {
    height: 100%;
    width: $sidebar-width;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: white;
    overflow-x: hidden;
    padding-top: 30px;
    padding: 10px;
    transition: all ease-out 600ms;

    &.collapse {
      transform: translateX($sidebar-width);
    }

    @include lowResolution {
      width: 300px;
      padding-top: 45px;
    }
  }
}

.backBtn {
  padding: 0.375rem 0.75rem;
  margin-top: 1.2rem;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 375px);
  background-color: #fcfcfc;
  padding: 5px 16px;
  transition: all ease-out 600ms;
  margin-top: 12px;
  position: sticky;

  &.collapseSidebar {
    width: 100% !important;
  }
}

.header .content {
  display: flex;
  align-items: center;
}

.content button {
  background-color: transparent !important;
  border: 0;
}

.content div {
  margin-left: 10px;
}

.header .flowName {
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;

  @include lowResolution {
    font-size: 14px;
  }
}

.header {
  .editFlow {
    @include lowResolution {
      font-size: 10px;
    }
  }
}

.header .keyword {
  font-size: 14px;
  color: #777;

  @include lowResolution {
    font-size: 10px;
  }
}

.buttons {
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  display: flex;

  .lastPublished {
    img {
      width: 22px;
      cursor: pointer;
      pointer-events: none;
    }
  }

  @include lowResolution {

    :global(.btn),
    a {
      font-size: 12px !important;
    }
  }
}

.buttons button {
  display: block;
}

.buttons a.verifyBot {
  display: block;
  padding: 5px 12px;
  text-align: center;
  color: #007EC3;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.buttons a.verifyBot:hover,
.buttons a.verifyBot:active {
  color: #015d8f;
}

.backBtn {
  color: black !important;

  @include lowResolution {
    font-size: 12px;
  }
}

.publishStatus {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @include lowResolution {
    font-size: 10px;
  }
}

/* Element list */
.container {
  width: 100%;
}

.container .disable {
  background-color: #efefef !important;
  cursor: not-allowed !important;
}

.icon {
  display: flex;
  font-size: 2em;
  align-items: center;
}

.details {
  flex-grow: 1;
}

.details p,
.title {
  margin: 0;
}

.title {
  font-size: 18px;
  margin-bottom: 5px;
}

/* New add element styles */
.addElementCard {
  background-color: white !important;
  color: var(--primary);
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 1rem;
  padding: 10px 12px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  transition: all 300ms ease;

  @include lowResolution {
    font-size: 0.7rem;
  }
}

.addElementCard.selected {
  background-color: var(--primary) !important;
  color: white;
}

.addElementCard h3 {
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
  font-size: 1rem;

  img {
    @include lowResolution {
      width: 16px;
    }
  }

  @include lowResolution {
    font-size: 0.8rem;
  }
}

.addElementCard.iconCard {
  text-align: center;
  cursor: pointer;
}

.addElementCard.iconCard.unselected {
  background-color: white !important;
  color: var(--primary);
  border: 1px solid #F0F7F7;
}

.addElementCaption {
  color: #728D9B;
  font-size: 1.2rem;
  font-weight: 600;

  @include lowResolution {
    font-size: 0.8rem;
  }
}

.floatWarning {
  animation: float 1s ease-in-out infinite;
  color: red;
  font-size: 16px;
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 7px;
  cursor: pointer;
  position: relative;
  z-index: 0;
  width: 20px;
  height: 20px;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.botName {
  font-size: 18px;
  font-weight: bold;
  background-color: #caefd0;
  padding: 7px;
  border-radius: 5px;
}

.botSVG {
  margin-bottom: 4px;
}

.ticketing {
  font-size: 25px;
  color: #5d8ca6;
}

.date {
  margin-bottom: 0px;
  margin-top: 10px;
}

.botlibraryList {
  display: flex;
  justify-content: space-between;
  width: 100%;

  div {
    flex: 1;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  div:nth-child(1) {
    flex-basis: 40%;
  }

  div:nth-child(2) {
    flex-basis: 40%;
  }

  div:nth-child(3) {
    flex-basis: 20%;
  }

}

.implementBot {
  float: right;
  width: 14%;
  padding: 10px;
  margin-left: 10px;
}

.libraryHeaderColor {
  color: #007ec3;
  font-weight: 500;
}

.correctButton {
  background-color: #c6f5c0;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 5px;
}