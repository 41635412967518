@import "./mixins";
@import "../../common/common.scss";

* {
  font-family: "Poppins", sans-serif !important;
}

.react-flow__panel.react-flow__attribution,
#react-flow__minimap-desc-1 {
  display: none !important;
}

.react-date-picker__inputGroup {
  width: 100%;
}

.react-date-picker__wrapper {
  border: none;
}

// Bot flow table
.table.botflows-table {
  @include lowResolution {
    font-size: 8px !important;
  }

  thead th {
    font-weight: 500;
  }

  tbody {
    background-color: white;
  }

  th {
    background-color: transparent !important;
  }

  td,
  th {
    text-align: left !important;
  }

  tbody {
    background-color: white !important;

    tr {
      background-color: white !important;

      td {
        background-color: white !important;
        color: #2E3967;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a,
        button {
          color: #2E3967 !important;
        }
      }
    }
  }
}

.btn {

  &.btn-primary,
  &.btn-primary:focus {
    color: white;
  }

  &.icon-btn {
    padding: 5px 10px !important;
  }
}

.dimmed {
  color: #A5A5A5;
}

.required {
  color: rgba(210, 37, 37, 1);
  font-weight: bold;
}

.addButton {
  border: none !important;
  background-color: rgba(0, 126, 195, 1) !important;
  border-radius: 50% !important;
  font-size: 1rem !important;
}

.deleteButton,
.deleteSmallButton {
  background-color: rgba(255, 101, 101, 1) !important;
  color: white;
  border-radius: 50% !important;
}

.deleteButton {
  height: 40px;
}

.form-check-input:checked {
  background-color: $submit-bg-color !important;
  border-color: $submit-bg-color !important;
}

// autosuggest
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #dee2e6;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  z-index: 2;
  border-radius: 8px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  display: block;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.minusRemoveBtn {
  color: rgba(255, 101, 101, 1) !important;
  font-size: 1.3rem;
  border: none !important;
  outline: none !important;
}

.tooltip {
  z-index: 1200 !important;
}

.react-flow__handle {
  // background: #5D8CA6;
  background: #51a397;
}

.react-date-picker__clear-button {
  display: none !important;
}