.grid {
    display: flex;
    margin-top: 12px;
    margin-bottom: 5px;
}

.input {
    margin-right: 10px;
    width: 300px;
}

.inputgrp,
.inputgrp .searchicon {
    background-color: white !important;
}

.inputgrp .searchicon,
.inputgrp .searchinput {
    border: 0;
}

.inputgrp {
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title .back {
    margin-right: 12px;
}

.title h1 {
    font-size: 1.5em;
    font-weight: 600;
}

.crBtn{
    background: #00B598;
    border: #00B598;
    margin-top: 10px;
    color: #fff;
    box-shadow: none;
    font-size: 14px;
    margin-top: 1px;
    padding: 9px 15px;
}

.crBtn:hover {
    background: #458d80;
    border: #458d80;
    color: #fff;
    box-shadow: none;
    font-size: 14px;
  }
  
  .crBtn:focus {
    background: #00B598;
    border: #00B598;
    color: #fff;
    box-shadow: none;
    font-size: 14px;
  }
  
  .crBtn:active {
    background: #00B598 !important;
    border-color: #00B598 !important;
    color: #fff !important;
    box-shadow: none !important;
    font-size: 14px !important;
  }
  
  .crBtn:disabled {
    background: #86b5ac;
    border: #86b5ac;
    color: #fff;
    box-shadow: none;
    cursor: not-allowed;
  }

  .botName {
    background-color: #caefd0;
    padding: 7px;
    border-radius:  5px;
    margin-left: 10px;
  }

  .botSVG {
    margin-bottom: 4px;
  }

  .container {
    display: flex; /* Use flexbox to arrange items in a single row */
    justify-content: space-between; /* Push items to opposite ends */
    align-items: center; /* Align items vertically in the center */
  }

  .filterFlows {
    padding-right: 10px;
    width: 230px;
  }

  .searchLogs {
    position: relative;
    top: 70px;
    right: 240px;
  }