.edgebutton {
    font-size: 12px;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    border-radius: 50%;
    transition: opacity 0s;
    user-select: none;
    border: none;
    display: inline-block;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background-color: white;
}