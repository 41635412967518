

.custom-link {
    color: #4a91d4;
    font-weight: 500;
    margin-left: 5px;
}

.custom-link:hover, 
.custom-link:focus, 
.custom-link:active {
    color: #4a91d4;
    text-decoration: underline;  
}

.disabled-link {
    color: lightblue;
    font-weight: 500;
    margin-left: 5px;
}

.checkIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchLocation {
    margin-left: -17px;
    margin-right: 70px;
    margin-bottom: 4px;
}

.latLon {
    margin-left: -17px;
    margin-bottom: 4px;
}

.viewMap {
    font-weight: 500;
    margin-left: 5px;
}
