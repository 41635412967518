.plusDiv {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;
    width: 220px;
    height: 187px;
    margin-bottom: 30px;
    margin-top: 1rem;
}


.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.noPointer {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

.plus {
    display: inline-block;
    width: 195px;
    height: 185px;
    color: #337ab7;
    text-align: center;
    line-height: 185px;
    border-radius: 5px;
    font-size: 17px;
    background-color: #fff;
    border: 1px solid;
    margin-top: 3px;
}

.addKnowledge {
    margin-top: 14px;
    margin-bottom: 30px;
}

.card {
    width: 220px;
    height: 190px;
    cursor: pointer;
}

.cardHeader {
    background-color: #f0f0f0;
    position: relative;
    padding: 10px;
}

.batch {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popover {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 10px;
    z-index: 1;
}

#popoverBody {
    border-bottom: 1px solid #e2e2e2;
}

.folderName {
    text-align: "left";
    font-weight: "bold";
}

.cardFooter {
    background-color: #f0f0f0;
    position: relative;
    padding: 15px;
}

.arrowDiv {
    cursor: pointer;
    margin-top: 5px;
}

.arrow {
    font-size: 25px;
}

.status {
    font-size: 16px;
    margin-right: 10px;
    position: absolute;
    margin-top: 12px;
    margin-left: 4%;
}

.dropArea {
    border: 1px dashed #000;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 96%;
    margin: auto;
}

.dropText {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
}

.allowedType {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
}

.uploadFiles {
    background-color: #007ec3;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    display: inline-block;
}

#left {
    margin-left: 10px;
}

#relative {
    position: relative;
}

.fileDiv {
    position: relative;
    display: inline-block;
    width: 120px;
}

.faTimes {
    position: absolute;
    top: -10px;
    right: 5px;
    cursor: pointer;
    color: grey;
    font-size: 18px;
}

.image {
    width: 60px;
    height: 60px;
}

.file {
    margin-top: 10px;
    width: 120px;
    word-break: break-word;
    overflow-wrap: break-word;
    text-align: center;
}