$global-bg-color: #FBF8F8;
$global-border-color: #E2E2E2;

.modal-content {

    // width: 1050px;
    .cardStyle {
        cursor: pointer;
        border: 2px solid rgb(197, 189, 189);
    }

    .cardStyleActive {
        cursor: pointer;
        box-shadow: 0 2px 10px;
    }
}

//-*---------------*--  Date CONTAINER -*---------------*-//

.DateContainer {
    .custom-clear-icon {
        font-size: 20px;
    }

    input {
        width: 120px;
    }

    .DateTimePicker {
        word-spacing: 20px;
        text-align: center;
        // background-color: #007EC3;
        // color: white;
    }

}

.datetime-picker__wrapper {
    border: none !important;
}

.react-datetime-picker {
    border: 2px solid #E2E2E2;
    border-radius: 5px;
    padding: 2px;
}

.react-datetime-picker__wrapper {
    border: none !important;
}

.alignInfo {
    position: relative;
    top: 2.5px;
}

.custom-tooltip {
    padding: 5px;
}

//-*---------------*--  EXCEL COMMUNITY CONTAINER -*---------------*-//


//Right sidebar //
.rightSidebar {
    .sidebarBtnCon {
        position: absolute;
        bottom: 0;
        right: 0;

        button {
            font-size: 12px;
        }
    }

    .text-danger {
        font-size: 11px;
    }

}

//-*---------------*--  LOADING CONTAINER -*---------------*-//

/* common.scss */

.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Adjust this to your layout needs */
}

.load {
    width: 100px;
    height: 100px;
    padding: 2%;

    img {
        width: 100%;
        height: auto;
        margin-bottom: 10px;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

//-*---------------*--  SEND CANCEL & UPLOAD SELECT-*---------------*-//
$cancel-color: #3B3E45;
$submit-bg-color: #00B598; // #FF7342;
$submit-color: #ffffff;
$clear-button: #E5F6FF;
$submit-bg-hover-color: #458d80;
$disable: #86b5ac;
$uploadColor: #007ec3;

.cancelButton {
    background-color: $global-bg-color;
    border: 1px solid $global-border-color !important;
    font-size: 1em;
    color: $cancel-color !important;
    border-radius: 4px;
    width: 148px;
    padding: 8px 12px;

    &:hover {
        color: $cancel-color;
        border: 2px solid $global-border-color;
        background-color: $global-bg-color;
    }

    &:focus {
        color: $cancel-color !important;
        box-shadow: none !important;
        border: 2px solid $global-bg-color;
        background: $global-bg-color;
    }

    &:active {
        background: $global-bg-color !important;
        color: $cancel-color !important;
        border: 1px solid $global-border-color !important;
    }

    &:disabled {
        background: $disable !important;
        color: $cancel-color !important;
        border: 1px solid $disable !important;
    }
}

.sendButton {
    color: $submit-color;
    background-color: $submit-bg-color !important;
    background: $submit-bg-color;
    border: $global-border-color ;
    font-size: 1em;
    border-radius: 4px;
    width: 148px;
    padding: 8px 12px;

    &:hover {
        background-color: $submit-bg-hover-color !important;
        cursor: pointer;
        color: $submit-color;
    }

    &:focus {
        background: $submit-color;
        border: $global-border-color ;
        color: $submit-color;
        box-shadow: none !important;
    }

    &:active {
        color: $submit-color !important;
        background: $submit-bg-hover-color !important;
        border: $submit-bg-hover-color !important;

    }

    &:disabled {
        color: $submit-color !important;
        background: $submit-bg-color;
        border: $global-border-color ;
        opacity: 0.4;
        cursor: not-allowed;
        pointer-events: none;
    }
}

.clearSelection {
    color: $cancel-color;
    background-color: $clear-button !important;
    background: $submit-bg-color;
    border: $submit-bg-color;
    font-size: 1em;
    border-radius: 4px;
    width: 148px;
    padding: 8px 12px;

    &:hover {
        background-color: $clear-button !important;
        cursor: pointer;
        color: $cancel-color;
    }

    &:focus {
        background: $clear-button;
        border: $submit-color;
        color: $cancel-color;
        box-shadow: none !important;
    }
}

.upload-select {
    // width: 25%;
    padding: 5px;
    border: none;
    color: white;
    background-color: $uploadColor !important;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: $uploadColor !important;
    }

    &:focus {
        box-shadow: none !important;
    }

    &:active {
        background-color: $uploadColor !important;
    }
}

.tooltipPosition {
    z-index: 9999;
}

//-*---------------*--  TOOLTIP -*---------------*-//


#tooltip-danger {
    background: #fed7d7 !important;
    font-size: smaller;
    color: #c53030;
    padding: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

#tooltip-danger [data-popper-arrow]::before {
    border-top-color: #c53030 !important;
}

.horizontal-list {
    list-style-type: none;

    li {
        display: block;
        width: 100%;
        text-align: left;

        &:last-child {
            margin-right: 0;
        }
    }
}




//-*---------------*--  TABLE -*---------------*-//

.table-bordered thead th,
.table-bordered thead td {
    /* border-top-width: 1px; */
    padding-top: 11px;
    padding-bottom: 11px;
    background-color: #EFF4FA;
}

.commonTooltip {
    position: relative;
    z-index: 10000 !important;
}

.searchDesign {
    border: 1px solid #DCDFE3;
    width: 95%;
    // margin: -7px 0px 10px 13px;
}

//-*---------------*--  STATUS -*---------------*-//

.badgeCon {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 4px;
    color: #3B3E45;
    font-size: 14px;
    font-style: normal;
    text-align: center;

    &.pending {
        background: linear-gradient(0deg, rgba(250, 193, 42, 0.20) 0%, rgba(250, 193, 42, 0.20) 100%), #FFF;
    }

    &.completed {
        background: linear-gradient(0deg, rgba(110, 199, 124, 0.20) 0%, rgba(110, 199, 124, 0.20) 100%), #FFF;
    }

    &.failed {
        background: linear-gradient(0deg, rgba(217, 11, 11, 0.20) 0%, rgba(217, 11, 11, 0.20) 100%), #FFF;
    }

    &.error {
        background: linear-gradient(0deg, rgba(217, 11, 11, 0.20) 0%, rgba(217, 11, 11, 0.20) 100%), #FFF;
    }
}

.Draft {
    border-radius: .3em;

    background: linear-gradient(0deg, rgba(250, 193, 42, 0.20) 0%, rgba(250, 193, 42, 0.20) 100%), #FFF;
}

.Publish {
    border-radius: .3em;
    background: linear-gradient(0deg, rgba(110, 199, 124, 0.20) 0%, rgba(110, 199, 124, 0.20) 100%), #FFF;
}

.Paused {
    border-radius: .3em;
    background: linear-gradient(0deg, rgba(217, 11, 11, 0.20) 0%, rgba(217, 11, 11, 0.20) 100%), #FFF;
}

//---------------------------PAGE NOTFOUND -------------------------
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    50% {
        transform: translateX(10%);
        opacity: 0.5;
    }

    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

.pageNotFoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    text-align: center;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    animation: slideInFromLeft 2s ease-out;
    color:
        #71839b !important;
}

.pageNotFoundContainer h1 {
    font-size: 5em;
    margin: 0;
    animation: colorPulse 3s ease-in-out infinite, rotateAndScale 5s linear infinite;
    color:
        #71839b !important;
}

.popoverWidth {
    width: 300px !important;
}

.channelConnectBtn {
    padding: 9px 15px;
    border-radius: 2px;
    background: #00B598;
    border: 1px solid #00B598;
    color: #fff;
    width: 50%;
    text-align: center;
}

.channelConnectBtn:active {
    padding: 9px 15px;
    border-radius: 2px;
    background: #00B598;
    border: 1px solid #00B598;
    color: #fff;
    width: 50%;
    text-align: center;
}

.channelConnectBtn:hover {
    padding: 9px 15px;
    border-radius: 2px;
    background: #458d80;
    border: 1px solid #458d80;
    color: #fff;
    width: 50%;
    text-align: center;
}

.chDropdown {
    border: none;
    background: none;

    &:focus {
        border: none;
        background: none;
    }
}

.chDropdown:focus {
    border: none;
    background: none;
}

.subscribePage {
    width: 40%;
    margin-top: 10%;
    margin-left: 28%;

    .signUpBtn {
        margin-left: 38%;
        border-radius: 4px;
        padding: 10px;
        margin-top: 0px;
    }
}

.sub {
    .logOut {
        position: absolute;
        left: 90%;
        top: 4%;
        border-radius: 4px;
        font-weight: 900;
        color: #063ba5;
        padding: 3px 10px;
        background-color: #f7f6f7;
        box-shadow: 1px 2px rgb(145, 143, 143);
    }

    .main-logo {
        position: absolute;
        top: 2%;
        left: 1%;
    }

    .warningImg {
        margin-left: 25%;
        width: 50%;
    }
}

.globalAddButton {
    background-color: blue($color: #000000);
}

.facebook-login-button {
    background-color: #1877f2;
    border: 0;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    // font-weight: bold;
    height: 38px;
    padding: 0 24px;
    // margin-top: 12px;
}

.button-global-align {
    display: flex;
    justify-content: end;
    align-items: center;
}

.ErrorDiv {
    padding: 30px 330px;
}

// ------------------ BLUR MEMBER MODULE CONTACTS -------------------//
.numberMasking_Blur {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    color: transparent;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

// ------------------ TOOLTIP  -------------------//

.tooltip.bs-tooltip-top {
    margin-bottom: 0 !important;
    padding: 0px !important;
    /* Ensure there's no extra space below */
}

.tooltip.bs-tooltip-bottom {
    padding: 0px !important;
    margin-top: 0;
    /* Ensure there's no extra space above */
}

.setChannel {
    width: 36%;
}

.removeBorder {
    border: none;
}

.channelImg {
    width: 30%;
}

.circleGraphCtwa {
    width: 600px !important;
}