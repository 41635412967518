.holidayList {
    .holidayList__item {
        border: 1px solid #efefef;
        padding: 10px 12px;
        margin-bottom: 12px;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: #f1f1f1;

        .removeBtn {
            display: block;
            color: #ff6565 !important;
            font-size: 1.3rem;
            cursor: pointer;

            &:disabled {
                cursor: not-allowed;
                border: 0;
            }
        }
    }
}