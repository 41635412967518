// *{
//     font-family: 'public-sans' !important;
// }
$global-color: #808080;
$global-bg-color: #FBFBFB;
$global-border-color: #BBBBBB;

@media only screen and (min-width: 320px) and (max-width: 480px) {

    /* Styles for mobile portrait screens */
    #refreshIcon {
        margin-top: -35px;
        width: 30px;
        max-width: 70px;
        margin-left: 180px;
    }

    #refreshIcon .refreshIconHistory {
        width: 40px;
        margin-right: 80px;
    }

    .refresh-col {
        max-width: fit-content;
    }

    .whatsapp-submit {
        display: flex;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    /* Styles for tablet portrait screens */

    #refreshIcon {
        margin-left: -80px;
        margin-top: 5px;
    }

    #mobileNo {
        width: 40px;
    }
}

//-*---------------*-  WHATSAPP PROPFILE CONTAINER -*---------------*-//

.whatsapp-profile-container {
    .whatsappFreeTrial {
        background-color: $global-bg-color;
        padding: 2em;
        width: 50%;
        margin: auto;
        text-align: center;
        margin-top: 26px;
        border: 1px solid $global-border-color;
        border-radius: 1em;
    }

    .freeTrialHead {
        text-align: center;
        padding-top: .8em;
    }

    // .bi-clouds{
    //     background-color: #007EC3;
    // }
    margin-top: 1em;

    label {
        margin-top: 5px;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .text-danger {
        font-size: .9em;
        line-height: 2em;
    }

    .refreshIconHistory {
        background-color: white;
        border: 1px solid $global-border-color;
        padding: .1em .1rem;
        margin-left: 175px;

        &:focus,
        &:hover {
            border: 1px solid $global-border-color;
            box-shadow: none !important;
            background: white;
        }

        &:active {
            background: white !important;
            border: 1px solid $global-border-color !important;
        }
    }

    .whatsapp-submit {
        text-align: center;
        margin: 35px;
    }

    .rotate {
        animation: rotation 1s linear;
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    .whatsapp-nav {
        label {
            cursor: pointer;
            padding: 0.2rem;
        }

        .navItem {
            text-align: center;
            border-bottom: 2px solid #007EC3;
        }
    }

    .temp-btn {
        padding: 7%;
    }

    .whatsapp-img-container {
        color: #9B959F;

        .whatsapp-profile-sample {
            border-radius: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            text-transform: uppercase;
            position: relative;
            // border: 4px solid rgba(0, 126, 195, 0.50);
            vertical-align: top;
            background: $global-bg-color;
            height: 140px;
            width: 140px;
            border: 2px dashed $global-border-color;
            border-radius: 5px;
        }

    }

    .upload-file-container {
        display: flex;
        align-items: center;

        .upload-files {
            display: flex;
            justify-content: center;
            width: 30%;
            text-align: center;
        }
    }

    .profile-fields {
        margin-top: 2rem;

        label {
            padding-top: 1rem;
        }
    }

    .whatsapp-profile-img {
        height: 100px;
        width: 110px;
    }

    //-**-  CHANNEL DETAILS CONTAINER -**-//

    .channel-details-container {
        background-color: $global-bg-color;
        border: 1px solid $global-border-color;
        border-radius: 5px;
        margin-top: 0.8rem;
        padding: 1.3rem;
        height: 100%;

        .title-card {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pi-Dots {
                border: 1px solid $global-border-color;
                border-radius: 2px;
                cursor: pointer;
            }
        }

        .row {
            padding-top: .8em;
        }

        .channel-connected {
            border: 1px solid #0D8420;
            border-radius: .3em;
            padding: 0.1em 0.6em;
            color: #0D8420;
        }

        .label {
            padding-top: 2rem;
        }

        .title {
            color: $global-color;
        }

        .card {
            margin: 0px auto;
            border: none;
            padding: .8em .2em;

            .container {
                padding-left: 15px;
                padding-right: 15px;
            }

            .green-tick {
                font-size: 0.8em;
                color: #848484;
            }

            .verify-now {
                color: #FFFFFF;
                background-color: #50B260;
                border: #50B260;
                font-size: 1em;
                width: 7em;
                padding: .4em .4em;
            }

            .change {
                color: #007EC3;
                font-size: 0.8em;
            }

            .high {
                span {
                    border-radius: 50%;
                    //background-color:#F1AA21 ;
                    font-size: 4px;
                    padding: 2px 5px;
                    margin-right: 6px;
                    margin-left: 2px;
                }

                // display: flex;
                // align-items: center;
                font-size: 10px;
                // padding-left: 1%;
                // margin-right: -10%;
                //color: #F1AA21;
                // margin-left: .8em;
            }
        }
    }

}


//-*---------------*-  CAMPLAINCE  INFO CONTAINER   -*---------------*-//

.compliance-profile-container {
    .row {
        padding-top: 0.3rem;

        .col-field {
            display: flex;
            padding: 0px;
        }
    }

    .channel-mobile-field {
        display: flex;

        .channel-country-code {
            width: 20%;
            height: 40px;
            border: 1.2px solid #E2E2E2;
            border-right: none;
            border-radius: 10px 0px 0px 10px;
            background: #E2E2E2;
            padding-left: .5em;

        }

        .form-control {
            border-left: none;
            border-radius: 0px 10px 10px 0px;

        }
    }

    .commonComplain {
        background-color: $global-bg-color;
        border: 1px solid $global-border-color;
        padding: 0px;
        border-radius: 4px;
        margin-top: 1rem;
        padding: 20px;

        h4 {
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            color: #000;
        }

        label {
            font-size: 1em;
            // display: flex;
            // align-items: center;
            color: #000;
            font-weight: 700;
        }

        .content-explain {
            color: #ABABAB;
            font-size: .6rem;
            font-weight: 500;
            padding-left: 0px;
        }

    }

    // .personal-details{

    // }
    // .customer-care-details{

    // }
    // .grievance-officer-details{

    // }

}


.headerLabel {
    font-weight: 600;
    font-size: 16px;
}

.mobileNumber {
    font-size: 12px;
    // text-align: center;
    margin: 5px 0 10px 0;
}

.fbLabel {
    font-size: 12px;
    margin-right: 3px;
    font-weight: 500;
}

.labelTextName {
    font-size: 10px;
    font-weight: 500;
}

.verifyBtn {
    background: #0079f2;
    color: #fff !important;
    border: 1px solid #0079f2;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
}

.mb30 {
    margin-bottom: 75px !important;
}

.limit {
    font-size: 11.5px;
}

.fbIcon {
    padding-left: 5%;
    padding-top: 1%;
}

.addressTxt {
    overflow: hidden;
}

.channelDetails {
    position: relative;
    bottom: 20px;
}