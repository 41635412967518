.jumbo {
    padding: 16px 0;

    p {
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .copyToClickboard {
        display: flex;
        justify-content: space-between;
        max-width: 450px;
        border: 1px solid #ccc;
        border-radius: 12px;
        padding: 10px 16px;
        align-items: center;
        margin-top: 12px;
        background-color: white;

        .token {
            display: block;
            font-size: 1rem;
            cursor: pointer;
        }

        .copyBtn {
            border: 0;
            color: #414141;
            border-radius: 50%;
            font-size: 1rem;
            background-color: transparent;
            cursor: pointer;
            width: 40px;
            height: 40px;

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.1);
            }
        }
    }
}

.settingCard {
    .content {
        display: flex;
        justify-content: space-between;

        .settingCard__title {
            text-transform: none;
            font-size: 1rem;

            .icon {
                font-size: 0.8rem;
                margin-left: 5px;
                color: #959595;
            }

            &::before {
                display: none;
            }
        }

        .settingCard__subtitle {
            font-size: 0.6rem;
            color: #959595;
        }
    }
}