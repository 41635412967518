.future{
    filter: drop-shadow(0px 0.5px 0px rgba(60, 64, 67, 0.3)) drop-shadow(1px 1px 2px rgba(60, 64, 67, 0.15));
    max-width: 30em;
    min-width: 202px;
    background: white;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 0px 0px, rgba(60, 64, 67, 0.15) -0.5px 1.5px 0px 0px;
    border-radius: 0px 5px 5px 5px;
    padding: 10px;
    .whatsappImg{
        top: -15px;
        left: -15px;
        width: 30px;
        height: 30px;
        z-index: 1;
        position: absolute;
    }
}
