// .activepay{
//     border-bottom: 2px solid #2179ca !important;
//     padding: 7px 0px 0px 0px !important;
//     font-weight: 500 !important;
//     color: #2180da !important;
//   }

.name-tag{

  .nav-tabs .nav-link.active {
    color: #049fdb;
    padding: 0px;
    border: 1px solid #c8ced3;
    border-bottom: none;
  }
  
  .nav-tabs {
    padding: 0px;
  }
  
  .nav-item {
    padding: 0px;
    position: relative;
    right: 0.3%;
  }
}

// new and complete
.paid {
  background-color: #C6F6D5;
  color:#4c8e6a;
}
// pendign and process
.pending {
  background-color: #fefcbf;
  color: #dca51f;
}
// partial and ship
.partial {
  background-color: #bee3f8;
  color:#2b94d5;
}

.newcolor {
  background-color: hsl(298, 100%, 94%);
  color:#aa53b6;
}
// cancel
.cancel {
  background-color: #fed7d7;
  color:#c53030;
}

.paywidth {
  font-size: 11px;
  padding: 2px 15px !important;
}

.configureBtn {
  color:#2180da;
  border: 1px solid #2180da;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 11px;
}

.contact {
  margin-top: -3px;
  font-size: 11px;
  .text-dark {
      font-size: 12px;
  }
}

.Cards{
  margin-top: 10px;
  margin-bottom: 10px;
  .divcards{
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 12px;
    margin-right: 2%;
    width: 15%;
    background-color: #ffffff;
    font-size: 12px;
    .Label{
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.datepick {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #c8c9ca;
  border-radius: 4px;
  padding: 5px 10px 3px 10px;
  margin: -4px 3px 2px 3px;
  background: #fcfcfc;
}

.filterBox {
  color: #5e5e5e;
}
.filterBox:hover {
  color: #fff;
  background-color: #cecfce;
}

.filterBox:focus {
  color: #fff;
  background-color: #cecfce;
}

.optionicon {
  margin: 5px 0px 0px 150px;
}

.errorPayment {
  background-color: #FCF1D5;
  padding: 1px;
  width: 30%;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}