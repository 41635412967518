.replyButton {
    border: 1px solid #DCDFE3;
    box-shadow: none;
    font-size: 13px;
    background-color: #fcfbfb;
    display: inline-block;
    border: 1px solid #d4e6f0;
    border-radius: 8px;
    text-align: center;
    color: #007EC3;
    font-weight: 600;
    padding: 3px 32px;
    margin-right: 10px;
}