.textareaBox,
.inputBox {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #D4D4D4;
    position: relative;
    padding: 0 5px;
    padding-top: 10px;
}

.textareaStatus {
    font-size: .8rem;
    font-weight: 400;
    color: #A3A3A3;
    text-align: right;
    padding: 1px 3px;
}

.textareaBox textarea {
    width: 100%;
    height: 90px;
    border: none;
    outline: none;
    resize: vertical;
}

.textareaBox .bottomRow {
    display: flex;
    position: relative;
    padding: 5px;
    justify-content: space-between;
    width: 95%;
}

.inputBox {
    padding: 0;
}

.inputBox input {
    border: none !important;
}

.inputBox .addVariable {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    margin-top: -10px;
}

.inputBox .addVariable button {
    display: flex;
    width: auto;
    height: 60%;
    color: white;
    border: 1px solid #D4D4D4 !important;
    margin-right: 10px;
    text-align: center;
}

.inputBox .customQuill {
    height: 150px;
}

.emojiPicker {
    max-height: 340px; 
    width: fit-content;
    overflow-y: hidden; 
    overflow-x: hidden;
}

.customPopover .popover-body {
    padding: 0;
}

.emoji {
    cursor: pointer;
}
