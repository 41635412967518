.expandBox {
    transition: all 0.3s;
    overflow-y: visible;
}

.expandBox .header {
    display: flex;
}

.title {
    flex: 1;
}
