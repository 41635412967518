.tagsContainer {
    display: flex;
    flex-wrap: wrap !important;
    .contactTag {
        background-color: #efefef !important;

        button {
            background-color: #efefef !important;
        }
    }
}
