@import "../../styles/partials";

.createBot {
    @include cancelBtn;
}

.isDisabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none;
    
}

.noPointer {
    pointer-events: none;
    cursor: none;
}