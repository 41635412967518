.actionList {
    list-style: none;

    .actionItem {
        margin: 0;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        padding: 12px 5px;
        border-bottom: 1px solid #efefef;

        &:hover {
            cursor: pointer;
        }

        .icon {
            width: 35px;
            margin-right: 12px;
        }

        .details {
            .title {
                font-weight: 500;
                font-size: 1rem;
                display: block;
            }

            .description {
                color: #919191;
                font-size: 0.8rem;
            }

            .customHint {
                color: red; 
                font-family: 'Arial', sans-serif; 
                opacity: 0.8;
                font-size: 12px; 
                font-weight: bold;
                margin-bottom: 0px;  
            }

            .infoIcon {
                margin-bottom: 2px;
            }
            
        }
    }
}

.disabled {
    opacity: 0.6 !important;
    pointer-events: none !important;
}