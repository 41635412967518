.formdesign{
  border-radius:15px;
  border: 2px solid #f5f5f5;
}

.conbtn {
  background-color: rgb(19, 58, 58);
  color:#f5f5f5;
  border-radius: 25px;
  margin-left:32%;
  padding: 1%;
}

.backcolor {
  background-color: rgb(255 255 255);
}

.modaltitle {
  padding: 1%;
  font-size:large;
}

.closebtn {
  margin-left: 93%;
  margin-top: 0%;
  padding: 1%;
  border: #f5f5f5;
  background-color:#ffffff;
  &:hover {
    background-color: rgb(230, 231, 231);
  }
}
.whatsappicon {
  color:rgb(51, 204, 133);
  padding-right: 2%;  
  font-size: 22px;
}

.modalcon {
  font-size:large;
}

.qrcode {
  margin-left: 35%;
  padding: 1%;
}
.modalbtn {
  background-color:rgb(51, 204, 133);
  margin-right: 5%;
  color:white;
  margin-bottom: 5%;
  border-radius: 25px;
  font-weight: 800;
}