@import "../../styles/partials";

.verifyBot {
    text-align: left;

    .header {
        text-align: center;

        h1 {
            color: #4B5052;
            font-size: 17px;
            font-style: normal;
            font-weight: bold;
            line-height: normal;
        }

        .botName {
            color: #007EC3;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .errorMessage {
        font-size: 12px;
        color: #dc3545;
    }

    .action {
        text-align: center;

        .cancel {
            @include cancelBtnStyle;
            width: unset;
            font-size: 1rem !important;
        }
    }

    :global(.PhoneInputInput) {
        background-color: transparent !important;
    }

    .normalSize {
        * {
            font-size: 14px !important;
        }

        :global(.css-b62m3t-container) {
            width: 250px;
        }
    }
}

.verifySuccess {
    padding: 16px 0;
    font-size: 16px;

    :global(.dimmed),
    .caption {
        padding: 12px 0;
    }

    .caption {
        color: #007EC3;
        font-size: 15px;
    }

    .testOnWhatsapp {
        border: 0;
        border-radius: 2px;
        background: #4ACA59;
        color: white;
        padding: 12px 21px;

        i {
            margin-right: 5px;
            font-size: 18px;
        }
    }
}