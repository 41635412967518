.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    display: block;

    .backBtn {
      color: black !important;
      border-radius: 50%;
      margin-right: 10px;
      outline: none;

      &:hover {
        transition: all 200ms ease;
        background-color: #efefef;
        cursor: pointer;
      }
    }
  }
}

@media (zoom: 1.1) {
  /* styles for when zoomed in to 110% or more */

  .conversation-container{
    padding: 0 !important;
    .container{
      width: 1250px !important;
      padding: 0;
    }
  }
}


.container {
  margin-top: 10px;
  height: 67vh;
  overflow-y: scroll;
  // width: 86vw;
  padding-right: 0;


  /* Make the table scrollable */
  .tableScrollable {
    overflow-x: scroll;
    display: block;

    // .sticky-col-1, .sticky-col-2, .sticky-col-3, .sticky-col-4, .sticky-col-5, .sticky-col-6, .sticky-col-7, .sticky-col-8, .sticky-col-9, .sticky-col-10 {
    //   position: fixed !important;
    //   z-index: 1;
    //   background: white;
    // }
  }

  /* Styling for the sticky columns */

  .actionDropdown {
    background: none;
    color: #8a929d;
    height: 2.5rem;
    // padding: 4px;
    border: none;

    &:focus,
    &:hover,
    &:active {
      color: #00b598 !important;
    }

  }

  .actionMenu {
    padding-left: 1rem;
    font-size: medium;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }

    // border: none;
  }
}

.bulkAssign {
  display: flex;

  .select {
    width: 200px;
  }

  .btn {
    border: 1px solid #ffc107 !important;
    background-color: hsl(45, 100%, 51%) !important;
    color: #ffffff !important;
    font-weight: 900;
    border-radius: 4px;
    margin-left: 10px;
  }
}

.noteMsg {
  display: inline-block;
  background-color: #fff5dc;
  border-radius: 5px;

  p {
    margin: 0;
  }
}

// #noPadding > div > div > div > div.conversationReport_container__xfWCb > div.table-scrollable > div > div.react-bs-table.react-bs-table-bordered > div.react-bs-container-header.table-header-wrapper > table > thead{
//   th {
//     position: sticky;
//     top: 0; /* Adjust if your table's header needs to be offset from the top */
//     background-color: white; /* Ensures the header is visible over the content */
//     z-index: 10; /* Higher z-index to ensure the header is above other content */
//   }
// }
.conToConv {
  position: relative;
  right: 25px;
}

body > div.conversationFilter_popup__fnAdj.popover.bs-popover-end > form > div.conversationFilter_body__94Esk.popover-body > div.conversationFilter_filter_item__Yw5xl > div.conversationFilter_filter_body__wL1sD > div:nth-child(2) > div > div:nth-child(2) > label{
  white-space: normal;
}

.select{
  caret-color: transparent;
  z-index: 9;
}

.avatarIcon{
  width: 30px;
}


.noTableBody{
  height: 30rem;
}