@import '../../botFlows/styles/mixins';

.holidayItem {
    border: 1px solid #e2e8f0;
    text-align: left;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 12px;
    background-color: #fafafa;

    .holidayItem__name {
        font-size: 1em;
    }
}

.noteTemplate {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    bottom: 8px;
}

.wrapper {
    display: flex;
    padding: 10px 24px;

    @include smallWidth {
        display: block;
    }

    .mainContent {
        width: 60%;

        @include smallWidth {
            width: 100%;
        }

        .calenderPreview {
            margin: auto;
            max-width: 650px;

            .row {
                display: flex;
                margin: 0 auto;
                min-width: 400px;
                justify-content: center;

                @include smallWidth {
                    display: block;
                }

                .unavailableButton {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    margin-left: 5px;
                    color: #4A4A4A;
                }

                .timeSlots {
                    margin-left: 12px;

                    .list {
                        height: 325px;
                        overflow-y: scroll;

                        .timeSlots__item {
                            cursor: pointer;
                            border: 1px solid #458d80;
                            padding: 10px 12px;
                            margin-bottom: 12px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;

                            &.excludeTimeslot {
                                border-color: #ccc;
                                font-weight: 200;
                                color: #4A4A4A;
                            }

                            .timestamp {
                                display: block;
                                width: 90%;
                            }
                        }
                    }
                }

                .calender {
                    width: 400px;
                    border: none;

                    :global(.react-calendar__navigation button):disabled {
                        background-color: transparent;
                    }

                    :global(.react-calendar__navigation__arrow) {
                        font-size: 1.5rem !important;
                    }

                    :global(.react-calendar__tile) {
                        padding: 18px 5px;
                        border-radius: 50%;
                        background-color: rgba($color: #458d80, $alpha: 0.1);
                        font-size: 0.85rem;

                        abbr {
                            display: none;
                        }

                        &:disabled {
                            background-color: transparent;
                        }

                        &:global(.react-calendar__tile--active) {
                            background-color: #458d80 !important;
                            font-weight: bold;
                        }

                        &:global(.react-calendar__tile--now) {
                            background: transparent;
                        }

                        &:global(.react-calendar__year-view__months__month) {
                            border-radius: 0 !important;
                        }
                    }
                }
            }
        }
    }

    .sidebar {
        height: 90vh;
        overflow-y: scroll;
        width: 35%;
        position: absolute;
        top: 12px;
        right: 0;
        padding: 0 10px;

        .sidebar_header {
            position: relative;
            z-index: 155000;
            padding: 10px 12px;
            background-color: rgba($color: white, $alpha: 0.6);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .sidebarHeader_title {
                font-weight: bold;
                font-size: 1.2rem;
            }

            button.sidebarHeader__back {
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1rem;
                cursor: pointer;
                border-radius: 50%;
                padding: 12px;
                min-width: 45px;
                margin-right: 12px;

                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.1);
                }
            }
        }

        @include smallWidth {
            width: 100%;
        }

        .formActions {
            position: fixed;
            bottom: 0;
            right: 0;
            margin-right: 20px;
        }

        .calenderInput {
            width: fit-content;
            margin-right: 10px;
            cursor: pointer;

            i {
                color: #4A4A4A;
                margin-right: 10px;
            }
        }

        .removeBtn {
            display: block;
            color: #ff6565 !important;
            font-size: 1.3rem;
            cursor: pointer;

            &:disabled {
                cursor: not-allowed;
                border: 0;
            }
        }

        .unavailableDates {
            .dateItem {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .calenderInput {
                    margin-left: 8px;
                }
            }
        }

        .timeslots {
            .timeslotItem {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                .timeslotItem__text {
                    display: block;
                    margin-right: 6px;
                }

                .calenderInput {
                    min-width: 150px;

                    :global(.react-time-picker__wrapper) {
                        border: 0 !important;
                    }
                }
            }
        }
    }
}

#startTemplate {
    width: 36%;
    color:  #00b598 !important;
    background-color: white !important;
    border-radius: 8px;
    border: 1px solid #00b598;
}


.removeBttn {
    
    color: #ff6565 !important;
    font-size: 1.3rem;
    cursor: pointer;
    right: 0;
    margin-right: 40px;
    position: absolute;
}

.edit {
      right: 0;
      margin-right: 100px;
      position: absolute;
    
}

.dateFormat {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 8px;
    width: 120px;
    text-align: center;
}

