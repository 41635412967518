.creditAmountInput {
  width: 100%;
  height: 40px;
}

.p-Field {
  display: none !important;
}

.rupee-input {
  position: relative;
  display: inline-block;
}

.messageCredit {
  margin-top: 1rem;

  .creditHeader {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #353535;
    // margin-top: 15px;
    // margin-bottom: 10px;
    font-weight: 500;
  }

  .wrMsg {
    display: flex;
    border-radius: 0.5rem;
    background: #fff5dc;
    color: #A88300;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    width: fit-content;
    padding: 6px;

    .notify-icon {
      align-items: center;
    }
    .credit-notification {
      display: flex;
      align-items: center;
    }
  }


  // .credit_img{
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   // margin-top: 0.75rem;

  //     .credit_balance{
  //     }
  //     // .credit_balance{
  //     //   display: flex !important;
  //     //   justify-content: center !important;
  //     //   align-items: center;
  //     // }

  // }

  .referal_poster {
    // margin-bottom: 1.5rem;
    // margin-top: 7px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    padding: 1.5rem;
    border-radius: 0.5rem;
    // margin-top: 1rem;

    .poster_content {
      h6 {
        // font-weight: 800;
        color: #458d80;
        text-decoration: underline;
        font-weight: bolder;
      }
    }

    .poster_content,
    .referral_link {
      text-align: center;
      padding: 0 1.5rem;
    }

    .referral {
      display: flex;
      justify-content: center;
      padding: 1rem 0;

      .your_referral {
        font-size: smaller;
        color: #666666;
      }

      .referralCode {
        background-color: rgb(198, 246, 213);
        border: 3px dashed #458d80;
        border-spacing: 1rem;
        border-radius: 0.5rem;
        width: fit-content;
        text-align: center;
        font-weight: bold;
        color: #458d80;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
        padding: 0.3rem;
        width: 100%;
        cursor: pointer;
        // font-size: 1.2em;
      }
    }
  }

  .creditCardCon {
    border-radius: 10px;
    background: #f6f3f3;
    padding: 0.8rem 2rem 1.4rem 2rem;
    margin: 10px 0;
    // width: 80%;


    .total_credits {
      margin-top: 3px !important;
      display: flex;
      font-size: 16px;

      .credits {
        // color: #a0a0a0;
        font-weight: 600;
      }
    }
  }

  .creditedAmountCon {
    border-radius: 10px;
    background: #f6f3f3;
    padding: 10px 25px;
    margin: 10px;
    height: 204px;

    .walletImg {
      position: absolute;
      top: 45px;
      right: 20px;
    }
  }

  .creaditAmountInput {
    width: 100%;
    height: 40px !important;
    border-radius: 8px;
    border: 1px solid var(--grey-n-400, #A3A3A3);

    &:focus {
      outline: none;
      border: 1px solid var(--grey-n-400, #A3A3A3);
    }
  }

  .INR {
    color: #a0a0a0;
  }

  .validationWrng {
    color: #A88300;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin-left: 15px;
  }

  .amountBadgeCon {
    display: flex;
    justify-content: space-between;
    margin: 15px 5px;

    .amountBadgeBtn {
      width: 80px;
      height: 25px;
      cursor: pointer;
      font-weight: 500;
      padding: 2px 8px 4px 8px;
      text-align: center;
      border-radius: 4px;
      background: #dcebf3;
      font-family: 'Noto Sans', sans-serif !important;
    }
  }

  .amountContainer {
    position: relative;

    span {
      position: absolute;
      right: 15px;
      top: 10px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .addCreditBtn {
    padding: 1px 27.43px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: #FF7C4E;
    color: #fff;
    border: 1px solid #FF7C4E;
    font-weight: 800;
    width: 100%;
    height: 40px;

    &:disabled {
      background: #F9C5AF;
      border: 1px solid #F9C5AF;
      font-weight: 800;
    }
  }

  .availableAmount {
    font-size: 32px;
    font-weight: 500;
    padding-top: .8em;
    font-family: 'Noto Sans', sans-serif !important;
  }

  .subText {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #a0a0a0;
  }

}

/* Target the PaymentElement container */
#payment-element .Field-emailInput {
  pointer-events: none;
}

#Field-emailInput {
  background-color: #f2f2f2;
  /* Change the background color to a disabled appearance */
  color: #a0a0a0;
  /* Change the text color to a disabled appearance */
  border: 1px solid #ccc;
  /* Change the border color and style */
  cursor: not-allowed;
  /* Change the cursor style to indicate it's not clickable */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  /* Apply a shadow effect */
}

// input[name="email"] {
//   background-color: #f0f0f0; /* Change the background color */
//   color: #888; /* Change the text color */
//   cursor: not-allowed; /* Change the cursor to indicate it's not clickable */
// }

.crdAmountIpnut {
  position: relative;
}

.inrBadge {
  position: absolute;
  right: 10px;
  top: 38px;
  font-size: 15px;
  font-weight: 600;
}

.creditAmountInput {
  font-weight: 600;
}

.emailCon {
  display: none;
}

.creditEmail {
  width: 100%;
  height: 40px !important;
  margin-bottom: 5px;
}

.loadingVia {
  width: 25px;
  height: 25px;
  border: 5px dotted #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fontchange {
  font-family: 'YourFontName', sans-serif !important;
}

.points {
  float: right;
  color: #918d8d;
  font-weight: 700;
  margin-top: 7px;
}