.holidayItem {
    border: 1px solid #efefef;
    padding: 10px 12px;
    margin-bottom: 12px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.selected {
        border-color: #00B598;
    }

    .holidayItem_content {
        .holidayItem__name {
            font-weight: 600;
            font-size: 1rem;
            display: block;
        }

        .holidayItem__creator {
            color: #a0a0a0;
            display: flex;
            align-items: center;

            .holidayItemCreator___name,
            img {
                display: block;
            }

            img {
                width: 16px;
                margin-left: 8px;
            }

            .holidayItemCreator___name {
                margin-left: 3px;
                color: #858484;
            }
        }
    }

    .holidayItem_context {
        .holidayItemContext__selectedLabel {
            font-size: 0.75rem;
            color: #00B598;
        }
    }
}