$primary-color: #00005c; // Change color here. C'mon, try it!
$text-color: mix(#000, $primary-color, 64%);
// $primary-color: #fff;
$accent-color: #0172cb;

.sequenceContainer {
    margin-top: 1%;

    .sequenceHeader {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        gap: 10px;
        padding-top: 10px;
        padding-bottom: 10px;

    }

    .cardBodyHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        // padding: 1px;
    }

    .seqname {
        font-size: 14px;
        // font-weight: 600;
        // color: #7a7979;
        color: #555555;
    }

    .calendericon {
        color: #9fa19f;
        margin-right: 3px;
    }

    .date {
        font-size: 12px;
    }

    // .logStatus {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     padding-top: 10px;

    .status {
        // display: flex;
        font-size: 1em;
        text-transform: capitalize;
        text-align: center;
        border-radius: 4px;
        padding: 3px;
    }

    .logs {
        button {
            width: 100%;
            padding: 3px 2px;
            margin-bottom: 2px;
            // margin-left: 50px;
        }

    }

}

.createSequenceContainer {
  .sequenceHeaer {
    position: fixed;
    background: white;
    z-index: 800;
    width: 80%;
    top: 65px;
  }

  .createSequenceTitle {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 20px 0;
  }

  .sequenceForm {
    border: 1px solid #e2e2e2;
    background-color: #fbfbfb;
    border-radius: 4px;
    // padding: 1em;
    margin-top: 6em;
  }

  .radioStyles {
    // background-color: #FEFCBF;
    // color: #A88300;
    // border-color: #F1AA21;
    // background-color: #e3f2f8;
    color: #e0fff8;
    // border-color: #c3f7e7;
    border: 6px solid #c3f7e7;
    border-width: 1px;
    margin-top: 2em;
  }

  .customDefault {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    padding-left: 10px;
    line-height: 34px;
  }

  .days_time {
    background-color: #fefcbf;
    color: #a88300;
    border-color: #f1aa21;
    border-width: 1px;
    background-color: #fff5dc;
  }

  input[type="radio"] {
    width: 15px;
    height: 15px;
    border: 1px solid #e2e2e2;
    border-radius: 100%;
    background-color: #fbfbfb;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 30%;
    height: 30%;
    border-radius: 100%;
  }

  input[type="radio"]:checked:before {
    background-color: #ffffff;
    padding: 4px;
    border: 4px solid #0172cb;
    width: 15px;
    height: 15px;
  }

  input[type="checkbox"] {
    accent-color: #009980;
  }

  .start_stop {
    // margin: 1em;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;

    .fa_circle_xmark {
      cursor: pointer;
    }

    .dropDownToggle {
      border-radius: 4px;
      border: 1px solid var(--gray-400, #dcdfe3) !important;
      background: none !important;
      padding: 8px;
      cursor: pointer;

      &:active {
        background: none !important;
        box-shadow: none !important;
        color: #0172cb;
        // border: none!important;
      }

      &:focus {
        background: none !important;
        box-shadow: none !important;
        // border: none!important;
      }
    }

    .selectedData {
      background-color: #fbfbfb;
      border-radius: 10px;
      padding: 0px 10px;
      border: 1px solid #e2e2e2;
    }

    .selectedD_T {
      background-color: white;
      border: 1px solid #e2e2e2;
      border-radius: 8px;
      padding: 0px 3px;
      padding-top: 5px;

      Label {
        font-size: 11px;
      }
    }

    .fromTime {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 4px;
    }

    .field {
      color: #0884e2;
      margin-top: 4px;
      font-weight: 400;
    }

    .filter {
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 4px;
      background-color: #e2e2e2;
      border-radius: 4px;
      padding: 0px 3px;
    }

    .value {
      margin-top: 4px;
    }

    .condition_dropDown {
      // position: absolute;
      // right: 8em;
      // background-color: rgb(255, 255, 255);
      // box-shadow: 2px 2px 2px rgb(178, 174, 174);
      // text-align: center;
      overflow: auto;
      /* Use 'auto' for scrolling when needed */
      max-height: 68px;
      /* Set a maximum height for the dropdown */
      // width: 30%;
      // float: right;
      // border-radius: 5px;
      overflow-x: hidden;
      // padding: 5px auto;
      // top: 2em;
    }

    .drop_item {
      // background-color: #EFF4FA;
      // display: flex;
      // justify-content: space-between;
      margin: 4px auto;
      border-radius: 5px;
      color: black;
      cursor: default;
    }
  }
}

.bizContentShow {
  max-height: 248px;
  overflow: scroll;
  min-height: 248px;
  // background: #f4f5f7 !important;
  // border-radius: 4px;
  // width: 60%;
  // position: relative;
  // left: 15%;
  // bottom: 7%;
  // max-height: 60vh;
  // overflow-y: auto;
}

// .bizimg{

// // position: relative;
// width: 300px;
// height: 500px;
// padding-left: 20px;
// padding-top: 50px;
// // bottom: 60px;
// // left: 60px;
// // object-fit: cover;
// background-image: url('../../assets/img/mobileBiz.png');
// background-repeat: no-repeat;
// background-size: contain;

// }
.logsHeader {
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.afterTime {
  background: #00b598;
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
}

.profileNameCircle {
  border-radius: 100%;
  border: 1px solid #007ec3;
  padding: 0px 7px;
  text-align: center !important;
  background: #007ec3;
  color: #fff;
}

.logsStatus {
  padding: 5px 7px;
  font-weight: normal;
  font-size: 14px;
  margin: 6px;
}

.alignDatepicker {
    // float: right;
    
    width: 48%;
    white-space: nowrap; 
    text-overflow: ellipsis;  
}

.sequenceStatusCount {
  font-weight: 600;
  font-size: 15.5px;
  padding-top: 3px;
}

.noSequenceFound {
  font-size: 15px;
}
