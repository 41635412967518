// Misc {{{
@mixin IconButton {
  padding: 0;
  margin: 0;
  font-size: 16px;
  background-color: transparent;
  color: #6c757d !important;
  border: none;
  outline: none;
  padding: 10px;
  width: 45px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &.danger {
    color: var(--bs-danger) !important;
  }

  &:hover {
    background-color: #f1f3f5;
  }

  &:disabled {
    cursor: not-allowed;
    color: #ccc !important;
  }

  &.active {
    color: var(--bs-primary);
    border-bottom: 2px solid var(--bs-primary);
  }
}

// }}}
// Builder navbar {{{
.builderNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 20px;

  .builderNavbarIconButton {
    @include IconButton;
  }

  .builderNavbarLeft {
    display: flex;
    align-items: center;
  }

  .builderNavbarMiddle {
    display: flex;
    align-items: center;

    .builderNavbarMiddleButton {
      padding: 0 20px;
      height: 60px;
      font-size: 16px;
      background-color: transparent;
      color: #6c757d;
      border: none;
      outline: none;

      &.active {
        color: var(--bs-primary);
        border-bottom: 2px solid var(--bs-primary);
      }
    }
  }

  .builderNavbarRight {
    display: flex;
    align-items: center;
  }
}

// }}}
.builderContainer {

  // Builder screen list {{{
  .builderScreenList {
    padding: 5px 0;
    font-size: 14px;
    border-right: 1px solid #e9ecef;
    height: 80vh;

    .builderScreenListHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .builderScreenListItemWrapper {

      .builderScreenListItem {
        cursor: pointer;
        user-select: none;

        &:global(.dragging) {
          opacity: 0.5;
        }

        &:global(.selected) {
          background-color: #f1f3f5;
        }

        .listName {
          display: inline-block;
          margin-left: 10px;
        }

        .listRemove {
          display: inline-block;
          margin-left: 10px;
          color: #aaa;
          border: none;
          border-radius: 50%;
          background-color: transparent;
          transition: background-color 0.3s;
          height: 30px;
          width: 30px;

          &:hover {
            background-color: #f1f3f5;
          }
        }

        .listGrabber {
          display: inline-block;
          cursor: grab;
          padding: 10px;
          transition: background-color 0.3s;

          &:hover {
            background-color: #f1f3f5;
          }
        }
      }

      .builderScreenListItemSubScreens {
        margin-left: 25px;
        padding: 5px 0;
        font-size: 14px;
        color: #6c757d;
        cursor: pointer;

        &.selected {
          background-color: #f1f3f5;
          color: #000 !important;
        }
      }
    }
  }

  // }}}
  // Builder screen {{{
  .builderScreenHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #e9ecef;
    margin-bottom: 20px;
  }

  .builderScreenHeaderButton {
    @include IconButton;
  }

  // }}}
}

// Builder editor {{{
.builderEditors {
    height: 70vh;
    overflow: auto;

    .builderEditorComponentWrapper {
      display: flex;

      .builderEditorComponentGabbler {
        cursor: grab;
        font-size: 2em;
        padding: 10px;
        color: #6c757d;
      }

      .builderEditorComponent {
        width: 100%;
        padding: 10px 20px;
        border: 1px solid #e9ecef;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        background-color: #fff;

        .builderEditorComponentHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .builderEditorComponentHeaderTitle {
            font-size: 16px;

            .icon {
              margin-right: 10px;
            }
          }

          .builderEditorDropdown {
            display: flex;
            align-items: center;
            border: 1px solid #e9ecef;
            border-radius: 5px;
            padding: 5px;

            .icon {
              display: inline-block;
              font-size: 16px;
              margin: 0 10px;
            }

            select {
              padding: 5px;
              border: none;
              outline: none;
              background-color: transparent;
              font-size: 16px;
            }
          }

          .builderEditorComponentHeaderButton {
            @include IconButton;
          }
        }
      }
    }
  }

  .builderEditorIconButton {
    @include IconButton;
  }
// }}}
// Builder add content {{{
.builderAddContentMenu {
  padding: 10px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-height: 375px;
  overflow-y: auto;

  .builderAddContentMenuTitle {
    font-size: 12px;
    margin: 10px 0;
    color: #6c757d;
    text-transform: uppercase;
  }

  .builderAddContentMenuGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    .builderAddContentMenuItem {
      cursor: pointer;
      padding: 1px 2px;
      border: 1px solid #e9ecef;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #f1f3f5;
      }

      .builderAddContentMenuItemIcon {
        font-size: 1.5em;
        margin-top: 4px;
      }

      .builderAddContentMenuItemTitle {
        font-size: 12px;
        color: #6c757d;
      }
    }
  }
}
// vi: fdm=marker:
