.refreshIcon {
    position: relative;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid var(--gray-400, #DCDFE3);
    background: #FFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    // margin-top: 5px;

    &:hover,
    &:focus {
        background-color: unset !important;
        outline: none !important;
        box-shadow: none !important;
    }

    :global(.rotate) {
        animation: rotation 1s linear;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
