@import "../../assets/scss/variables";

#noPadding
  > div
  > div
  > div
  > div.integrationDetails-header
  > div
  > div.d-flex.justify-content-center.align-items-center.col-md-2
  > div
  > div
  > div:nth-child(3)
  > div
  > button {
  background: none !important;
  border: none;
  &:hover {
    background: rgb(236, 248, 240) !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

.connectionConnected {
  color: #00b598;
  border: 1px solid #00b598;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
}

.lastUpdate {
  color: #718096;
  font-weight: 600;
}

.matchedIntegration-card {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
  transition: box-shadow 0.3s ease;
  margin-bottom: 1rem;

  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }

  img {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 6px;
  }

  .header {
    font-weight: 600;
    font-size: 14px;
    margin: 0.5rem 0;
    width: fit-content;
  }

  .body {
    color: #a0aec0;
    font-size: 12px;
    margin: 0;
    height: 70px;
  }

  .new-btn {
    border: 1px solid #e0e0e0;
    text-align: center;
    padding: 4px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: $primary-dark;
      color: white;
    }
  }
}

.filter-options {
  margin-left: 0.75rem;
  margin-bottom: 1.5rem;
  button {
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    margin-right: 0.5rem;
    padding: 4px 8px;
    cursor: pointer;

    &.selected {
      background-color: $primary-dark;
      color: white;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.integrationDetails {
  margin-top: 1rem;

  .integrationOverview {
    display: flex;
    font-size: 14px;
    margin-bottom: 1rem;
    .selected-type {
      font-weight: 500;
      margin-left: 4px;
    }
  }

  .integrationDetails-header {
    background: rgb(213, 240, 222);
    padding: 1rem 0.5rem;
    border-radius: 6px;

    img {
      height: 5rem;
      width: 5rem;
      border-radius: 6px;
    }

    .connect-btn {
      background: $primary-dark;
      color: white;
      border-radius: 6px;
      padding: 4px 8px;
      font-weight: 600;
      width: 6rem;
      font-size: small;
    }

    .description {
      color: #718096;
      font-size: small;
    }
  }
}

.cancel-btn {
  width: 6rem;
  margin-right: 6px;
  border: none;
  border-radius: 6px;
  background: #fbf8f8;
  padding: 4px 8px;
  font-size: small;
  border: 1px solid #e2e2e2;
}

.submit-btn {
  background: $primary-dark;
  color: white;
  border-radius: 6px;
  padding: 4px 8px;
  width: 6rem;
  font-size: small;
  border: 1px solid $primary-dark;
}

.integration-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  cursor: pointer;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-weight: 600;
  }

  .tab-active {
    // background: rgb(236, 248, 240);
    color: #00b598;
    border-bottom: 3px solid #00b598;
    padding: 6px;
    cursor: pointer;
    margin: 0;
    border-radius: 3px;
    width: fit-content;
  }
}

.shipRocketOverview {
  .headerOverview {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    color: #718096;
    font-size: small;
  }

  .body {
    .overview {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
    }
  }
}

.stripeOverview {
  .headerOverview {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    color: #718096;
    font-size: small;
  }

  .body {
    display: flex;
    .overview {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
    }
  }
}

.shopifyOverview {
  .headerOverview {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    color: #718096;
    font-size: small;
  }

  .body {
    .overview {
      display: "flex";
    }
  }
}

.shipRocket-configuration {
  height: 70vh;
  label {
    color: #718096;
    font-size: 13px;
  }

  small {
    color: #8b8e92;
    font-size: smaller;
  }

  .form-control {
    width: 40%;
  }

  .channel-select {
    width: 40%;
  }

  .channel-select-zoho {
    width: 20%;
    margin-left: 1rem;
  }

  .copyButton {
    background: #00b598;
    color: white;
    border: none;
    border-radius: 4px;
    margin-left: 1rem;
    width: 6rem;
    height: 2rem;
    margin-top: 0.25rem;
    font-weight: 500;
  }
  .text-muted {
    position: relative;
    right: 30px;
  }
}

.workFlowComponent {
  .workFlow-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(224, 224, 224);
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 6px;

    .cart-icon {
      // background: rgb(198, 246, 213);
      // color: rgb(47, 133, 90);
      padding: 0.35rem;
      border-radius: 6px;
    }

    .flow-title {
      font-weight: 600;
      font-size: 14px;
    }

    .flow-description {
      color: #718096;
      font-size: small;
    }

    .flow-description-array {
      background: #edf2f7;
      font-size: smaller;
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      padding: 4px 8px;
      border-radius: 4px;
      
      .comparator{
        color:#00b5d8;
        margin-right: 4px;
      }
      
      .operator{
        background: #c6f6d5;
        color: #2f855a;
        padding: 2px;
        margin-right: 4px;
        border-radius: 2px;
      }
    }

    .use-btn {
      border: 1px solid rgb(224, 224, 224);
      padding: 0.25rem 1.5rem;
      border-radius: 6px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background: $primary-dark;
        color: white;
      }
    }
  }

  .back-btn {
    background: white;
    border: 1px solid rgb(224, 224, 224);
    padding: 6px 10px;
    border-radius: 6px;
  }

  .workFlowForm-header {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // border: 1px solid rgb(224, 224, 224);
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 6px;

    .cart-icon {
      // background: rgb(198, 246, 213);
      // color: rgb(47, 133, 90);
      padding: 0.35rem;
      border-radius: 6px;
    }

    .flow-title {
      font-weight: 600;
      font-size: 14px;
    }

    .flow-description {
      color: #718096;
      font-size: small;
    }

    
  }

  .workFlow-form {
    .remove-action {
      color: red;
      font-size: 11px;
      cursor: pointer;
    }
    .form-group {
      display: flex;
      margin-left: 1rem;

      .add-action {
        color: $primary-dark;
        border: 1px solid rgb(224, 224, 224);
        width: fit-content !important;
        padding: 6px 12px;
        border-radius: 6px;
        font-weight: 600;
        cursor: pointer;
      }

      .form-label {
        width: 15rem;
        font-size: 14px;
      }

      .form-value {
        width: 25rem;

        .selected-template {
          // border: 1px solid rgb(224, 224, 224);
          background: rgb(198, 246, 213);
          color: rgb(47, 133, 90);
          padding: 2px 8px;
          border-radius: 4px;
          font-size: small;
        }

        .pick-template-btn {
          background: $primary-dark;
          color: white;
          padding: 4px 8px;
          border-radius: 6px;
          border: none;
        }

        .nested-form {
          border: 1px solid rgb(224, 224, 224);
          padding: 0.75rem;
          border-radius: 8px;
          margin-bottom: 1rem;

          .remove-action {
            display: flex;
            justify-content: end;
          }

          .nested-form-group {
            margin-bottom: 0.5rem;

            .nested-form-label {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }

      .form-conditions {
        width: 50rem;

        .condition-select {
          width: 15rem;
        }

        .add-condition-btn {
          color: $primary-dark;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          cursor: pointer;
          border-radius: 6px;
          width: 8rem;
          padding: 6px;
          margin-top: 1.5rem;

          &:hover {
            background: #c6f6d5;
          }
        }

        .condition-form {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;

          .comparator,
          .operator,
          .value {
            margin-right: 0.25rem;
          }
        }
      }
    }
  }
}
.calendlyCompoent{

  .cart-icon {
    background: rgb(198, 246, 213);
    color: rgb(47, 133, 90);
    padding: 0.35rem;
    border-radius: 6px;
  }
  
   .back-btn {
      background: white;
      border: 1px solid rgb(224, 224, 224);
      padding: 6px 10px;
      border-radius: 6px;
    }
    .flow-title {
      font-weight: 600;
      font-size: 14px;
    }
}

/* Add this CSS to your main.scss or a dedicated CSS file */
.custom-switch .form-check-input {
  width: 3em;
  height: 1.5em;
  background-color: #ddd;
  border-radius: 1.5em;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.custom-switch .form-check-input:checked {
  background-color: #4caf50;
}

.custom-switch .form-check-input::before {
  content: "";
  position: absolute;
  top: 0.25em;
  left: 0.2em;
  width: 1.1em;
  height: 1.1em;
  // background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.custom-switch .form-check-input:checked::before {
  transform: translateX(1.5em);
}

.password-field {
  position: relative;
  display: flex;
  align-items: center;
}

.password-field input {
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.zohoBooksExtension {
  background: rgb(241, 238, 207);
  width: fit-content;
  padding: 6px 0.75rem;
  border-radius: 6px;
}

.zohoBookAdd {
  background: #00b598;
  color: white;
  padding: 4px 6px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #00b598;
}
// -------------------------WOOCOMMERCE ---------------- //

.woocommerceContainer {
  .httpsContainer {
    border: 1px solid #e2e2e2 !important;
    border-radius: 5px;
    width: 100%;
    margin-left: 1px;
    .https {
      background-color: #edf2f7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .shopUrl {
      border: none;
    }
  }
}

.channel-select-shopify {
  width: 40%;
}

.webHook-note {
  display: flex;
  background: #fefcbf;
  padding: 4px;
  font-size: smaller;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.btn-connected {
  border-radius: 6px;
  padding: 6px 12px;
  border: 1px solid #00b598;
  color: #00b598;
  background: white;
  font-weight: 600;
}

.btn-notConnected {
  border-radius: 6px;
  padding: 6px 12px;
  border: 1px solid #00b598;
  background: #00b598;
  color: white;
  font-weight: 600;
}

.metaData-title {
  // color: #212529bf;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.metaData-label {
  font-weight: 500;
  color: #6c757d;
  font-size: 14px;
  width: 300px;
}

.metaData-key {
  width: 300px;
  background: #d5f0de;
  padding: 6px;
  border-radius: 6px;
}

.shopify-card {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: smaller;
  // background: #00b598;
  // background: #c6f6d5;
  color: #2f855a;
}

.webHook-captureData {
  margin-top: 1rem;
  max-height: 20rem;
  overflow-y: scroll;
  width: 40rem;
}

// .calendly-container-workFlowForm{
//   display: flex;
//   gap: 10px;
// }

.template-variables-container {
  .templateName {
    color: black;
  }

  .view-template {
    margin-top: 1rem;
    width: 20rem;
    height: 30rem;
    border-radius: 1.5rem;
    background-image: url("../../../src/assets/img/ChatbackgroundImg.png");

    .view-template-header {
      background-color: #008069;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      padding: 1rem;

      .company-name {
        color: white;
        font-size: larger;
        margin-right: 0.5rem;
      }
    }

    .template-body {
      height: 22rem;
      padding: 1rem 0 0 1rem;
      overflow-y: scroll;

      .template-preview {
        background: white;
        padding: 3px;
        border-radius: 6px;
        width: 15rem;

        //renderButtonColumnsArray
        .catalog-image{
          display: flex;
          border-radius: 4px;
          background: #ececec;

          img{
            height: 4.75rem;
            width: 4rem;
          }

          .catalogue-overview{
            padding: 6px 12px 6px 3px;
            line-height: 16px;

            div{
              font-size: small;
            }
            small{
              font-size: x-small;
            }
          }
        }

        //renderContent
        .content-card {
          width: 15rem;
          .header {
            img,
            video {
              width: 14.6rem;
              height: 8.6rem;
              border-radius: 6px 6px 0 0;
            }

            .document-header{
              display: flex;
              align-items: center;
              
              .document{
                background: #ececec;
                padding: 0.5rem;
              }

              .document-file{
                margin-left: 1rem;
              }
            }
          }

          .location-details{
            padding: 8px;
            background: #ececec;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            width: 14.6rem;
          }

          .limited-time-offer {
            display: flex;
            padding: 8px;
            background: #ececec;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            width: 14.6rem;

            .limited-time-offer-icon {
              div {
                padding: 0.75rem;
                background: #e2e2e2;
                border-radius: 1.5rem;
              }
            }
          }

          .body {
            padding: 8px;
          }

          .footer {
            padding: 8px;
          }

          .buttons {
            .default-button {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.5rem;
              border-top: 1px solid #e2e2e2;
              font-weight: 500;
              color: #00a4d6;
            }
          }
        }
      }
    }

    .keyboard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;

      .keypad {
        display: flex;
        justify-content: space-between;
        width: 15rem;
        color: #a0aec0;
        background: white;
        padding: 0.5rem;
        border-radius: 1rem;
        height: 2.5rem;
      }

      .keypad-mic {
        background: #25855a;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
      }
    }
  }
}

.template-modal-tabs {
  display: flex; /* Enables Flexbox */
  flex-direction: column; /* Stacks the items vertically */
  align-items: center; /* Horizontally centers the items */
  justify-content: center; /* Vertically centers the items */
  gap: 2.5rem; /* Adds a 1rem gap between each item */
  height: 60%; /* Ensures the container takes full height */

  .tab {
    color: #6c757d;
    border: 1px solid #e2e2e2;
    padding: 10px;
    border-radius: 2rem;

    &:hover {
      background: #00b598;
      color: white;
      border: 1px solid #00b598;
    }
  }

  .tabSelected {
    background: #00b598;
    color: white;
    border: 1px solid #00b598;
    padding: 10px;
    border-radius: 2rem;
  }
}

.template-form{
  .add-variables-btn{
    background: white;
    color: $primary-dark;
    border-radius: 6px;
    padding: 4px;
    // width: 8rem;
    font-size: smaller;
    border: 1px solid $primary-dark;
  }

  .template-header{
    border-bottom: 2px solid #dee2e6;
    font-size: larger;
    font-weight: bold;
    color: #212529;
    padding-bottom: 0.5rem;
  }

  .template-body{
    padding: 1rem;
    height: 30rem;
    overflow-y: scroll;
  }
}

.popup-div{
  padding: 1px;
  max-height: 10rem;
  overflow-y: scroll;
  .popup-addVariable{
    padding: 4px;
    cursor: pointer;
    border-radius: 4px;
  
    &:hover{
      background: #c6f6d5;
    }
  }
}

.workFlowName-select{
  width: 40%;
}


.shop-url{
  display: flex;
  align-items: center;

  .refresh-connection{
    background: #fefefe;
    padding: 0.5rem;
    border: 1px solid #E2E2E2;
    border-radius: 6px;
    cursor: pointer;

    .spin-icon {
      animation: spin 1s linear;
    }
    
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  
}

.add-custom-time{
  color: #00b598;
  // font-size: smaller;
  font-weight: 600;
  cursor: pointer;
}