.PhoneInputInput {
    border: none;
    pointer-events: none;
}

#code[disabled] {
    background-color: #fff;
}

// .custom-confirm-button {
//     background-color:  #00B598;
//     border-color:  #00B598;
//   }
  
//   .custom-confirm-button:hover {
//     background-color: #458d80; 
//     border-color: #458d80;
//   }
  