.CatBtn{
    padding: 8.5px 8px;
    border-radius: 5px;
    margin-left: 89%;
}

  .Cat{
     .react-bs-table-tool-bar .form-group {
         position: absolute;
         top: -192%;
         right: 37%;
         float: left;
        }
     }

     .refreshIconHistory{
        background-color:white ;
        border: 1px solid #e2e2e2;
        padding: .1em .1rem;
        // margin-left: 32%;
        // margin-top: 2%;
        padding: 6px 6px;
    
        &:focus, &:hover {
            border: 1px solid #e2e2e2;
            box-shadow: none !important;
            background: white;
        }
        &:active {
            background: white !important;
            border: 1px solid #e2e2e2 !important;
        }
    }

    // .catalogueActive{
    //     // border-bottom: 3px solid #2179ca !important;
    //     padding: 9px 0px 0px 0px !important;
    //     font-weight: 500 !important;
    //     // color: #2180da !important;
    //   }

.catalogue-container{

    .tab-content {
        margin-top: -1px;
        background: #fff;
        border: 1px solid #c8ced3;
        border-radius: 0 0 0.25rem 0.25rem;
      }
    
      .nav-tabs .nav-link.active {
        color: #049fdb;
        padding: 0px;
        border: 1px solid #c8ced3;
        border-bottom: none;
      }
    
      .nav-tabs {
        padding: 0px;
      }
    
      .nav-item {
        padding: 0px;
        position: relative;
        right: 0.3%;
      }
    }
    //   .hrline{
    //     margin-top: -11px; 
    //     margin-bottom: 15px;
    //     border-top: 2px solid rgb(229, 230, 230); 
    //   }


.fbModal {
    margin-top: 13%;
}

.new {
  background-color: #C6F6D5;
  color:#4c8e6a;
  border-radius: 4px;
  padding: 4px;
  width: 3.5rem;
}

.four-image-square {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

.corner-image {
  width: 50%;
  height: 50%;
  position: absolute;
  border: 1px solid rgb(209, 206, 206);
}

.corner-image:nth-child(1) {
  top: 0;
  left: 0;
}

.corner-image:nth-child(2) {
  top: 0;
  right: 0;
}

.corner-image:nth-child(3) {
  bottom: 0;
  left: 0;
}

.corner-image:nth-child(4) {
  bottom: 0;
  right: 0;
}

.singleImg{
  border: 1px solid rgb(209, 206, 206);
  border-radius: 2px;
}

.collectionBtn {
  color: rgb(83, 175, 83);
  background-color: rgb(202, 255, 200);
  border-radius: 4px;
  padding: 2px 5px;
}

.greenBack {
  padding: 3px 10px;
}

.linkcolor {
  color: #049fdb;
  cursor: pointer;
  
  &:hover {
    text-decoration: none;
  }
}


.upload-box {
  width: 100%;
  max-width: 500px;
  background: #ecf8f0;
  border: 1px dotted #ccc;
  border-radius: 2px;
  text-align: center;
}

.upload-label {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  background: #fff;
  color: #212222;
  width: 100%;
  border: 1px solid #cbccc9;
}

.file-name {
  font-weight: bold;
}

.alertBack {
  background-color: #fcf6cf;
  border-color: #fae3a3;
  color: rgb(134, 134, 134);
}

.icondownload {
  margin-top: 2px;
}