.keywordItem {
    margin-bottom: 15px;
}

.labelRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.labelRow :global(.deleteSmallButton),
.labelRow :global(.addButton) {
    margin-bottom: 5px;
}

.keywordItem {
    background-color: rgba(160, 160, 160, 0.1);
    padding: 21px;
}

.advanceOptions {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    font-size: 14px;
}
.advanceOptions .label {
    color: rgba(115, 115, 115, 1);
    cursor: pointer;
}


.advanceOptions :global(.form-check-input) {
    margin-top: 3.5px
}

.advanceOptions {
    display: flex;
    justify-content: space-between;
    margin: 5px;
    font-size: 14px;
}

.labelRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer; /* Ensure the cursor indicates it's clickable */
}

.checkboxesRow {
    display: flex;
    justify-content: space-between;
    margin-top: 10px; /* Optional margin for spacing */
}

.advanceOptions .form-check-input {
    margin-top: 0; /* Adjust margin to align with labels */
}

/* Optional: Add this to further adjust the layout */
.checkboxColumn {
    flex: 1; /* Allow each checkbox to take equal space */
    display: flex;
    align-items: center; /* Center align checkboxes vertically */
}

/* Optional for specific styling */
.checkboxRow {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensures checkboxes use full width */
}

.arrow {
    margin-left: 5px;
    margin-top: 10px;
    color: #737373;
}

.advancedName {
    margin-top: 10px;
    color: #737373;
}

.formCheck {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.formCheck label {
    margin-left: -15px;
    margin-bottom: 4px;
    margin-right: 30px;
}