.widgetTtl,.OR{
    text-align: center;
    font-size: 2.25rem;
    margin:50px auto;   
}

.dummyImg{
    cursor: pointer;
    border: 2px solid rgb(250, 250, 247);
    border-radius: 5px;
    margin-bottom: 8px;
}

.dummyImgActive{
    border: 2px solid rgb(61, 106, 196);
}
.ChatHeader{
    background-color: rgb(18, 140, 126);
    border-radius: 10px 10px 0px 0px;
    padding: 15px;
    color: white;
    margin-top: 1rem;
    img{
        border-radius: 100%;
    }
}

.ChatBackgroundImg{
        background-image: url('../../assets/img/ChatbackgroundImg.png');
        height: 400px;
        width: 100%;
        .ChatBackgroundArea{
            background-color: white;
            height:50px;
            margin:20px;
            padding-top: 10px;
            border-radius: 10px;
            min-width: 25%;
            max-width: 75%;
        }
        .footerChatBox{
            position: absolute;
            bottom: 0px;
            width: 75%;
            .sendData{
                display: flex;
                justify-content: space-between;
            }
            .sendMsg{
                background: white;
                border-radius: 50px;
                padding:10px;
            }
            .poweredBy{
                display: flex;
                justify-content: center;
                a{
                    color: #007EC3;
                }
            }
        }
}

.WhatsAppImg{
    // padding-top: 1em;
    .faWhatsapp{
        margin: auto;
        display: flex;
        background-color: #25d366;
        padding: .8rem;
        font-size: 50px;
        border-radius: 100%;
        color: white;
    }

}
.widgetTitle{
    font-size: 36px;
    font-weight: 1000;
    color:black;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif
}
.WhatsappImgContent{
    max-height: 126px;
    overflow: scroll;
    margin-top: 50px;
    position: relative;
    z-index: 9999;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #333;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    padding: 0.5rem;
    overflow-wrap: break-word;
}

.triger-msg-content{
    max-height: 80px;
    overflow: scroll;
    position: relative;
    z-index: 9999;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #333;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    padding: 0.5rem;
    overflow-wrap: break-word;
    width: 170px;
}

label{
    color: black;
}
.brandName{
    margin:50px  0px;
}
.LogoContainer{
    border: 2px solid beige;
    width: 300px;
    // .LogoFile{
    //     background-color: #007EC3;
    //     border-radius: 10px;
    //     color: white;
    //     padding: 10px 20px;
    //     margin-top: 7px;
    // }
}
.UploadImg{
    position: "relative";
    display: "inline-block";
    margin-top: 5px;
    .cancelIcon{
         position: absolute;
         right: 0px;
         cursor: pointer;
    }
}
.widgetBtn{
    padding: 10px 0px;
    width: 200px;
    margin-top: 40px;
    font-size: 20px;
    background-color: #FF7342;
    color: white;
    &:hover{
        background-color: #FF7342 !important;
    }
}
.widgetForm{
    border: 3px solid rgb(250, 250, 247);
    border-radius: 5px;
    .WhtDetails{
        font-size: 25px;
        color: black;
        font-weight: 700;
    }
}
.sidebarScript{
    overflow: scroll;
    height: 400px;
}
.Copy {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    width: 50px;
    border: none;
    text-align: center;
    margin-left: auto;
}
.subContent{
    background-color: #f3eeee8e;
    padding:15px;
    border-radius: 20px;
    color: black;
    font-size: 18px;
    
    svg{
        background-color: #007EC3;
        color: white;
        border-radius: 20px;
        padding: 5px;
        text-align: center;
        margin-right: 10px;
        // border-right: 2px solid  #FF7342;
        box-shadow: 2px 2px 2px  #FF7342;
    }
}
.promoteImg{
    margin-top: 150px;
}

.welcomeTxt{
    border: 2px solid  #DCDFE3;
    border-radius: 5px 5px 0px 5px;
    // &:active{
    //     border: 2px solid #2CB2FC !important;
    // }
    // &:focus{
    //     border: 2px solid #2CB2FC !important;
    // }
}