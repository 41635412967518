@import "../../../styles/partials";

.wrapper {
    position: relative;
    font-size: 8px;
}

.wrapper.selected .card {
    outline: 0.5px solid rgb(255 115 66 / 50%);
}

.card {
    background-color: white !important;
    border: 1px solid #efefef;
    border-radius: var(--bs-border-radius);
    max-width: 115px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.cardC {
    background-color: white !important;
    border: 1px solid #efefef;
    border-radius: var(--bs-border-radius);
    max-width: 165px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
}

.notes {
    background-color: lightyellow !important;
}

.wrapper .options {
    display: none;
    z-index: 50;
}

.cardC .width {
    width: 150px !important;
    font-size: 7px;
    background-color: #92aec8;
    color: #fff;
    margin: 0;
    padding: 5px 6px;
    border-top-left-radius: var(--bs-border-radius);
    border-top-right-radius: var(--bs-border-radius);
    font-weight: 600;
}

.card .title {
    font-size: 7px;
    background-color: #92aec8;
    color: #fff;
    margin: 0;
    width: 114px;
    padding: 5px 6px;
    border-top-left-radius: var(--bs-border-radius);
    border-top-right-radius: var(--bs-border-radius);
    font-weight: 600;
}

.wrapper .options {
    background-color: #92aec8;
    position: absolute;
    top: 5px;
    right: 2px;
}

.wrapper .options button {
    background-color: transparent;
    border: 0;
    outline: none;
    color: #fff !important;
    padding: 0 2px;
}

.wrapper:hover .options {
    display: block;
}



.card .body {
    font-size: 6px;
    padding: 10px 5px;
    font-weight: 400;
}

.card.readOnly {
    max-width: 160px;
}

.card.readOnly .title {
    width: 159px;
}

.card.readOnly .body {
    padding: 6px 12px;
}

.card.readOnly .title,
.card.readOnly .body {
    font-size: 12px !important;
}

.cancelButton {
    @include cancelBtnStyle;
    width: unset;
}

.acceptButton {
    background-color: #00b598 !important;
}

.node {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;        
    overflow: hidden;              
    text-overflow: ellipsis;     
    line-height: 1.2;            
    max-height: calc(1.2em * 3);  
    word-break: break-word;    
    overflow-wrap: break-word;   
    cursor: pointer;
    padding-left: 1px;
}

.sheetsNode {
    background-color: #efefef;
    height: auto;
    width: auto;
    margin-bottom: 0;
    padding: 3px;
    border-radius: 5px;

    .sheetType {
        font-size: 7px;
        font-weight: bold;
    }

    .smallFont {
        font-size: 5px;
    }
}

.metaData {
    font-size: 7px;
    font-weight: bold;
}

.opacity {
    opacity: 0.5;
}