.orderDetail {
    color: rgb(101, 102, 102);
    .Row {
        .Col {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            color: aquamarine;
            text-overflow: ellipsis;
            font-weight: 900;
        }
    }
}

.fontchange {
    font-family: 'YourFontName', sans-serif !important;
}

.amount {
    padding-left: 180px;
}
// new and complete
.new {
    background-color: #C6F6D5;
    color:#4c8e6a;
    // margin-left: -10px;
}

.completed {
    background-color: #C6F6D5;
    color:#4c8e6a;
    margin-left: 18px;
}
.failed{
    margin-left: 20px;
}
// pendign and process
.pending {
    background-color: #fefcbf;
    color: #dca51f;
}
// partial and ship
.partial {
    background-color: #bee3f8;
    color:#2b94d5;
}
// cancel
.cancel {
    background-color: #fed7d7;
    color:#c53030;
}

.contact {
    margin-top: -3px;
    font-size: 11px;
    .text-dark {
        font-size: 12px;
    }
}

.closeBtn {
    margin: 9% 2%;
}

.statuswidth {
    width: auto;
}

.filterBtn {
    margin: 0 0 0 0;
    padding: 8px 8px;
    font-size: 12px;
    font-weight: 600;
    color: #cecfce;
    background-color: #fff;
    border-color: #fff;
    border-radius: 4;
    border-color: #dadada;
}
.filterBtn:hover {
    color: #fff;
    background-color: #cecfce;
    border-radius: 4;
    border-color: #dadada;
}

.filterBtn:focus {
    color: #fff;
    background-color: #cecfce;
    border-radius: 4;
    border-color: none !important;
}

.check {
    font-size: 12px;
    .label {
        font-size: 12px;
        position:relative;
        bottom: 2px;
    }
}

.resetbtn {
    background-color: #1590dd;
    color: white;
    padding: 1px 3px;
    margin-left: 50%;
    margin-bottom: 0px;
    height: 25px;
    font-size: 11px;
}

.resetbtn:hover {
    background-color: #52ace4;
    color: white;
}

.resetbtn:focus {
    background-color: #52ace4;
    color: white;
}

.resetbtn:active {
    background-color: #52ace4;
    color: white;
}

.dateRangePicker{
    position: relative;
    right: 15%;
}

.paywidth {
    font-size: 11px;
    padding: 2px 15px !important;
  }

  .bodyMsg {
    height: auto !important;
  }

  .exduration{
    background-color: #e9ecee;
    text-align: center;
  }

  .disableblue{
    color:rgb(161, 237, 250);
  }

  .orderRows{
    margin-left: -14px;
  }

  .disabled-link {
    color: grey; 
    cursor: not-allowed;
  }
  .dateRangePicker {
    height: 250px; /* Adjust the height as needed */
}

.dateRangePicker .rdr-MonthAndYear, 
.dateRangePicker .rdr-Month {
    height: calc(100% - 70px); /* Adjust the inner elements if necessary */
}
