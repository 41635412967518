  // .modal-header-content {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin: 10px;
  // }
  // .underLine{
  //   margin-top:10px ;
  //   border: 1px solid rgba(231, 231, 231, 0.849);
  // }
  // .modal-dialog{
  //   margin: 0px !important;
  // }
  // .modal-content{
  //   margin: 20px 50px;
  //   width: 240% !important;
  // }
  // .modalBody{
  //   overflow: scroll;
  //   height: 480px;
  // }

  .modal-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #dddada;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title,
  .close-btn {
    margin: 0 3px;
  }

  .modal-xxl {
    width: 1400px;
    max-width: 90%;
    z-index: 1500;
  }

  .modal-xl {
    width: 1200px;
    max-width: 90%;
  }

  .modal-l {
    width: 650px;
    max-width: 90%;
    height: 600px;
  }

  .modalBody {
    overflow-y: auto;
    max-height: 85vh;
    overflow-x: hidden;
    scrollbar-width: thin;
  }


  .modal-open .modal {
    overflow-y: hidden;
  }

  .modal-backdrop {
    background-color: rgba(20, 18, 18, 0.103);
  }

  body.modal-open {
    overflow: hidden !important; /* Prevent scrolling */
  }
  
  .modal-content {
    overflow-y: auto; /* Allow scrolling inside modal */
  }
  
  .modalcontent {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  
    