.dropdownContent {
    border: 1px solid #ccc;
    border-top: none;
    /* overlaps with the button to prevent double-border */
    padding: 10px;
    box-sizing: border-box;
    background-color: #fff;
    border: none;
  }

  .dropbtn {
    background-color: #fff;
    color: #333;
    padding: 10px 15px;
    font-size: 13px;
    border: 1px solid #ccc;
    width: 100%;
    text-align: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
  }