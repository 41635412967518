.delayCotainer {
    border-radius: 5px;
    border: 1px solid #EDEDED;
    background: #FAFAFA;
    display: flex;
    padding: 10px;
}

/* .delayCotainer input::-webkit-outer-spin-button,
.delayCotainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.delayCotainer input[type=number] {
    -moz-appearance: textfield;
} */

.delayCotainer .dropdownBox {
    width: 70%;
    margin-right: 12px;
    margin-top:5px;
}

.delayCotainer .valueInput {
    width: 35%;
    margin-top: 5px;
}