.card-body{

    .URGENT{
            background-color: #ff5959;
            border-radius: 10px;
            padding: 1px 3px;
            height: 6px;
        }
        .MEDIUM{
            background-color: #4da1ff;
            border-radius: 10px;
            padding: 1px 3px;
            height: 6px;
        }
        .HIGH{
            background-color: #ffd012;
            border-radius: 10px;
            padding: 1px 3px;
            height: 6px;
        }
        .LOW{
            background-color: #a0d76a;
            border-radius: 10px;
            padding: 1px 3px;
            height: 6px;
        }
}
