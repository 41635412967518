@import "../../styles/mixins";

.grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 16px;
    margin-bottom: 10px;

    @include lowResolution {
        grid-template-columns: auto auto auto;
        margin-bottom: 5px;
        gap: 12px;
    }
}

.grid .gridItem {
    cursor: pointer;
}

.iconCard {
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    color: var(--primary);
    width: 55px;
    height: 55px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F7F7;

    @include lowResolution {
        width: 45px;
        height: 45px;
    }
}

.iconCard:hover {
    border-color: #F7BBA7;
    cursor: pointer;
}

.iconCard .icon {
    font-size: 1.2rem;
    padding: 5px;
}

.iconCard img {
    width: 25px;
    margin-bottom: 5px;

    @include lowResolution {
        width: 15px;
    }
}

.title {
    color: #626262;
    font-size: .8rem;
    text-align: center;
    width: 60px;
    @include lowResolution {
        margin: 0px !important;
        font-size: .5rem;
    }
}

.specialTitle {
    white-space: nowrap;
}


// .gridItem:hover .title {
//     visibility: visible;
// }

.secondary .iconCard {
    color: #91AAB7;
}

.secondary .iconCard:hover {
    border-color: rgba(145, 170, 183, 0.5);
    cursor: pointer;
}

.secondary .title {
    color: #007EC3;
}