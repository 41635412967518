.ruleContainer {
    background-color: #efefef;
    border-radius: 8px;
    padding: 12px 20px;
    margin-bottom: 15px;
}

.conditionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.conditionHeader .header {
    font-weight: 500;
    color: #525252;
}

.variableNameInput {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 0.5rem !important;

    .addVariableWrapper {
        width: 100%;

        :global(.css-13cymwt-control) {
            width: 100% !important;
            border: 0 !important;
        }
    }

    &.invalid {
        border-color: var(--bs-form-invalid-color) !important;
    }

    &.valid {
        border-color: #198754 !important;
    }
}

.variableNameInput .inputIcon {
    display: flex;
    align-items: center;
}

.variableNameInput .inputIcon .label {
    display: block;
    margin-left: 0.25rem;
    padding-right: 0.5rem;
}

.variableNameInput .variableInput,
.variableNameInput .variableInput:focus,
.variableNameInput .variableInput:hover {
    border: none !important;
    outline: none !important;
}

.andOrSwitch_Wrapper {
    margin: auto;
    max-width: 130px;

    .andOrSwitch {
        display: flex;
        justify-content: center;
        padding: 2px;
        padding: 10px 12px;
        background-color: #efefef;
        border-radius: 8px;

        .option {
            padding: 10px 12px;
            border-radius: 8px;
            cursor: pointer;

            &.selected {
                background-color: #ff7342;
                color: white;
            }
        }
    }
}