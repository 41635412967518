.table {
  border-left: none !important;
  border-right: none !important;
}

.isDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  pointer-events: none;
}
