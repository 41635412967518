.endpoint {
    background-color: #f1f1f1;
    padding: 3px 5px;
    border-radius: 5px;
    margin: 3px 0;
}

.endpoint div {
    display: flex;
    align-items: center;
}

.endpoint .dot {
    position: absolute;
    right: 3px;
    border-radius: 50%;
    margin-right: 2px;
}

.endpoint.success .dot {
    background-color: #66BB6A;
    width: 7px;
    height: 7px;
    border-radius: 0 !important;
}

.endpoint.failed .dot {
    background-color: #FF7043;
    width: 7px;
    height: 7px;
    border-radius: 0 !important;
}

.endpoint .caption {
    font-weight: 500;
}