//-----------*------SLA policy---------*-----------------//

.slaContainer {
  margin-top: 1rem;

  .slaInfo {
    color: #a5a8ab;
  }

  .editIcon {
    display: none;
    margin-top: 15px;
  }

  .card {
    border: 2px solid #caced145;
    cursor: pointer;

    &:hover .editIcon {
      display: block;
    }

    .cardHeader {
      background-color: #f5f7f9;
      border: none;
      display: flex;
      justify-content: space-between;
      align-items: center;

    }

    .clipboardIcon {
      background-color: #d6ffe9;
      border-radius: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 17vh;
    }

    .scrollBars {
      max-height: 60px;
      /* Adjust the height as needed */
      overflow-y: auto;
    }

    .scrollBars::-webkit-scrollbar {
      width: 8px;
      /* Adjust the width as needed */
    }

    .scrollBars::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    .scrollBars::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
      /* Round edges */
    }

    .scrollBars::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

  }
}

//-----------*------Edit SLA policy---------*-----------------//

.editPolicyContainer {
  margin-top: 1rem;

  .charCount {
    font-size: .7em;
  }

  .textarea {
    resize: none;
    /* Prevent the user from resizing the textarea */
    overflow: auto;
    /* Add vertical scrollbar */
    scrollbar-width: thin;
    /* Width of the scrollbar */
    height: auto;
    /* Adjust height based on content */
    min-height: calc(1.5em * 3);
    /* Ensures the textarea is at least 3 rows high */
    box-sizing: border-box;
    /* Ensures padding and border are included in the width and height */
    scrollbar-color: #888 #f1f1f1;
    /* Scrollbar thumb and track colors */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    margin-bottom: 1rem;
  }

  .card {
    border: 2px solid #caced145;

    .cardHeader {
      background-color: #f5f7f9;
      border: none;
    }
  }

}