.optionBox {
    border: 1px solid #EFF3F5;
    background: #FAFAFA;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 12px 16px;
    margin-bottom: 18px;
}


.idCol {
    display: flex;
    align-items: center;
    font-size: 12px;
}