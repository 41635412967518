@import "../../botFlows/styles/mixins";

// :global(.active) {
//   background-color: transparent !important;
// }

:global(.alert-warning) {
  background-color: #fff5dc !important;
  color: #A88300 !important;
  padding: 10px !important;
  border-color: #F1AA21 !important;
}


// #pro {
//     border: 2px solid #FF7342 !important;
// }

// #ultimate{
//   border: 2px solid red !important;
// }

.billingInfoCard,
.activePlanCard,
.planCard {
  :global(.card-body) {
    :global(.card-title) {
      text-transform: none !important;
      font-weight: bold;
      font-size: 1.3em !important;

      &::before {
        display: none !important;
      }
    }

    :global(.card-subtitle) {
      font-size: 1rem;
    }

    :global(.btn).cta:disabled {
      border-color: #717171 !important;
      color: #717171 !important;
    }
  }
}

.activePlanCard {
  margin-top: 24px;
  position: relative;
  border-radius: 15px !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05) !important;
  padding: 0 !important;
  height: 155px;

  .background {
    position: relative;
    right: -5px;
    top: -36px;
    max-width: 220px;
  }

  :global(.card-body) {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    margin: 25px 0;

    :global(.card-title) {
      font-size: 2em !important;
    }

    p {
      margin-top: 5px;
      max-width: 600px;
      font-size: 13px;
      color: #717171;
    }

    .planDetails {
      display: flex;
      margin-top: 20px;

      .item {
        min-width: 200px;

        .caption,
        .data {
          display: block;
        }

        .caption {
          color: #333;
          font-size: 12px;
          font-weight: 400;
        }

        .data {
          color: #333;
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}

.planCard {
  transition: all 100ms ease;
  cursor: pointer;
  min-height: 450px;
  width: 90%;
  margin-left: 11%;



  &.selected {
    border: 2px solid #007ec3 !important;
  }

  &.ultimatePlan {
    border: 2px solid #007ec3 !important;
  }

  :global(.card-body) {

    :global(.card-title) {
      font-size: 2em !important;
      text-align: center;
    }

    :global(.card-subtitle) {
      text-align: center !important;
      color: #2d3748;
    }

    :global(.btn) {
      width: 100%;

      &:hover,
      &:focus {
        // background-color: #FF7342 !important;
        color: white !important;
        outline: none !important;
        box-shadow: none;
      }
    }

    ul {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      list-style: disc !important;

      li {
        display: list-item !important;
      }
    }
  }
}

.subscriptionPlanDetails {
  text-align: center;
  margin-bottom: 80px;

  h2 {
    margin: 32px 0;
    margin-bottom: 16px;
  }

  .switch {
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    .switch_label {
      margin: 0 10px;
    }
  }

  :global(.table) {
    margin: auto;
    max-width: 600px;

    tr,
    td,
    th {
      border-bottom-width: 0 !important;

      .tooltip small {
        color: #efefef !important;
      }

      .infoButton {
        cursor: pointer;
        color: #007EC3 !important;
      }
    }
  }
}

.billingInfoCaption {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  div {
    display: flex;
    align-items: center;
  }
}

.selectedPlan {
  position: fixed;
  bottom: 0;
  left: 240px;
  width: 85%;
  padding: 10px 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -2px 3px;
  display: flex;
  align-items: center;
  height: 80px;
  z-index: 900;

  @include lowResolution {
    width: 81vw;
  }

  @include highResolution {
    width: 87.5vw;
  }

  &.sidebarToggled {
    left: 80px;
    width: 95%;


    @include lowResolution {
      width: 94vw;
    }

    @include highResolution {
      width: 96vw;
    }
  }

  animation: initialAnimation 300ms linear;

  &.enter {
    transform: translateY(80px);
  }

  &.enterActive {
    transform: translateY(0);
  }

  &.enter,
  &.exit {
    transition: transform 300ms linear;
  }

  &.enter,
  &.enterActive {
    transform: translateY(0);
  }

  &.exit,
  &.exitActive {
    transform: translateY(80px);
  }
}


@keyframes initialAnimation {
  0% {
    transform: translateY(80px);
  }

  100% {
    transform: translateY(0);
  }
}

.billingInfoCard {
  :global(.btn-link).editButton {
    text-decoration: none !important;
    color: #313131 !important;
    outline: none !important;
    float: right;
    background: transparent !important;
    box-shadow: none;

    &:hover {
      outline: none;
      border: 1px solid #0085ff;
      cursor: pointer;
      background: transparent !important;
    }
  }

  :global(.table) {
    margin-top: 12px;
    border: 0 !important;

    th,
    td {
      text-align: left !important;
      padding: 3px 0 !important;
      color: #595959;
      font-size: 14px;
      font-weight: 500;

      .infoIcon {
        font-size: 21px;
        color: #595959;
        margin-right: 6px;
      }
    }
  }
}

.invoiceAction {
  :global(.btn-link) {
    text-decoration: none !important;
    color: #000 !important;
    outline: none !important;
  }
}

.hidesidebar {
  position: absolute;
  left: 0.5%;
  top: 2%;
}

.trialPlan {
  max-width: 600px;
  background: #f0f2f5;
  margin: 10px auto;
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  font-size: 0.9rem;
}

.notProvidedColor {
  color: #03547e;
  font-weight: 650;
}

.addonsDesign {
  color: #0085ff;
  border: 1px solid #0085ff;
  padding: 5px 7px;
  border-radius: 25%;
  margin-right: 5px;
  font-size: 16px;
  position: relative;
}

.addonCount {
  position: absolute;
  bottom: 25px;
  right: -3px;
  background: #0085ff;
  border-radius: 50%;
  height: 21px;
  width: 25px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.logOut {
  position: absolute;
  left: 90%;
  top: 2%;
  border-radius: 4px;
  font-weight: 900;
  color: #063ba5;
  padding: 3px 10px;
  background-color: #f7f6f7 !important;
  box-shadow: 1px 2px rgb(145, 143, 143);
}

.planSelector {
  font-size: 16px;
  /* Adjust the font size as needed */
  // color: #951f1f; 
  background-color: #ebebeb;
  // padding: 10px;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 28px;

  // gap: 10px;
  span {
    letter-spacing: -.5px;
    font-size: 14px;
    color: #FAB534;
    font-weight: 700;
  }
}

.monthly,
.quarterly,
.halfyearly,
.yearly {
  // background-color: #004c3d !important;
  background-color: rgb(69, 141, 128);
  color: #fff;
}

.planOption {
  //  font-size: 20px; 
  font-weight: 600;
  //  font-family: 'proxima-nova'; 
  padding-top: 7px;
  padding-bottom: 7px;
  //  margin: 0 0px; 
  cursor: pointer;
  border-radius: 24px;
  // transition: background-color 0.3s ease;
  // 
  // color: #888;
  width: 200px;
  text-align: center;
}

.payNowInvoice {
  background: #00bbd6;
  box-shadow: none;
  border: none;

  &:hover {
    background: #7bcbd4;
    box-shadow: none;
    border: none;
    color: black;
  }

  &:focus {
    background: #7bcbd4;
    box-shadow: none;
    border: none;
    color: black;
  }
}

.billingPhoneNum {
  margin-left: 30px !important;
}

.smallplaceholder::placeholder {
  font-size: 13px;
}

.perMonthGST {
  text-align: center;
  font-size: 0.95em;
  color: #777;
  font-weight: 500;
  position: relative;
  bottom: 7px;
}

.strikedPrices {
  text-decoration: line-through;
  color: #999;
  font-size: 1.4em;
  text-align: center;
}

.upgradeBtn {
  margin-top: 26px;
}

.notStrikedSubtitile {
  position: relative;
  top: 19.5px;
}

.choosePlan {
  position: relative;
  top: 20px;
}

.discount {
  color: rgb(69, 141, 128);
  font-weight: 600;
}

.discountPercentage {
  font-size: 15.5px;
  font-weight: 450;
}