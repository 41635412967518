@import "../../../../styles/mixins";

.scopeSelect {
    border: 0;
    outline: none;
    background-color: transparent;
}

.optionalfields {
    height: 300px;
    overflow: auto;
}

.queryParams {
    .queryParams__item {
        margin-bottom: 12px;
        background-color: #efefef;
        padding: 12px 5px;
        border-radius: var(--bs-border-radius);
        display: flex;
        align-items: center;

        .grid {
            display: grid;
            grid-template-columns: auto auto auto;

            @include lowResolution {
                display: block;
                min-width: 80%;
            }

            .queryParamsItem__field {
                margin-left: 10px;
                min-width: 145px;

                @include lowResolution {
                    min-width: unset;
                    width: 100%;
                }

                &.valueField {
                    min-width: unset;
                    max-width: 160px;

                    @include lowResolution {
                        max-width: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
}