.pendingTransation {
  padding: 0px;
  font-size: 12px;
  text-align: center;

  img {
    width: 45px;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    color: #838181;
  }
}

