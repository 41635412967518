.blockPreview {
    border: 1px solid #ccc;
    background-color: #fcfcfc;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;

    * {
        pointer-events: none;
    }
}