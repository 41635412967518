.checkboxContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;

    .customCheckbox {
        width: 14px;
        height: 14px;
        background-color: #fff;
        border: 1px solid #e2e1e1;
        border-radius: 4px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        transition: background-color 0.2s ease-in-out;
    }

    .checked {
        border-color: #00b598;
        background-color: #00b598;
        color: #fff;
        font-size: 9px;
        text-align: center;
    }

    .checkboxContainer:hover .customCheckbox {
        border-color: #00b598;
    }
}

.wrapper {
    display: flex;
    flex-flow: column;
    position: relative;
    z-index: 10000;

    .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 3px;
        right: 3px;
    }
}

.popup {
    :global(.popover-arrow) {
        display: none;
    }

    .header {
        width: 273.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0px 10px !important;
    }

    .body {
        max-height: 350px;
        overflow-y: auto;

        hr {
            padding: 3px 0;
        }

        .filter_item {
            margin: 5px 0;

            .filter_header {
                display: flex;
                justify-content: left;
                align-items: center;
                margin-bottom: 3px;

                .icon {
                    margin-right: 8px;
                }

                .caption {
                    display: block;
                    font-weight: bold;
                }
            }

            .filter_body {
                margin-bottom: 10px;
            }
        }
    }

    .footer {
        background-color: #efefef;
        padding: 2px 5px;
        display: flex;
        justify-content: right;

        :global(.btn) {
            height: 1.5rem !important;
            min-width: 1.5rem !important;
            font-size: 0.75rem !important;
            padding-inline-start: 0.75rem !important;
            padding-inline-end: 0.75rem !important;
            margin-left: 5px !important;

            &:global(.cancelButton) {
                width: unset !important;
                padding: unset;
            }
        }
    }
}

.caption {
    display: block;
    font-weight: bold;
}
