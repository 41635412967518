.listitem {
    margin: 2px 0;
}

.listitem .item {
    /* padding: 3.5px 4px;
    margin: 0;
    border: 1px solid rgb(231, 235, 243) !important;
    border-radius: 4px !important;
    margin-top: 4px; */
    padding: 3.5px 4px;
    margin: 0;
    border: 1px solid rgb(231, 235, 243) !important;
    border-radius: 4px !important;
    margin-top: 4px;
    background-color: #E7F0FA;
    text-align: center;
    font-weight: 500;
}

.item .itemtitle {
    display: block;
}


.title {
    font-weight: bold;
}