.optionBox {
    background-color: #efefef;
    margin-bottom: 12px;
    padding: 5px;
    border-radius: var(--bs-border-radius);
    display: flex;
    align-items: center;
   
}

.idCol {
    display: flex;
    align-items: center;
    font-size: 12px;
}




.form {
    width: 85%;
}

.deleteButton {
    padding: 0 12px;
}

.removeBtn {
    color: rgba(255, 101, 101, 1) !important;
    font-size: 1.3rem;
    cursor: pointer;
}
.selectVariableCustomWidth {
    width: 500px;
}
.removeBtn:disabled {
    cursor: not-allowed;
    border: 0;
}