#map-container {
    position: relative;
    // width: 100%;
    // height: 100%;
    right: 7px;
}

.custom-control {
    position: absolute;
    z-index: 10;
    background: #fff;
    padding: 10px;
    border-radius: 4px;
}

#customControl1 {
    top: 35%;
    left: 10px;
    transform: translateY(-50%);
    width: 18%;
}

#customControl2 {
    top: 9%;
    right: 1px;
    width: 28%;
}

@media (min-width: 1700px) {
    #customControl2 {
        right: 9%;
    }
}

@media (max-width:1500px) {
    #customControl2 {
        right: 13%;
    }
}

.siteRow {
    &:hover {
        background-color: rgba(170, 210, 243, 0.1);
    }
}

.selectedSite {
    border-left: 3px solid #C5D4DB;
    background-color: rgba(170, 210, 243, 0.1);
}

.siteName {
    color: #007EC3;
}

.latLongHeight {
    height: 33px;
}

.latLongHeight::placeholder {
    font-size: 11px;
}

.createGeo {
    width: 25%;
    padding: 6px;
}

.scrollSites {
    max-height: 30vh;
    overflow-y: auto;
}

.closeSites {
    float: right;
    cursor: pointer;
    position: relative;
    bottom: 3px;
}

.geoIcon {
    float: right;
    border: 1px solid gray;
    border-radius: 4px;

    &:hover {
        background-color: rgba(170, 210, 243, 0.1);
    }

    &:focus {
        background-color: rgba(170, 210, 243, 0.1);
    }
}

.geoLocation {
    &:hover {
        color: #84a7da;
        text-decoration: underline;
    }

    &:focus {
        color: #84a7da;
        text-decoration: underline;
    }
}