.keyValueContainer {
    padding: 21px;
    background-color: #EFF3F5;
    border-radius: 8px;
    margin-bottom: 12px;
}

.customTextArea{
    min-height: 150px !important;
}
