@import "../../botFlows/styles/mixins";
@import "../../assets/scss/variables";

@keyframes fadeFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  /* Styles for mobile portrait screens */

  .dashboard {
    display: block !important;

    .main_section {
      padding: 0 !important;

      .jumbotron {
        .title {
          margin-bottom: 2px !important;
        }

        .subtitle {
          font-size: 16px !important;
        }

        .subscription_status {
          width: 110%;
          font-size: 13px !important;

          .upgradeBtn {
            background: #00B598;
            color: white;
            border-radius: 8px;
            padding: 2px 6px;
            font-size: 12px;
          }
        }

        img {
          height: 5rem;
          width: 5rem;
          margin-bottom: 3.1rem;
        }
      }

      .features {
        margin-top: 10px !important;

        // .feature_item{
        //   display: flex !important;
        // }
      }
    }

    .side_section {
      padding: 0 !important;
      border-left: none !important;

      .center {
        img {
          margin: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
  /* Styles for mobile landscape screens */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* Styles for tablet portrait screens */

  .dashboard {
    display: block !important;
  }

  .side_section {
    padding: 0 5rem !important;
    border-left: none !important;

    .center {
      img {
        margin: 0 !important;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  /* Styles for tablet landscape screens & Desktop*/
}

.dashboard {
  display: flex;
  margin-top: 5px;

  .main_section {
    padding: 10px 16px;

    .jumbotron {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 130px;

        @include highResolution {
          width: 150px;
        }
      }



      .title,
      .subtitle,
      .subscription_status {
        display: block;
      }

      .title {
        color: #040404;
        font-size: 23px;
        font-weight: 600;
        margin-bottom: 10px;

        @include lowResolution {
          font-size: 20px;
        }
      }

      .subtitle {
        color: rgba(4, 4, 4, 0.80);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;

        @include lowResolution {
          font-size: 18px;
        }
      }

      .subscription_status {
        color: rgba(4, 4, 4, 0.80);
        font-size: 17px;
        font-weight: 400;

        @include lowResolution {
          font-size: 15px;
        }

        :global(.btn) {
          color: #FF7342 !important;
          font-style: italic !important;
          font-weight: 700 !important;
          text-decoration-line: underline !important;

          @include lowResolution {
            font-size: 15px;
          }
        }

        .subscription_status_info {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fcf1d5;
          padding: 4px;
          border-radius: 8px;
          margin-top: 1rem;

          .plan_notification {
            color: #8a8a8a;
            font-size: 14px;
            font-weight: 400;
            margin-left: 6px;
          }
        }

      }
    }

    .features {
      margin-top: 25px;

      h3 {
        color: #353535;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 30px;

        @include lowResolution {
          font-size: 16px;
        }
      }

      a.feature_item {
        opacity: 0;
        transform-origin: center top;
        text-decoration: none;
        color: #3E3E3E;
        cursor: default;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        &.animation {
          animation: fadeFromTop .75s ease-in-out;
          animation-fill-mode: forwards;
        }

        img {
          width: 60px;
          margin-right: 16px;
          pointer-events: none;
          user-select: none;

          @include lowResolution {
            width: 45px;
          }

          @include highResolution {
            width: 75px;
          }
        }

        .label {
          .caption {
            color: #3E3E3E;
            display: block;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
            position: relative;
            transition: color 200ms ease-out;

            @include lowResolution {
              font-size: 12px;
            }

            &::after {
              content: "››";
              position: absolute;
              margin-left: 12px;
              opacity: 0;
              transform: translateX(-5px);
              transition: all 300ms ease-out;
            }
          }

          .subcaption {
            color: #A0A0A0;
            font-size: 12px;
            font-weight: 400;

            @include lowResolution {
              font-size: 9.5px;
            }
          }
        }

        &:hover {
          .label .caption {
            color: #1a1a1a;

            &::after {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }
  }

  .side_section {
    // margin: auto;
    // margin-top: 2rem;
    min-height: 80vh;
    min-width: 28vw;
    padding: 10px 25px;
    border-left: 1px solid #F1F1F1;

    .credit_img {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 1rem;
      border-radius: 0.5rem;
    }

    .referal_poster {
      margin-bottom: 1.5rem;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 1rem;
      border-radius: 0.5rem;

      .poster_content {
        h6 {
          // font-weight: 800;
          color: #458d80;
          text-decoration: underline;
          font-weight: bolder;
        }
      }

      .poster_content,
      .referral_link {
        text-align: center;
        padding: 0 1.5rem;
      }

      .referral {
        display: flex;
        justify-content: center;
        padding: 1rem;

        .your_referral {
          font-size: smaller;
          color: #666666;
        }

        .referralCode {
          background-color: rgb(198, 246, 213);
          border: 3px dashed #458d80;
          border-spacing: 1rem;
          border-radius: 0.5rem;
          width: fit-content;
          text-align: center;
          font-weight: bold;
          color: #458d80;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
          padding: 0.3rem;
          width: 100%;
          cursor: default;
          // font-size: 1.2em;
        }
      }
    }

    @include highResolution {
      margin-top: 10vh;
    }

    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    img.creditImg {
      pointer-events: none;
      user-select: none;
      // margin-left: 12%;
      // margin-top: -10%;
      // margin-bottom: 10%;
      width: 300px;

      @include lowResolution {
        width: 250px;
      }
    }

    .credit_balance {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 16px 0;


      .available_balance {
        margin-right: 30px;
        text-align: center;

        .amount {
          font-size: 26px;
          font-weight: 500;
          display: block;
          font-family: 'Noto Sans', sans-serif !important;

          @include lowResolution {
            font-size: 22px;
          }
        }

        .label {
          font-size: 12px;
          font-weight: 400;

          @include lowResolution {
            font-size: 10px;
          }
        }
      }

      a.cta {
        cursor: pointer;
        border-radius: 27px;
        background: #458d80;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
        padding: 10px 28px;
        color: white;
        text-decoration: none;
        transition: box-shadow 200ms ease;

        @include lowResolution {
          font-size: 12px;
          padding: 10px 24px;
        }

        &:hover {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }

    .whatsapp_channel {
      margin-top: 21px;
      width: 100%;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
      padding: 16px 20px;

      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .qrCode {
          width: 80px;
          margin-right: 18px;
          margin-left: 10px;
        }

        .caption {
          color: #2F3652;
          font-size: 18px;
          font-weight: 600;
          line-height: 136.5%;

          @include lowResolution {
            font-size: 12px;
          }

          :global(.whatsapp-green) {
            color: #6EC77C;
          }

          .info {
            font-size: 12px;
            margin-left: 5px;
            color: #9a9a9a;
          }
        }

        .selectChannel {
          margin-top: 12px;

          .control {
            border-color: #6EC77C !important;
          }

          .indicatorSeparator {
            display: none;
          }
        }
      }

      .linkEditor {
        margin-top: 16px;
        // display: flex;
        justify-content: space-between;
        align-items: center;

        a {
          color: #007EC3;
          font-size: 15px;
          font-weight: 600;
          padding-left: 1%;

          @include lowResolution {
            font-size: 12px;
          }
        }

        .options {
          margin-bottom: 3%;

          button {
            border-radius: 4px;
            border: 1px solid #EDEDED;
            background-color: white;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            color: black;
            padding: 3px 8px;
            margin-left: 3px;

            @include lowResolution {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.whatsappLinkInfo {
  svg {
    height: 14px;
    margin-left: 4px;
    cursor: default;
  }
}