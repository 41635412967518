@import "../../../styles/mixins";

.caption {
    display: flex;
    justify-content: center;
    align-items: center;

    @include lowResolution {
        font-size: 12px;
    }
}

.editorIcon {
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    color: var(--primary);
    width: 40px;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F7F7;
    margin-right: 15px;
    margin-left: 10px;
    margin-top: 12px;

    @include lowResolution {
        width: 32px;
        height: 32px;
        margin-top: 12px;
        margin-right: 12px;
        img {
            width: 12px !important;
        }
    }
}