.optionBox {
    background-color: #efefef;
    margin-bottom: 12px;
    padding: 21px;
    border-radius: var(--bs-border-radius);
}


.idCol {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.removeBtn {
    color: rgba(255, 101, 101, 1) !important;
    font-size: 1.3rem;
}