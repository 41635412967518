.name-tag{
.tagTable{
  padding-bottom: 2%;
    .react-bs-table-tool-bar .form-group {
      position: absolute;
      top: 20%;
      right: 3%;
      float: left;
    }
  }

  .cancelBtn {
    background: #FBF8F8;
    border: 1px solid #d6d2d2;
    margin-top: 10px;
    color: black;
    box-shadow: none;
    font-size: 14px;
}

.cancelBtn:hover {
    background: #FBF8F8;
    border: 1px solid #f7f5f5;
    margin-top: 10px;
    color: black;
    box-shadow: none;
    font-size: 14px;
}

.cancelBtn:focus {
    background: #FBF8F8;
    border: 1px solid #f7f5f5;
    margin-top: 10px;
    color: black;
    box-shadow: none;
    font-size: 14px;
}
.circlelogo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #CAF1E8;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.logomargin {
  margin-left: 29% ;
}
}

.isDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  pointer-events: none;
}
