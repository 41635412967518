ul.connectionList {
    list-style: none;

    li.connectionItem {
        display: flex;
        margin: 0;
        margin-bottom: 12px;
        justify-content: space-between;
        align-items: center;
        background-color: #efefef;
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 6px;

        .name {
            font-size: 0.8rem;
        }
    }
}

.smallToggle {
    font-size: 14px;
}

.noPointer {
    pointer-events: none;
}

.centerScreen {
    max-width: 100%;
    max-height: 100%;
    margin-top: 2rem;
    cursor: pointer;
}

.parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}