@import "../../../../assets/scss/variables";
@import "../../../styles/mixins";
@import "../../../styles/partials";


.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    z-index: 1000;
    height: 100%;
    width: 100%;
}

.offcanvas {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 30vw;
    background-color: white !important;
    z-index: 1045;
    overflow-x: hidden;
    overflow-y: auto;
    animation: initialAnimation 300ms linear;

    &.enter {
        transform: translateX(30vw);
    }

    &.enterActive {
        transform: translateX(0);
    }

    &.enter,
    &.exit {
        transition: transform 300ms linear;
    }

    &.enter,
    &.enterActive {
        transform: translateX(0);
    }

    &.exit,
    &.exitActive {
        transform: translateX(30vw);
    }
}

@keyframes initialAnimation {
    0% {
        transform: translateX(30vw);
    }

    100% {
        transform: translateX(0);
    }
}

.editor {
    overflow: visible;
    min-height: max-content;

    * {
        @include lowResolution {
            font-size: 10px !important;
        }
    }

    @include lowResolution {
        :global(.form-control) {
            padding: 5px 12px !important;
            line-height: normal !important;
            height: 30px !important;
        }
    }

    @include cancelBtn;

    :global(.offcanvas-body),
    :global(.offcanvas-header) {
        padding: 0 16px;
    }

    :global(.offcanvas-header) {
        margin-top: 12px;
    }

    :global(.offcanvas-body) {
        margin-bottom: 60px;
        min-height: 80vh;
        overflow-y: visible !important;
    }

    :global(.editor-footer) {
        padding: 12px;
        overflow: hidden !important;
        position: fixed;
        display: flex;
        justify-content: right;
        background-color: #efefef;
        bottom: 0px !important;
        right: 0;
        width: 30vw !important;
        z-index: 1200;
    }
}

// Don't Edit or Remove this
:global(.disabledState) {
   pointer-events: none !important;
   opacity: 0.5 !important;
}

:global(.notAllowed) {
    cursor: not-allowed !important;
}

:global(.notifyIcons) {
    padding-bottom: 7px;
    color: rgba(152, 152, 152, 1) !important;
}

:global(.pointer) {
    cursor: pointer !important;
}

:global(.checkColor) {
    background-color: #21c237;
    border-radius: 100px;
    padding: 3px 4px;
    margin-left: 10px;
    margin-top: 6px;
    margin-right: 10px;
}