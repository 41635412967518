.dragDropTextColor {
    color: #718096;
}

.dragDropFiles {
    border: 1px dashed #718096;
    padding: 30px;
    border-radius: 4px;
}

.uploadImage {
    height: 130px;
    width: 100px;
    border: 1px solid #718096;
    padding: 6px;
    border-radius: 4px;
    font-size: 20px;
}

.uploadDocs {
    font-size: 16px;
    position: relative;
    bottom: 23px;
    text-align: center;
}

.file-preview-wrapper {
    position: relative;
    display: inline-block;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    background: #e16161;
    border-radius: 50%;
    padding: 3px;
}