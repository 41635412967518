.badge {
  display: inline-block;
  padding: 5px 12px;
  border-radius: 4px;
  color: #3B3E45;
  font-size: 14px;
  font-style: normal;
}

.badge.success {
  background-color: rgba(110, 199, 124, 0.2);
}

.badge.danger {
  background-color: rgba(217, 11, 11, 0.2);
}

.ellipsisCell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.badge.yellow {
  background-color: #fefcbf;
  color: #dca51f;
}

.statusBadge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #eff8ef;
  font-size: 12px;
  color: #327a30;
  text-align: center;
}

.errorBadge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #ce2d2d;
  background-color: #ffeaea;
}

.activeBadge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #589CE3;
  background-color: #dbebfa;
}

.failedBadge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  color: #ce2d2d;
  background-color: #ffeaea;
  text-align: center;
}


/* Container styles */
.dropdownContainer {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 90%;
  margin: 20px auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}

.dropdownHeader {
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.dropdownTitle {
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0;
}

.dropdownArrow {
  font-size: 1rem;
  transform: rotate(0);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
}

.dropdownArrow.open {
  transform: rotate(180deg);
}

/* Dropdown Content */
.dropdownContent {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease, transform 0.3s ease-in-out;
}

.dropdownContent.show {
  /* Adjust based on content */
  opacity: 1;
  transform: translateY(0);
  max-height: max-content;
}

/* Tabs */
.tabsContainer {
  display: flex;
  justify-content: space-between;
  /* Ensures spacing between left and right content */
  align-items: center;
  /* Centers items vertically */
  gap: 20px;
  margin-bottom: 10px;
  padding: 10px 0;
  /* Adjust padding to reduce width */
  border-bottom: 1px solid #ddd;
  margin-left: 15px;
  /* Align with content for better visibility */
  margin-right: 15px;
}


.tabHeading {
  font-size: 0.88rem;
  color: gray;
  cursor: pointer;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.tabHeading.active {
  color: #007bff;
  /* Active tab color */
  font-weight: bold;
}

/* Tab Content */
.tabContent {
  padding: 10px 15px;
  animation: fadeIn 0.5s ease-in-out;
}

/* Keyframes for animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.breakLine {
  border-bottom: 1px solid #ddd;
}

.fontSmall {
  font-size: 0.8rem;
}

.preText {
  background-color: transparent;
  font-size: 12px;
}

.customLabel {
  font-size: 0.78rem !important;
  display: inline-block;
  width: 120px; 
  text-align: center;
}
