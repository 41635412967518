.selectList {
    list-style-type: none;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 5px;

    li {
        display: block;
        padding: 5px 8px;
        min-width: 140px;
        border-radius: 6px;
        cursor: pointer;

        &.selected {
            background-color: #efefef;
        }

        &:hover {
            background-color: #efefef;
            cursor: pointer;
        }
    }
}