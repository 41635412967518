.dropdownMenu {
    img{
        margin-top: 3px;
    }

    h3 {
        font-size: 0.8rem;
        color: #9f9f9f;
        margin: 0;
        margin-bottom: 5px;
        padding: 5px 15px;
    }

    .dropdownMenu_item {
        font-size: 0.8rem;
        min-width: 226px;
        background: transparent !important;
        border: 0 !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .label {
            font-weight: 200;
        }

        .timestampPreview {
            color: #9f9f9f;
            font-size: 0.7rem;
        }

        &:hover {
            background: #efefef !important;
        }

        &:focus {
            color: black !important;
        }
    }
}

.calenderMenu {
    width: 300px !important;

    :global(.react-calendar) {
        border: 0 !important;
    }

    .container {
        padding: 10px 12px;

        .action {
            display: flex;
            justify-content: right;

            :global(.cancelButton),
            :global(.btn) {
                width: unset;
                padding: 3px 20px;
            }
        }

        .timeInput {
            width: 140px;
            padding: 1px 6px;
            input,
            select {
                border: none;
                outline: none;
                background-color: transparent;
            }

            input {
                width: 38px;
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                appearance: none;
                margin: 0;
            }

            input[type="number"]::-moz-inner-spin-button,
            input[type="number"]::-moz-outer-spin-button {
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }
        }
    }
}

.editDropdown {
    width: 240px !important;

    h3 {
        font-size: 0.8rem;
        color: #9f9f9f;
        margin: 0;
        margin-bottom: 5px;
        padding: 5px 15px;
    }

    .followupItem {
        display: flex;
        align-items: center;
        padding: 1px 5px;

        .timestamp {
            margin: 0 10px;
            font-size: 14px;
        }

        .action {
            :global(.btn) {
                font-size: 0.85rem;
                color: #9f9f9f;
            }
        }
    }
}