
.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Two equal columns */
    gap: 10px; /* Space between grid items */
    margin-top: 30px;
    width: 50%;
}

.disabledSelect {
    pointer-events: none; /* Prevent interaction */
    opacity: 0.6; /* Make it look grayed out */
    cursor: not-allowed; /* Show a "not allowed" cursor */
}