.addIcon {
  background: #00b598;
  padding: 2px 4px;
  border-radius: 50%;
  color: white;
}

.dragDropsContainer {

  .ticketFieldHeader{
    margin-top: 0.5rem;
    font-size: small;
    display: flex;
    flex-direction: column;
    // justify-content: start;
    // align-items: center;
    margin-bottom: 0.5rem;
    
    .ticketInfo{
      color: #a5a8ab;
      padding: 6px;
      border-radius: 4px;
    }
  }
  .dragDropItems {
    .staticItems {
      border: 1px solid rgb(239, 238, 238);
      height: 77vh;
      border-radius: 4px;

      .staticHeader{
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        font-size: 14px;
        font-weight: 500;
      }
      #ticketFieldDisable {
        pointer-events: none;
        opacity: 0.4;
      }

      #ticketFieldAble {
        pointer-events: all;
        opacity: 1;
      }

      .draggableItem {
        display: flex;
        justify-content: space-between;
        // color: red;
      }
    }
    .dynamicItems {

      .dynamicHeader{
        border: 1px solid rgb(239, 238, 238);
        height: 8vh;
        margin-bottom: 12px;
        border-radius: 4px;
        display: flex;
        justify-content: end;
        align-items: center;

        .dynamicHeaderSearch{
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgb(239, 238, 238);
          padding: 6px;
          border-radius: 4px;
          margin-right: 1rem;
          
         
          input{
            border: none;
            outline: none;
            padding: 0;
  
            :focus{
              outline: none;
            }
          }

        }

      }
      .dynamicItemList {
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: thin;
        height: 67vh;
        padding: 10px 30px;
        border: 1px solid rgb(239, 238, 238);
        border-radius: 4px;
      }
    }
    #fieldDisable {
      pointer-events: none;
      opacity: 0.4;
    }

    #fieldAble {
      pointer-events: all;
      opacity: 1;
    }

    .fieldForm {
      .label {
        // width: 50%;
        // display: flex;
        // flex-direction: column;
        margin-top: 1rem;
      }

      .statusDropDownTable {
        // margin-top: 1rem;
        border: 1px solid #cfd7df;
        // label{
        //     margin-top: 1rem;
        //     margin-left: 1rem;
        // }

        .tableHeader {
          background: #ebeff3;
          margin-bottom: 1rem;
          label {
            margin: 0;
            padding: 0.5rem 0;
          }
        }
      }
    }

    .typeForm {
      .addIcon {
        background: #00b598;
        padding: 2px 4px;
        border-radius: 50%;
        color: white;
      }
    }

    .footer {
      display: flex;
      justify-content: end;
    }
  }

  .defaultField{
    background-color: #ebeff3;
    border: 1px solid #ebeff3;
    padding: 2px 6px;
    border-radius: 3px;
    font-size: smaller;
    font-weight: 600;
    color: black;
    margin-left: 0.5rem;
  }

  .hoverDelete {
    display: none;
    cursor: pointer;
  }
  &:hover {
    .hoverDelete {
      display: inline-block;
      cursor: pointer;
    }
  }



  // .cancelbutton {
  //   background-color: #fbf8f8;
  //   border: 1px solid #e2e2e2 !important;
  //   font-size: 1em;
  //   color: #3b3e45 !important;
  //   border-radius: 4px;
  //   width: fit-content;
  //   padding: 8px 12px;
  //   margin-right: 0.5rem;
  // }
}

.cancelbutton {
  background-color: #fbf8f8;
  border: 1px solid #e2e2e2 !important;
  font-size: 1em;
  color: #3b3e45 !important;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 12px;
  margin-right: 0.5rem;
}

.discardButton{
  background-color: #00b598;
  border: 1px solid #e2e2e2 !important;
  font-size: 1em;
  color: white !important;
  border-radius: 4px;
  width: fit-content;
  padding: 8px 12px;
  margin-right: 0.5rem;
}

.modalText{
  font-size: small;
  color: #6f7c87;
  }
  
.headerLabel{
  font-size: smaller;
  color: #6f7c87;
  margin-left: 0.5rem;
}
