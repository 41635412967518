.campaignInfo {
    min-width: 80vw;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        .title {
            font-weight: bold;
            font-size: 1.25rem;
        }

        small {
            color: black;
            font-size: .7em;
            margin-left: 5px;
        }
    }

    .infoGrid {
        margin-top: 16px;

        :global(.col) {
            margin: 5px;
        }

        :global(.card) {
            min-height: 80px;
            border-radius: 2px;
            border: 1px solid #F4F4F4;
            background: #FFF;
            box-shadow: 3px 4px 8px 0px rgba(0, 0, 0, 0.03);
        }

        :global(.card-subtitle) {
            color: #909090;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: 3px;
        }

        .excelGrid {
            display: flex;
            align-items: center;

            img {
                width: 35px;
                height: 35px;
                margin-right: 12px;
            }

            button {
                border: none;
                cursor: pointer;
                border-radius: 1px;
                background: #DDF3FF;
                color: #007EC3;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
            }
        }
    }

    .contactSelected {
        .item {
            border: 1px solid #F4F4F4;
            background: var(--white, #FFF);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            padding: 10px;
            text-align: center;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 12px;
            margin-top: 7px;
            
            svg {
                margin-right: 12px;
            }

            a {
                color: #007EC3;
                font-weight: 500;
            }
        }
    }

    .cta {
        margin-top: 16px;
        width: 100%;
    }
}

.testCampaignInfo{
    .information{
        background: #FBFBFB;
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        padding: 1em;
        display: flex;
    }
}