.wrapper {
    position: relative;

    .selectInput {
        cursor: pointer;
    }

    .suggestion {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        position: absolute;
        background-color: white;
        width: 100%;
        z-index: 1200;
        max-height: 180px;
        overflow-y: auto;

        .suggestion_message {
            padding: 10px 12px;
            color: #959595;
        }

        .suggestion_item {
            padding: 10px 12px;
            cursor: pointer;

            &:hover {
                background-color: #efefef;
            }
        }
    }
}