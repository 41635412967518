//-*---------------*-  Profile -*---------------*-//
$global-bg-color:#FBF8F8;
$profile-border:#3692c4b9;
$global-border-color: #E2E2E2;

.profile-container {
  label {
    font-size: 13px;
    font-weight: 400;
    color: #20102B;
    margin-bottom: 5px;
  }
  .formInput{
    margin-bottom: 20px;
  }
}
// .css-13cymwt-control{
//   border-color: #ededf5 !important;
// }
#businessMobileNumber{
  border: none;
}
.inputBorder{
  border: 1px solid #ededf5;
}
.cameraImg {
  position: relative;
  bottom: -30px;
  right: 22px;
  cursor: pointer;
}
.profileImageContainer{
  position: relative;
}
.cameraImg-sample{
  position: relative;
  top: 65px;
  right: 26px;
  cursor: pointer;
}
.uploadBtn {
  border: 2px solid #7B7B7B;
  border-radius: 5px;
  cursor: pointer;
  padding: 4px 6px;
}

.profile-img {
  border: 5px solid $profile-border;
  border-radius: 100%;
  height: 100px;
  width: 100px;
}

.profile-sample{
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  border: 4px solid rgba(0, 126, 195, 0.50);
  vertical-align: top;
  font-size: calc(1.2rem);
  background:$global-bg-color;
  height: 100px;
  width: 100px;
}
.profileBtnCon{
  margin: auto;
  text-align: center;
}
.profile-info {
  color: #7B7B7B;
  margin-top: 2em;
  margin-bottom: 1em;
}

.profile-data {
  border-bottom: 2px solid $profile-border;
  cursor: pointer;
}

.business-profile {
  border-bottom: 2px solid $profile-border;
  cursor: pointer;
}

.dataset {
  cursor: pointer;
}

.disabled-data{
  opacity: 0.4 !important;
    cursor: not-allowed !important;
    // background: none !important;
}
.page-heading{
  margin-left: -4rem;
}