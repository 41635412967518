
.iconContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trashIcon {
    margin-left: auto; 
    cursor: pointer;
    color: #ff4d4f;
    padding-right: 10px;
    font-size: medium;
}

.smallFont {
    font-size: 14px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.addRowLabel {
    font-weight: bold;
}

.info {    
    color:rgba(152, 152, 152, 1);
    margin-right: 10px;
    min-width: 1.2em;
}

