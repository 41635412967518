$borderRadius: 20px;
@mixin iconButton {
  padding: 10px 12px;
  min-width: 15px;
  font-size: 1.2em;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.2)
  }
}

// Material input {{{
.matBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.2);
  z-index: 200;
}

.matModal {
  z-index: 500;
  position: absolute;
  top: 30%;
  left: 12%;
  width: 200px;
  padding: 10px;
  background-color: #fff;
  border-radius: 6px;
}

.matDummyDatepicker {
  padding: 5px 0;
  text-align: center;
  user-select: none;

  .disabledDate,
  .enabledDate {
    padding: 2px 0;
  }

  .disabledDate {
    font-size: 1.1em;
    color: #aaa;
  }

  .enabledDate {
  font-size: 1.2em;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
}

.matInputContainer {
  position: relative;
  margin-top: 12px;
  width: 100%;
}

.matInput {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: transparent;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #22c55e;
  }

  &:focus + .matLabel,
  &:not(:placeholder-shown) + .matLabel {
    transform: translateX(-8px) translateY(-28px) scale(0.75);
    color: #22c55e;
  }
}


.matLabel {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0 4px;
  color: #999;
  background-color: white;
  transition:
    transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
    color 0.3s ease;
  pointer-events: none;
  font-size: 14px;
}

.matInputFocused {
  color: #22c55e;
  transform: translateY(-28px) scale(0.75);
}

.matInputHelptext {
  display: block;
  color: #4a5568;
  font-size: 10px;
  margin-bottom: 12px;
}

.textareaContainer {
  .matLabel {
    top: 20%;
  }

  .matInput {
    height: 90px;
    resize: none;
  }
}
//}}}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.flowPreview {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: $borderRadius;
  height: 500px;
  width: 270px;
  margin: auto;
  background-color: #fff;
  background-image: url("../../assets/img/ChatbackgroundImg.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  .flowPreviewOverlay {
    position: absolute;
    top: 9%;
    left: 0;
    width: 100%;
    height: 91%;
    background-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
    animation-fill-mode: forwards;

    .flowPreviewOverlayHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ccc;
      color: #4a5568;

      .flowPreviewOverlayHeaderIcon {
        border: none;
        @include iconButton;
        background-color: transparent;
        font-size: 0.8em;
        color: #4a5568;
      }

      .flowPreviewOverlayHeaderTitle {
        display: block;
      }
    }

    .flowPreviewOverlayContainer {
      padding: 10px;
      height: 80%;
      overflow-y: auto;

      .flowPreviewText {
        display: block;
        padding: 2.5px 0;

        &.type_largeHeading {
          font-size: 1.2em;
          font-weight: bold;
        }

        &.type_smallHeading {
          font-size: 1em;
          font-weight: bold;
        }

        &.type_caption {
          font-weight: 200;
        }
      }
    }

    .flowPreviewOverlayFooterButton {
      margin: 5px 10px;
      background-color: #22c35e;
      width: 93%;
      border: none;
      outline: none;
      border-radius: 10px;
      color: #fff;
      padding: 5px 10px;
      transition: background-color 0.5s;

      &:hover {
        background-color: #179848;
      }
    }
  }

  .flowPreviewOverlayEnter {
    animation: slideFromBottom 450ms ease-in-out;
  }

  .flowPreviewOverlayExit {
    animation: slideFromBottom 450ms ease-in-out reverse;
  }

  // Whatsapp dummy {{{
  .flowPreviewHeader {
    background-color: #008069;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    .flowPreviewHeaderLogo {
      width: 20px;
      margin-right: 5px;
    }

    .flowPreviewHeaderIcon {
      @include iconButton()
    }

    .flowPreviewHeaderTitle {
      font-size: 1em;
      display: block;
      font-weight: bold;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .flowPreviewMessages {
    padding: 5px 12px;
    margin-top: 5px;
    height: 390px;

    .flowPreviewMessage {
      background-color: #fff;
      border-radius: 6px;

      .flowPreviewMessageContainer {
        padding: 10px;

        .flowPreviewMessageFiller {
          display: block;
          width: 100%;
          height: 10px;
          background-color: #edf2f7;
          border-radius: 6px;
          margin: 5px 0;
        }
      }

      .flowPreviewMessageButton {
        color: #3182ce;
        font-weight: bold;
        text-align: center;
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        transition: background-color 0.3s;
        padding: 5px 0;

        &:hover {
          background-color: rgba($color: #000000, $alpha: 0.025)
        }
      }
    }
  }

  .flowPreviewFooter {
    padding: 10px 12px;
    display: flex;

    .flowPreviewFooterInput {
      width: 90%;
      padding: 2px;
      color: #a0aec0;
      background-color: white;
      border-radius: 20px;
      display: flex;
      align-items: center;

      .flowPreviewFooterInputText {
        display: block;
        width: 70%;
      }

      .flowPreviewFooterInputIcon {
        @include iconButton()
      }
    }

    .flowPreviewFooterButton {
      border: none;
      outline: none;
      border-radius: 50%;
      font-size: 1.5em;
      color: #fff;
      background-color: #008069;
      padding: 5px;
      width: 40px;
      margin-left: 5px;
    }
  }

  // }}}
}
// vi: fdm=marker:
