.backBtn {
    color: black !important;
    border-radius: 50%;
    margin-right: 10px;
    outline: none;

    &:hover {
        transition: all 200ms ease;
        background-color: #efefef;
        cursor: pointer;
    }
}

.container {
    max-width: 450px;
    margin: auto;
    text-align: center;

    :global(.cancelButton) {
        width: unset;
    }
}

.downloadSample {
    h3 {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 18px;
    }

    p {
        font-size: 1rem;
        color: #4a5568;
    }

    .icon {
        cursor: pointer;
        color: #718096;
        font-size: 4rem;
        display: block;
        margin: 18px auto;
        margin-bottom: 5px;
    }
}

.uploadExcel {
    .dropzone {
        border: 3px dashed #e2e8f0;
        border-radius: 5px;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        margin-bottom: 18px;
        color: #718096;
        font-size: 1rem;

        .icon {
            display: block;
            font-size: 2rem;
            margin: 16px auto;
        }
    }

    .contactFile {
        margin-bottom: 18px;
        border: 1px solid #e2e8f0;
        text-align: left;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        background-color: #fafafa;

        .icon {
            width: 24px;
            margin-right: 12px;
        }

        .filename {
            font-size: 0.8rem;
            font-weight: 500;
        }
    }

    .formItem {
        text-align: left;

        :global(.form-check-input) {
            margin-top: 24px;
        }

        :global(.form-check-label),
        :global(.form-text) {
            margin-left: 10px;
        }

        :global(.form-check-label) {
            font-size: 1rem;
            font-weight: 500;
        }

        :global(.form-text) {
            font-size: 0.8rem;
        }
    }
}