input[type="checkbox"] {
    accent-color: #009980;
  }
.tagList {
    .tagItem {
        display: flex;
        align-items: center;
        padding: 10px 12px;
        background: #FAFAFA;
        margin-bottom: 12px;

        .delete {
            margin-right: 10px;

            :global(.btn) {
                color: #EF5858;
                font-size: 22px;
            }
        }

        .selectTag {
            :global(.css-b62m3t-container) {
                width: 100% !important;
                min-width: 250px;
            }
        }
    }
}

.tagsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 3px 0;
    margin-bottom: 10px;

    .contactTag {
        background-color: #efefef;
        border-radius: 12px;
        padding: 3px 16px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        button {
            outline: none;
            border: none;
            font-weight: bold;
            font-size: 16px;
            display: block;
            cursor: pointer;
            margin-left: 5px;
        }
    }
}