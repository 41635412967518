.productItem {
  background-color: #efefef;
  margin-bottom: 12px;
  padding: 12px 5px;
  border-radius: var(--bs-border-radius);
}

input,
textarea {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
}

input[type="button"] {
  background-color: lightgray;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-group {
  clear: both;
  margin: 15px 0;
  position: relative;
}

.input-group input[type='button'] {
  background-color: #eeeeee;
  min-width: 38px;
  width: auto;
  transition: all 300ms ease;
}

.input-group .button-minus,
.input-group .button-plus {
  font-weight: bold;
  height: 38px;
  padding: 0;
  width: 38px;
  position: relative;
}

.button-plus {
  left: -13px;
}


#round {
  position: relative;
  margin-bottom: 3px;
  color: rgba(152, 152, 152, 1);
  margin-right: 10px;
}

#deleteButton {
  outline: none;
  border: none;
  margin-bottom: 20%;
  margin-left: 15px;
  background-color: orangered;
  border-radius: 70%;
  width: 28px;
  height: 25px;

}

#icon {
  color: white;
}

.quantity {
  text-align: center;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 50px;
}

.taxLabel {
  font-size: 16px;
}

.advancedLabel {
  font-size: 17px;
  font-weight: 490;
}

/* YourComponent.module.css */
.dropdown {
  font-family: Arial, sans-serif;
  
  position: relative;
  width: 100%;
}

.dropbtn {
  background-color: #f3f8fd;
  color: #333;
  padding: 10px 15px;
  font-size: 13px;
  border: 1px solid #ccc;
  width: 100%;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownContent {
  border: 1px solid #ccc;
  border-top: none;
  /* overlaps with the button to prevent double-border */
  padding: 10px;
  box-sizing: border-box;
  background-color: #f3f8fd;
}

.label {
  display: block;
  /* makes label take up its own line */
  margin: 10px 0 5px;
}

.input,
.textarea {
  width: calc(100% - 22px);
  /* adjust the calculation based on your specific padding/border */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  /* includes padding in the width */
}

/* Style for the optional tag */
.optionalTag {
  font-size: 0.8em;
  color: #666;
  margin-left: 5px;
}

/* Additional styling to position and size the form */
.formContainer {
  max-width: 471px;
  /* The width as per your image */
  margin: auto;
}

/* Adjust this class to make the dropdown visible as needed */
.visible {
  display: block;
}

/* Your additional button styles here */


/* You can use media queries to handle responsiveness */
@media (max-width: 480px) {

  .dropdownContent,
  .input,
  .textarea {
    width: 100%;
    /* On small screens, the input can take full width */
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}


.unsupportedMessage{
  background: #fff5f5;
  padding: 5px;
  width: fit-content;
  margin-top: 0.5rem !important;
  border-radius: 5px;
  display: flex;
}