.fbButton button {
    background: #00B598;
    border: #00B598;
    color: #fff;
    box-shadow: none;
    font-size: 14px;
    padding: 7px;
    margin: 5%;
    border-radius: 5px;
    white-space: nowrap; 
    margin-top: -10px;
}

.fbButton button:hover {
    background-color:  #458d80;
}
