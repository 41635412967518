.loadingVia {
  width: 25px;
  height: 25px;
  border: 5px dotted #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.wrMsg {
  display: flex;
  border-radius: 0.5rem;
  background: #fff5dc;
  color: #a88300;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  width: fit-content;
  padding: 6px;

  .notify-icon {
    align-items: center;
  }
  .credit-notification {
    display: flex;
    align-items: center;
  }
}

.ticketFieldsWidth {
  width: 95%;
}

.contains-link {
  color: #4a91d4
}

.contains-link-bg {
  background-color: #96cff61a !important;
}

.ticketplaceholder {
  color: #999;
  font-size: small;
  width: 95%;
}

.ticketplaceholder {
  color: #999;
  font-size: small;
  width: 95%;
}

.addOns-section {
  height: 65vh;
  overflow-y: scroll;
  width: 106%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  .form-field {
    margin-bottom: 1rem;
    width: 90%;
  }
}

.viewTicket-nav-bottom {
  margin-top: 2rem;
  border-radius: 6px;
  // justify-content: center;
  align-items: center;
}

.viewTicket-nav,
.viewTicket-nav-bottom {
  display: flex;
  padding: 1rem;
  background: #f5f7f9;
  border-bottom: 1px #cfd7df solid;

  button {
    background-color: #f3f5f7;
    font-size: 14px;
    padding: 6px 12px;
    align-items: center;
    justify-content: center;
    border: 1px solid #cfd7df;
    border-radius: 4px;
    color: #264966;
    cursor: pointer;
    transition: all 0.2s linear;
    font-weight: 500;
    height: 32px;
    min-width: 80px;
    border: 1px solid #cfd7df;
    margin-right: 0.5rem;
    background-image: linear-gradient(to bottom, #fff, #f3f5f7);
  }
}

.viewTicket-description {
  height: 80vh;
  overflow-y: scroll;
  padding: 0px 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  .statusDetails {
    padding: 3px 0 0.75rem 0;

    .ticket-status-new,
    .ticket-status-overdue,
    .ticket-status-customer,
    .ticket-status-undelivered {
      // background: red;
      border-radius: 4px;
      width: fit-content;
      padding: 4px 8px;
    }

    .ticket-status-new {
      background: #e0f5f0;
      color: #007958;
      border: 1px solid #b4e5d9;
    }

    .ticket-status-overdue {
      background: #ffecf0;
      color: #c82124;
      border: 1px solid #ffd0d6;
    }

    .ticket-status-customer {
      background: #e5f2fd;
      color: #2c5cc5;
      border: 1px solid #bbdcfe;
    }

    .ticket-status-undelivered {
      background: #fef1e1;
      color: #c13e1b;
      border: 1px solid #fddbb5;
    }
  }

  .sticky-title-wrapper {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Adjust as necessary */
    background-color: white;
    /* Ensure it covers the background */
  }

  .ticket-title {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1rem;
    }

    h6 {
      margin: 0;
    }
  }
}

.ticketUpdate {
  // margin-top: 0.1rem;
  padding: 1rem;
  // background: #ebeff3;
  height: 89vh;
  // border-radius: 6px;
  // border-left: 1px #ededed solid;

  .ticketUpdate-header {
    margin-bottom: 0.5rem;

    .ticket-details {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  .ticketUpdate-body {
    margin-top: 0.3rem;

    .ticketUpdate-body-header {
      display: flex;
      align-items: center;

      .header {
        border-bottom: 3px solid #c5d4db;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        cursor: pointer;
        margin: 0;
        border-radius: 3px;
        width: 7.5rem;
        // color: #c5d4db;

        &:hover {
          background: rgb(236, 248, 240);
          color: #00b598;
          border-bottom: 3px solid #00b598;
        }
      }

      .headeractive {
        background: rgb(236, 248, 240);
        color: #00b598;
        border-bottom: 3px solid #00b598;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        cursor: pointer;
        margin: 0;
        border-radius: 3px;
        width: 7.5rem;
        // color: #c5d4db;
      }
    }

    .ticketForm-update {
      height: 57vh;
      overflow-y: scroll;
      width: 106%;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: white;
        border-radius: 5px;
      }

      .form-field {
        margin-bottom: 1rem;
        width: 90%;
      }
    }

    // .add-on-field{
    //   margin-top: ;
    // }

    .form-footer {
      // position: fixed;
      // bottom: 0;
      z-index: 10;
      margin-bottom: 0.5rem;
      width: 97%;


      button {
        width: 99%;
        background: #00b598;
        border: none;
        color: white;
        font-weight: 700;
        font-size: 14px;
        padding: 0.5rem;
        border-radius: 4px;
      }
    }
  }

  .form-footer {
    margin-top: 0.5rem;
    width: 100%;

    .not-allowed {
      cursor: not-allowed;
    }

    button {
      width: 100%;
      background: #00b598;
      border: none;
      color: white;
      font-weight: 700;
      font-size: 14px;
      padding: 0.5rem;
      border-radius: 4px;
    }
  }
}

.avatar,
.inbox-avatar {
  color: rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  margin-right: 2rem;
}

.avatar {
  background-color: #eed8f7;
}

.inbox-avatar {
  background-color: #fff4a8;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 1rem;
  width: 36px;
  height: 36px;
  font-weight: 500;
  font-size: 16px;
}

.classCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.classStart {
  display: flex;
  justify-content: start;
  align-items: center;
}

.inbox-container {
  border-radius: 6px;
  padding: 0.5rem;
  margin-bottom: 1rem;

  .inbox-header {
    margin-bottom: 1.5rem;

    .inbox-from {
      display: flex;
      align-items: center;

      .email-id {
        color: #2c5cc5;
        font-weight: 500;
        font-size: medium;
        margin-right: 0.5rem;
      }

      .email-type {
        font-size: small;
      }
    }

    .inbox-date {
      font-style: italic;
      font-size: smaller;
    }
  }

  .inbox-body {

    .email-to-from,
    .notified-to {
      margin-bottom: 2rem;

      .email-to,
      .email-from {
        display: flex;

        .email-receiver {
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.input-component {
  .email-id-details {

    .from-mail,
    .to-mail {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cc {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .carbon-copies {
      display: flex;
      font-size: 12px;
      cursor: pointer;
      color: #2c5cc5;
      font-weight: 500;
    }
  }

  .btnloader {
    width: 15px;
    height: 15px;
    border: 2px dotted #fff;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .showTicket {
    font-weight: 550;
  }

  .showTicket:hover {
    color: #2c5cc5;
    cursor: pointer;
    margin-left: 5px;
  }

  .showTicket:focus {
    color: #2c5cc5;
    cursor: pointer;
    margin-left: 5px;
  }

  .showTicket:active {
    color: #2c5cc5;
    cursor: pointer;
    margin-left: 5px;
  }

  .ticketFilter {
    float: right;
    margin-left: 8px;
    margin-top: 3px;
    padding: 8px;
  }
}

.ticketOverdue {
  background: #f16352e5;
  padding: 5px;
  color: #fff;
  border-radius: 4px;
  display: inline;
  margin-left: 3px;
}

//configuration
.ticketConfiguration {
  .notification-config {
    padding: 4px;
    border-radius: 6px;
    width: fit-content;
    color: #a5a8ab;
  }

  .labelstyle {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
  }
}

.ticket-create-header {
  z-index: 10;
  background: white;
  // padding: 2rem 0.5rem 0.5rem 0.5rem;
  // margin-bottom: 1rem;
  position: fixed;
  top: 30;
  left: 0;
  // width: max-content;
  // margin-top: 3.7rem;
  // margin-left: 16%;
}


.ticket-create-body {
  // overflow-y: auto;
  margin-top: 1rem;

  .create-body {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;

    :-webkit-scrollbar {
      display: none;
      /* For Chrome, Safari, and Edge */
    }
  }
}

.charCount {
  position: relative;
  right: 34px;
  top: 5px;
}

.cloneTicketId {
  border: 0.25px solid #cfc9c9;
  display: flex;
  align-items: center;
  padding: 6px;
  position: relative;
  left: 10px;
  bottom: 5px;
}

.field-attachment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  width: 100%;
  // overflow-x: scroll;

  .uploadedFiles {
    display: flex;

    .uploadedFile {
      display: flex;
      align-items: center;
      background: #e6e6e6;
      border-radius: 4px;

      .fileName {
        display: flex;
        font-size: smaller;
        font-weight: 400;
        margin-right: 6px;
        margin-left: 6px;
      }

      .mediaType {
        margin-right: 3px;
      }

      .remove {
        &:hover {
          background: #ffbdad;
          color: red;
          border-radius: 3px;
        }
      }
    }

  }

  .attachment-icon {
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem 0 0 0.25rem;
    cursor: pointer;
    border-left: 1px solid #e0e0e0;
  }
}

.toggle-attachment {
  padding: 0.5rem 0;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;

  .media-attachment-option {
    display: flex;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background: #deebff;
    }
  }
}