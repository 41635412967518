  .modalsidebar {
    position: absolute;
    left: 55%;
    top: 9%;
    height: 90%;
    z-index: 999;
  }

  .emojipopover {
  position: fixed;
  background-color: #fff;
  z-index: 9999;
  }

  input.form-group-sm {
    position: absolute;
    right: 195%;
    top: 5%;
    padding-bottom: 2%;
  }

  .select {
    width: 100%;
    height: 40%;
  }

  .variable {
    display: inline-block;
  }

  // .textarea {
  //   padding: 1.5%;
  //   width: 100%;
  //   // border-color: rgb(240, 243, 243);
  // }
  // .custom-css-b {
  //   position: absolute;
  //   top: 20px;
  //   left: 1px;
  // }
  // .custom-css-i {
  //   position: absolute;
  //   top: 20px;
  //   left: 40px;
  // }
  // .custom-css-s {
  //   position: absolute;
  //   top: 40px;
  //   left: 90px;
  // }

  .editor {
    position: relative;
  }
.quickcontainer{
.TQuick {
  position: absolute;
   top:10px;
    .react-bs-table-tool-bar .form-group {
      position: absolute;
      top: -111%;
      right: 3%;
      float: left;
    }
}
}
// .addbutton {
//   margin-bottom: 10%;
// }

  .signUpBtn1 {
    position: absolute;
    top: 11px;
    left: 95px;
    height: 30px;
    width:35px;
    color: black !important;    
    border-color: #999999;
    background-color: #f7f7f8 !important;
    padding: 0%;
    &:hover {
      color:black;
      border-color: #999999;
    }
  }

  .signUpBtn1:focus {
    box-shadow: none;  
    color:black;
    border-color: #999999;
  }
  .addvariable {
    position: absolute;
    top: 1px;
    left: 280px;
    height: 36px;
    width:85px;
    color:  #fff;
    background-color: #00B598;
    padding: 0%;
    &:hover {
      color: #fff;
    }
  }
  .addvariable:focus {
    box-shadow: none;  
    color: #fff;
  }
  .infoicon {
    position: absolute;
    top: 15px;
    left: 367px;
    height: 30px;
    width:15px;
    font-size: 15px;
    padding: 0%;
  }

  .dropDown {
    position: absolute;
    top: 6px;
    left: 162px;
    padding: 1%;
  }

  
  .fileattach {
    position: absolute;
    top: 11px;
    left: 127px;
    height: 30px;
    width:30px;
    border-radius: 1px;
    border-color: #999999;
    color: rgb(2, 2, 2);
    background-color: #f7f7f8 !important;
    padding: 0%;
    &:hover {
      color:black;
      border-color: #999999;
    }
  }

  .fileattach:focus {
    box-shadow: none;  
    color:black;
     border-color: #999999;
  }

  .paddingBtn {
    height:40px;
  }
  .fileattach input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }