.optionBox {
    background-color: #efefef;
    margin-bottom: 12px;
    padding: 5px;
    border-radius: var(--bs-border-radius);

    .questionRow {
        display: flex;
        align-items: center;

        .deleteButton {
            margin-right: 15px;
        }

        .matchText {
            width: 100%;
        }
    }
}