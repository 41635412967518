.fullScreenPopover {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    transition: all 300ms ease;
    animation: initialAnimation 300ms ease;

    &.enter,
    &.enterActive {
        transform: translateY(0);
    }

    &.exit,
    &.exitActive {
        transform: translateY(80vh);
    }

    .header {
        padding: 10px 12px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        z-index: 15500;
        // background-color: rgba($color: white, $alpha: 0.6);

        .caption {
            font-weight: 600;
            font-size: 1.2rem;
        }

        button {
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 1rem;
            cursor: pointer;
            border-radius: 50%;
            padding: 12px;
            min-width: 45px;
            margin-right: 12px;

            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.1);
            }
        }
    }
}

@keyframes initialAnimation {
    0% {
        transform: translateY(80vh);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}