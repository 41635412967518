.filePreview {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 6px;
    width: 300px;

    .thumbnail,
    .download {
        width: 20%;
    }

    .thumbnail {
        padding: 10px;
        position: relative;

        .extension {
            padding: 0 1px;
            pointer-events: none;
            font-size: 0.5rem;
            margin-left: 15px;
            background-color: #007EC3;
            color: #fff;
            display: block;
            position: absolute;
            bottom: 12px;
            left: 0;
            width: 20px;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }

    .data {
        width: 80%;

        span {
            display: block;
        }

        .caption {
            max-width: 140px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .fileSize {
            color: #a0a0a0;
        }
    }

    a.download {
        display: block;
        color: black;
        padding: 12px 20px;
    }
}