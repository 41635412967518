 .addTagPopup,
 .tagsPopup {
     padding: 10px;
 }

 .addTagPopup {
     width: 250px;
 }

 .convTags {
     //  position: relative;
     //  right: 12px;
     display: flex;
     align-items: center;

     .tagCount,
     .addBtn {
         font-weight: normal;
         text-decoration: none;
         cursor: pointer;
         color: #474747;
         //  margin-left: 1px;
         outline: none !important;
         border: 0 !important;
         background: transparent !important;
         font-size: 12px;
     }

     .addBtn {
         border: 1px solid #efefef !important;
         //  margin-top: 4px;
         //  margin-left: -13px;
     }

     .tagList {
         .tagItem {
             display: block;
             background-color: #efefef;
             border-radius: 12px;
             padding: 2px 10px;
             display: flex;
             align-items: center;
             justify-content: space-between;
             margin-bottom: 10px;

             .label,
             button {
                 display: block;
             }

             button {
                 border: 0 !important;
                 background: transparent !important;
                 outline: none !important;
                 font-size: 1.2em;
             }
         }
     }
 }

 .tagsPopup {
     max-height: 350px;
     overflow-y: auto;
 }