//-*---------------*-  MAIN CAMPAIGN CONTAINER -*---------------*-//
.textGrayAlert {
    color: #ABABAB;
    font-size: 12px;
}


.templateName {
    font-size: 16px;
    font-weight: 600;
    color: #007ec3;
}

.cancelBtn {
    background-color: #ffffff;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #EAEAEA;
}

.changeBtn {
    border: 1px solid #ff7342;
    background: #ff7342;
    color: #fff;
    padding: 2px 4px;
    border-radius: 5px;
    margin: 2px;
}

.warningText {
    padding: 10px 5px;
    background: #FFF5DC;
    color: #A88300;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    border-radius: 2px;
}

.createCampain {
    overflow-x: hidden;
    font-family: "Public Sans";
    color: #000;

    .campainHeader {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        padding: 20px 0;
    }

    .alertCon {
        margin: 0;
    }
}

.MainCampaignContainer {
    font-family: 'Public Sans';

    .limitMsg {
        padding: 10px 10px;
        background: #FFF5DC;
        color: #A88300;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        border-radius: 2px;

        svg {
            margin: 8px;
            color: #A88300;
            font-size: 16px;
        }
    }
    .amount_credits{
        background-color: #fed7d7;
        padding: 1em;
        margin-top: .8em;
        border-radius: 4px;
        display: flex;
        .css-anu9qi{
            width: 1em;
            height: 1em;
            display: inline-block;
            line-height: 1em;
            -webkit-flex-shrink: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            color: red;
        }
        // .reacharge-btn{
        //     // display: flex;
        //     // align-items: center;
        // }
    }

    input[type='radio'] {
        width: 15px;
        height: 15px;
        border: 1px solid #E2E2E2;
        border-radius: 100%;
        background-color: #FBFBFB;
    }

    input[type='radio']:before {
        content: '';
        display: block;
        width: 30%;
        height: 30%;
        border-radius: 100%;
    }

    input[type='radio']:checked:before {
        background-color: #FFFFFF;
        padding: 4px;
        border: 4px solid #0172CB;
        width: 15px;
        height: 15px;
    }

    .campaign {
        border-radius: 4px;
        border: 1px solid #E2E2E2;
        background: #FBFBFB;
        margin: 10px 0;
        padding: 15px 20px 15px 38px;

        .campaignConent {
            .campianNameInput {
                border-radius: 4px;
                border: 1px solid var(--neutral-10, #E2E2E2);
                background: var(--white-10, #FFF);
                width: 50%;
            }

            .campaingNav2 .radioContainer {
                padding: 15px 50px;

                row {
                    padding: 10px;
                }
            }

            .campaignTitle {
                display: flex;
                justify-content: space-between;

                h4 {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    color: #000;
                }

                .Optional {
                    font-size: 12px;
                    color: #ABABAB;;
                }

                .uncheck {
                    background-color: #D9D9D9;
                    border-radius: 100px;
                    padding: 4px 5px;
                }

                .check {
                    background-color: #21c237;
                    border-radius: 100px;
                    padding: 4px 5px;
                }
            }

            .defaultgroup {
                width: 200px;
                margin-left: 20px;
            }
        }
    }

    .radioContainer {
        align-items: center;
        list-style: none;
        padding: 5px 0;

        .selectPeriod {
            color: #000 !important;
            font-weight: 400;
        }

        .whatsappOpt {
            color: #828282;
            font-size: 12px;
            display: flex;
            margin-left: 1em;
            margin-bottom: .3em;

            .switch-button {
                margin-left: 1em;
            }
        }

        ul {
            display: flex;
            list-style: none;
            margin: 15px 0;

            li {
                padding-right: 35px;
            }
        }

        .botNav {
            padding: 5px 15px;
        }

        .radioOption {
            label {
                display: flex;
                align-items: center;
                font-weight: 400;
            }
        }

        .memberData {
            position: relative;
            bottom: 5px;
            width: 75%;
        }

        .date-time {
            margin: auto;
        }

        .redioField {
            // display: flex;

            #community-data {
                list-style: none;
                display: flex;
                align-items: center;
                margin-right: 5%;

                span {
                    margin: 0px 7px;
                }

                .checkExistCommunity {
                    border: 2px solid #007EC3 !important;
                    background: none !important;
                    border-radius: 0px;
                    color: black;
                    border: none;
                    border-radius: 5px;
                    box-shadow: none !important;
                }

                .selectContact {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    background: none;
                    border: none;
                    color: #000;
                    box-shadow: none;

                    svg {
                        font-size: 18px;
                    }
                }
            }
        }

        .error-select-contact {
            margin-left: 1em;
        }
    }

    .changeTemplate {
        color: #007EC3;
        cursor: pointer;
    }

    .previewTemplate {
        border: 2px dashed #BBBBBB;
        text-align: center;
        border-radius: 5px;
        align-items: center;
        padding: 80px 50px;
        background-color: #FBFBFB;

        span {
            margin-bottom: 10px;
        }

        button {
            background-color: #007EC3;
            // padding: 5px !important;
            font-size: 12px;
        }
    }

    .sendOpt {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        position: relative;
        margin: 5px 0;

        .radioSw {
            position: absolute;
            right: 17%;
        }

        p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            color: #ABABAB;
        }
    }

    .faPeople {
        color: #0172CB;
        margin-bottom: 10px;
    }

    .sendCampaign {
        display: flex;
        justify-content: flex-end;
        width: 75%;
        margin: 10px;
    }
}

// .new-community-container {
// .react-bs-table-container {
//     .react-bs-table-tool-bar {
//         .row {
//             display: block !important;
//             position: relative;
//             bottom: 10px;

//             .col-lg-8 {
//                 display: none;
//             }

//             .col-lg-4 {
//                 // margin: auto;
//             }

//             input {}
//         }
//     }
//         .col-lg-4{
//             // margin: auto;
//         }
//   }
// }
.new-community-container{
    .global-check{
        .react-bs-select-all {
        display:block;
        border: none;
        }
    }
}

.selected-contacts {
    span {
        color: #007EC3;
    }

    // position: relative;
    // top: 50px;
}

.global-top-table-button {
    float: right;
    position: relative;
    top: 4px;
}

.global-table-title {
    margin-top: 2%;

    h5 {
        margin: 0px;
    }
}

// .disabled-data{
//     opacity: 0.4 !important;
//       cursor: not-allowed !important;
//     //   margin-top: 5px;
//       width: 250px !important;
//       margin-right: 5px;
//     }

.newGroup {
    // margin-top: 5px;
    width: 250px !important;
    margin-right: 5px;
}

.new-community-buttons {
    display: flex;
    justify-content: end;
}

.contact-select-newcommunity{
           margin-right: 10px;
          line-height: 36px; 
}
// .contactDelBtn{
//     position: relative;
//     right: 10%;
//     width: 17%;
//     float: right;
//     cursor: pointer;
// }
.community-name {
    margin: 0px;
    line-height: 30px;
}

// }




// .createGroup {
//     display: flex;
//     justify-content: space-between;

//     .clrSel {
//         margin-top: 20px;
//     }

// }

// .underline {
//     border: 1px solid #DCDFE3;
//     margin: 5px 0px;

// }


.custom-search-bar-row .react-bs-table-search-form {
    /* Styles for the search bar row */
    /* Add your styles here */
    display: flex;
    flex-direction: column;
}

//-*---------------*- PREVIEW WHATSAPP -*---------------*-//
.replyActionBtnsShow {
    top: 78.5%;
    color: #007EC3;
    font-weight: 500;
    left: 15%;
    background: none;

    .msg-button {
        color: #007EC3;
        font-weight: 600;
        background-color: white;
        padding: 5px;
        font-size: 11px;
        margin: 5px;
        border: none;
    }
}

// .whatsappLayout {
//     overflow: auto;
//     /* Use 'auto' to show scroll bars only when needed */
//     height: 250px;
//     width: 150px;
//     padding-right: 20px;
//     left: 20%;
// }

@media screen and (min-width: 1600px) and (min-height: 900px) {
    // .whatsappLayout {
    //     overflow: auto;
    //     height: 250px;
    //     width: 150px;
    //     padding-right: 20px;
    //     left: 20%;
    //     position: relative;
    // }

    .replyActionBtnsShow {
        top: 78.5%;
        color: #007EC3;
        font-weight: 500;
        left: 15%;
        background: none;

        .msg-button {
            color: #007EC3;
            font-weight: 600;
            background-color: white;
            padding: 5px;
            font-size: 11px;
            margin: 5px;
            border: none;
        }
    }
}

// .preview-image {
//     background-image: url('../../assets/img/templatePreview.svg');
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 509px;
//     margin: -60px auto;
//     width: 125%;
//     position: relative;
//     /* left: 15%; */
//     z-index: -1;
// }

.preview-image {
    .channelName {
        position: relative;
        bottom: 3em;
        color: white;
        left: -3.5em;
        display: flex;
        justify-content: center;
    }

    // position: relative;
    width: 300px;
    height: 500px;
    padding-left: 20px;
    padding-top: 50px;
    // bottom: 60px;
    // left: 60px;
    // object-fit: cover;
    background-image: url('../../assets/img/previewPhone.png');
    background-repeat: no-repeat;
    background-size: contain;
}

//-*---------------*- SELECT COMMUNITY -*---------------*-//

.SelectCommunityContainer {
    font-family: 'Public Sans';

    .exist-community-container {
        .react-bs-table-container {
            .react-bs-table-tool-bar {
                .row {
                    display: block !important;
                    margin-bottom: 4%;

                    .col-lg-8 {
                        display: none;
                    }
                }
            }
        }

        .global-top-table-button {
            float: right;
            position: relative;
            top: 4px;
        }

        .selected-contacts {
            span {
                color: #007EC3;
            }

            // position: relative;
            // top: 70px;
        }
    }

    .global-table-title {
        margin-top: 2%;

        h5 {
            margin: 0px;
        }
    }

    // .community_row {
    //     .create_community,
    //     .multi_community {
    //         border: 2px solid #DCDFE3;
    //         padding: 3px 10px;
    //         color: #6F6F6F;
    //     }

    //     .muli_community {
    //         width: 50%;
    //         margin-right: 1em;
    //     }
    // }
}

.community-name {
    padding-left: 2.3em;
    padding-bottom: .5em;
    word-wrap: break-word;
}

//-*---------------*- UPLOAD CONTACT  -*---------------*-//

.UploadContactContainer {
    .upload-head {
        color: black;
        font-weight: 400;
        font-size: 1.5em;
        margin-bottom: 14px;
    }

    .down-content {
        font-size: 14px;
    }

    .country-mandatory,
    .xlsx {
        color: #8E8E8E;
        font-size: 14px;
    }

    .contact-excel-table {
        display: flex;
        justify-content: space-between;

        .community-name {
            width: 300px;
        }
    }

    .uploadExcel {
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border: 2px dashed #BBBBBB;
        // width: 75%;
        border-radius: 5px;
        padding: 2%;

        #file {
            width: 300px;
            margin: auto;
        }
    }

    p {
        font-size: 16px;
        // .whatsappBullet{
        //     margin-right: 10px;
        //     align-items: center;
        //     border-radius: 100%;
        //     // padding: 5px;
        //     color: #25d366;
        //     font-size: 25px;
        // }
    }

    .whatsappOpt,
    .drag {
        color: #828282;
        font-size: 14px;
    }

    .download-sample {
        background-color: '#007EC3' !important;
        width: 150px;
    }

    .sampleDowncon {
        font-size: 12px;
        margin: 5px 0;
        color: #ABABAB;

        a {
            color: #007EC3;
            text-decoration: underline;
        }
    }

    .upload-files {
        width: 25%;
        margin: auto;
        padding: 5px;
        color: white;
        background-color: #007EC3;
        border-radius: 5px;
        cursor: pointer;
    }

    .selected-file {
        background-color: white;
        width: 50%;
        padding: 10px;
        align-items: center;
        border-radius: 5px;
        // display: flex;
        // justify-content: space-between;
        border: 2px dashed #BBBBBB;

        .uploadIcon,
        .deleteIcon {
            text-align: center;
            background-color: #E7E7E7;
            padding: 5px;
            font-size: 1rem;
            border-radius: 5px;
            color: #0172CB;
            cursor: pointer;
        }
    }
}


.campaign-item-action {
    color: #505050 !important;
    outline: none;

    &::after {
        display: none !important;
    }
}

.campaign-item-repliedInfo {
    color: #909EBD;
    font-size: 1.2em;
    cursor: pointer;
    margin-left: 5px;
}

table.campaign-history-replied-stats {
    border: 0 !important;

    tr,
    th,
    tr td {
        border: 0 !important;
    }
}



//-*---------------*-  CAMPAIGN HISTORY CONTAINER -*---------------*-//
.CampaignHistoryContainer {
    .data {

        background-color: #F9F9F9;
    }

    .grid-container {
        grid-column-start: 1;
        display: grid;
        // grid-template-columns: 10% 10% 10% 10% 10% ;
        grid-template-columns: .5fr .5fr .5fr .5fr .5fr .5fr;
        padding: 20px;
        background-color: #F9F9F9;

    }

    .grid-item {
        text-align: center;
        padding: 10px;
        border-right: 1px solid #CDCDCD;
    }

    .grid-last {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}




//-*---------------*- CAMPAIGN LISIT -*---------------*-//
.campaignLsitContaner {
    margin: 20px 0;
    color: #000;

    h6 {
        font-size: 16px;
        font-weight: 600;
    }

    .rightSideContainer {
        display: flex;
        justify-content: end;
        align-items: center;

        .createBtn {
            border-radius: 4px;
            background: #FF7342;
            color: #fff;
            margin-left: 10px;
            cursor: pointer !important;

            &:disabled {
                cursor: not-allowed !important;
            }
        }
    }
}

.campaignStatusCon {
    background: #F9F9F9;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stats {
        display: flex;
        overflow:scroll;

        .status {
            margin: 15px 0px 15px 20px;
            // padding-right: 20px;
            padding-right: 50px;
            border-right: 1px solid #CDCDCD;
            text-align: center;

            p {
                display: flex;
                color: #888888;
                font-size: 14px;
                font-family: 400;
                margin-bottom: 0;
                svg{
                    margin-top: 5px;
                }
            }

            .statusCount {
                color: #636363;
                font-weight: 700;
                font-size: 24px;
            }
        }

        :last-child {
            border-right: none;
        }
    }

    .filter {
        display: block;

        .dateFilter,
        .calenderInput {
            width: 225px;
            margin-right: 10px;
        }

        .dateFilter {
            width: 224px;
            margin-bottom: 7px;
        }

        .calenderInput {
            cursor: pointer;

            i {
                color: #4A4A4A;
                margin-right: 10px;
            }
        }
    }

}

.badgeCon {
    display: inline-block;
    padding: 5px 12px;
    border-radius: 4px;
    color: #3B3E45;
    font-size: 14px;
    font-style: normal;
    text-align: center;

    &.pending {
        background: linear-gradient(0deg, rgba(250, 193, 42, 0.20) 0%, rgba(250, 193, 42, 0.20) 100%), #FFF;
    }

    &.completed {
        background: linear-gradient(0deg, rgba(110, 199, 124, 0.20) 0%, rgba(110, 199, 124, 0.20) 100%), #FFF;
    }

    &.failed {
        background: linear-gradient(0deg, rgba(217, 11, 11, 0.20) 0%, rgba(217, 11, 11, 0.20) 100%), #FFF;
    }
}

.btn.campaignActionButton {
    background-color: transparent !important;
    color: black !important;
}

.campaignTableHeader {
    color: #000;
    margin: 15px 0 15px 0;

    h6 {
        margin-right: 20px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
    }

    .rightSideCon {
        display: flex;
        justify-content: end;

        .filters {
            display: flex;

            .dateFilter,
            .calenderInput {
                width: 225px;
                margin-right: 10px;
            }

            .dateFilter {
                width: 175px;
            }

            .calenderInput {
                cursor: pointer;

                i {
                    color: #4A4A4A;
                    margin-right: 10px;
                }
            }
        }
    }

    .exportBtn {
        display: flex;
        border-radius: 4px;
        border: 0.25px solid #E7E7E7 !important;
        background: #E5F6FF !important;
        color: black !important;
        padding: 8px 17px;
        font-size: 12px;
        align-items: center;

        i {
            margin-right: 5px;
            color: #666E7D !important;
        }
    }

    .filter {
        border-radius: 4px;
        border: 1px solid #DCDFE3;
        background: #FFF;
        margin-left: 10px;
        padding: 5px 10px;
        outline: none;

        &:hover,
        &:focus {
            outline: none;
            border-color: #6A24DC;
        }

        &::selection {
            background: transparent !important;
        }

        &::after {
            display: none !important;
        }

        .searchBox {
            border: none;

            &:focus {
                border: none;
            }
        }

        .custom-dropdown .btn::after {
            display: none;
            /* Hide the dropdown arrow */
        }

        .custom-dropdown .btn:focus {
            box-shadow: none;
            /* Hide the border on focus (click) */
            border: none;
            /* Hide the border on focus (click) */
        }

        .dropdown-item {
            border-bottom: 1px solid #E8EDF1;
        }
    }
}

.campaignTable {
    position: relative;

    .react-bs-table-search-form {
        position: absolute;
        left: 8vw;
        top: -53px;
    }

    .react-bs-table-pagination {
        margin-bottom: 120px;
        position: relative;

        .row {
            margin-top: 15px;
            position: absolute;
            width: 100%;
            top: -120px;
        }
    }

}

.campaignAlert {
    background-color: #FEFCBF;
    color: #A88300;
    border-color: #F1AA21;
    border-width: 1px;
    background-color: #fff5dc;
    padding: 5px 10px;

    svg {
        margin-right: 5px;
    }
}

.DateRangePicker,
.popover {
    font-family: "Public Sans" !important;
}

.bi-person-circle-count {
    position: absolute;
    // right: -9px;
    // width: 19px;
    // height: 18px;
    cursor: pointer;
    right: -15px;
    width: 17px;
    height: 17px;
    bottom: 14px;
    border-radius: 50%;
    background: var(--banner-red, #0bc2a3);
    color: white;
    font-size: 8.5px;
    text-align: center;
    line-height: 18px;

}

.disableRepeat {
    border: none;
}
.layoutFortemp{
position: relative;
bottom: 15px;
background-color: rgb(253, 252, 250);
// position: absolute;
// margin-top: 15%;
width: 85%;
padding: 10px;
overflow: hidden;
word-wrap: break-word;
// top: 25.5%;
// left: 12.5%;
box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
max-height: 290px;
overflow-y: auto;
border-radius: 10px;
}

.replyActionBtnsfortemp{
    position: relative;
bottom: 15px;
background-color: rgb(253, 252, 250);
  // width: 85%;
  // top: 76.5%;
  color: #007ec3;
  word-wrap: break-word;
  font-weight: 500;
  // left: 12.5%;
  padding: 1% 3%;
  box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);
  border-radius: 10px;
}