@mixin lowResolution {
    @media (max-width: 1366px) {
        @content;
    }
}

@mixin highResolution {
    @media (min-width: 1900px) {
        @content;
    }
}

@mixin smallWidth {
    @media (max-width:600px) {
        @content;
    }
}
