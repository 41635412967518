h6.headerOne {
    color: #000;
    font-size: 16px;
    font-style: normal;
    padding: 0px;
    margin-top: 7px;
}

.borderLine {
    background: linear-gradient(90deg, #007EC3 0%, rgba(0, 126, 195, 0.00) 100%);
    width: 149px;
    height: 3px;
    flex-shrink: 0;
}

.welcomeContainer {
    padding: 20px 40px;
    font-family: 'Public Sans';
    color: #000;

    // background-color: rgba(234, 243, 241, .278);
    .lined-text {
        text-align: center;
        position: relative;
        font-size: 11px;
        right: 10px;
        padding-top: 35px;
        color: #DAD8D8;
    }

    .before {
        content: "";
        position: absolute;
        width: 1px;
        height: 40px;
        background-color: #E9E4E4;
    }

    .demoContainer {
        display: flex;
        padding: 15px;
        // background: rgba(110, 199, 124, 0.10);
        background: white;
        margin: 15px 0px;
        border-radius: 4px;
        box-shadow: 0 6px 6px rgba(160, 20, 20, 0.1);

        .demoContainerCol {
            width: 33.333% !important;
            padding: 0px 10px;

            p {
                color: #AEAEAE;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
            }
        }

        // .demoContainerCol:nth-child(2),
        // .demoContainerCol:nth-child(3) {
        //     padding: 0px 25px !important;
        // }
        .demoContainerCol:last-child {
            border-right: none;
        }

        .title {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;

            img {
                margin-left: 10px;
            }
        }

        .whatsappBtn {
            padding: 5.497px 16.43px;
            justify-content: center;
            align-items: center;
            border-radius: 1.444px;
            background: #4ACA59;
            border: 1px solid #4ACA59;
            color: #FFFFFF;
        }

        .QrBtn {
            padding: 5.497px 18.43px;
            justify-content: center;
            align-items: center;
            border-radius: 1.444px;
            background: #1D10DF;
            border: 1px solid #1D10DF;
            color: #fff
        }

        .qrImg {
            margin: 9px 1px 1px 1px;
            position: relative;
            bottom: 7%;
        }

        .Qrtxt {
            margin-top: 0px;
            font-size: 9px !important;
            text-align: center;
        }
    }

    .expRealContainer {
        .headTitle p {
            padding: 0px;
            margin: 10px 0px;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
        }

        .content {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px;
        }

        .connectBtn {
            // padding: 9px 0px;
            // justify-content: center;
            // align-items: center;
            // border-radius: 2px;
            // background: #FF7342;
            // border: 1px solid #FF7342;
            // color: #fff;
            margin-left: 70%;
            padding-top: 10px;
            .channelConnectBtn{
                width: 26%;
            }
        }

        // .connectBtn:hover {
        //     padding: 9px 38px;
        //     justify-content: center;
        //     align-items: center;
        //     border-radius: 2px;
        //     background: #4265ff;
        //     border: 1px solid #4265ff;
        //     color: #fff;
        // }
        
        // .connectBtn:active {
        //     padding: 9px 38px;
        //     justify-content: center;
        //     align-items: center;
        //     border-radius: 2px;
        //     background: #4265ff;
        //     border: 1px solid #4265ff;
        //     color: #fff;
        // }
    }
}

.dashboardContainer {
    background: #F6F6F6;
    padding: 35px 20px;
    margin: -15px;

    .userContainer {
        border-radius: 15px;
        background: var(--white, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        // width: 510px;
        height: 190px;
        display: flex;

        img {
            width: 100%;
            height: 100%;
        }

        .userDash {
            width: 70%;
            // text-align: center;
            padding: 20px;

            button {
                width: 140px;
                height: 35px;
                padding: 5px 25.43px 4.497px 25.43px;
                color: #fff;
                justify-content: center;
                border-radius: 5px;
                border: 1px solid #FF7342;
                background: #FF7342;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
                margin: 25px 0px;
                font-size: 12px;
            }

            p {
                color: #999;
                font-size: 10px;
            }
        }

        .imageContainer {
            width: 30%;
        }
    }

    .quickDashboard {
        padding: 4px;
        margin-bottom: 0%;
        border-radius: 15px;
        background: var(--white, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        height: 122px;

        .firstflex {
            height: 70px;
        }

        .qrcode {
            margin-left: 25%;
        }

        .whatsapp {
            color: #4ACA59;
        }

        .balanceContainer {
            padding: 7px 2px 0px 2px;
            margin: 9px -4px;
            background: #FFFFFF;
            border-radius: 15px;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);

            .plusbtn {
                display: flex;
                align-items: center;
                cursor: pointer;
                background-color: #fc934d;
                color: white;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 3%;
                margin-top: 1.5%;
                padding: 1% 2%;
            }
        }

        .actionBtnContainer {
            margin-left: 25%;
        }

        .w60 {
            width: 60%;
        }

        .w40 {
            width: 40%;
        }

        .actionBtn {
            // border-radius: 4px;
            // border: 1px solid #EDEDED;
            // background: var(--white, #FFF);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .addBtnCon {
        margin-top: 18px;
        justify-content: space-between;

        .addBtnCard {
            width: 200px;
            height: 160px;
            padding: 35px 3.492px 28px 3.492px;
            border-radius: 23px;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
            text-align: center;

            button {
                width: 115px;
                padding: 9px 10px;
                border-radius: 2px;
                background: #FF7342;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
                font-size: 12px;
                border: 1px solid #ff7342;

                a {
                    color: #fff;
                }
            }
        }

        :last-child {
            margin-right: 0px;
        }
    }

    .addFeatureBtnCon {
        justify-content: space-between;
        margin-top: 18px;

        .addFeatureBtnCard {
            width: 295px;
            height: 100px;
            border-radius: 16px;
            border: 1px solid #F4F4F4;
            background: #FFF;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
            text-align: center;
            padding: 15px;

            p {
                margin-bottom: 0.5rem;
            }

            svg {
                background: #ff7342;
                width: 30px;
                height: 29px;
                padding: 9px;
                border-radius: 100%;
            }
        }
    }
}

.textarea {
    width: 100%;
    border: 1px solid #e9e1de;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.textarea:hover {
    border: 1px solid #e9e1de;
    border-radius: 4px;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.textarea:focus {
    border: 1px solid #e9e1de;
    border-radius: 4px;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.link {
    margin-left: 25%;
}

.inline {
    display: inline-block;
}

.bordericon {
    border: 1px solid rgb(243, 242, 242);
    background-color: white;
    width: 17%;
    height: 50%;
    margin-left: 3%;
    align-items: center;
}

.background {
    background-color: #F6F6F6;
}

.formwidth {
    padding: 0% 4%;
}

.wallet {
    color: #60d669;
    padding-right: 4%;
}

.balance {
    margin-right: 2%;
}

.popup {
    position: absolute;
    top: 52%;
    left: 77%;
    height: 10%;
    padding: 0px 10px;
    font-size: 12px;
    background-color: #2d2e2d;
    color: #F4F4F4;
}

// .connectBtn {
//     padding: 9px 38px;
//     justify-content: center;
//     align-items: center;
//     border-radius: 2px;
//     background: #FF7342;
//     border: 1px solid #FF7342;
//     color: #fff;
// }

// .connectBtn:hover {
//     padding: 9px 38px;
//     justify-content: center;
//     align-items: center;
//     border-radius: 2px;
//     background: #4265ff;
//     border: 1px solid #4265ff;
//     color: #fff;
// }

// .connectBtn:active {
//     padding: 9px 38px;
//     justify-content: center;
//     align-items: center;
//     border-radius: 2px;
//     background: #4265ff;
//     border: 1px solid #4265ff;
//     color: #fff;
// }

.connectMargin {
    margin-left: 30%;
}

.linkerror {
    padding-left:50%;
}
.charCounts {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    font-size: .8em;
    .win{
        background-color: #edf2f7;
        border-radius: 0.8em;
        padding-inline-end: 0.4em;
        padding-inline-start: 0.4em;
        line-height: 14px;
        font-weight: 600;
    }
  }