.mentionNotes {
    margin-left: 10px;
    font-size: 17px;
}

.toolbarColor {
    color: #9195a0;
}

.mention-list {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 150px;
    overflow-y: auto;
}

.mention-item {
    padding: 8px 12px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    margin-right: 10px;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: #f5f5f5;
        border-radius: 4px;
    }
}

.mention-name {
    width: 120px;
}

.mention-content {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
}