.filePlaceholder,
.filePlaceholder * {
    overflow: hidden !important;
}

.filePlaceholder {
    width: 100px;
    height: 100px;
    background-color: white !important;
    border: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
}

.filePlaceholder.large {
    width: 200px;
    height: 200px;
}

.filePlaceholder.small {
    width: 50px;
    height: 50px;
}

.filePlaceholder.medium {
    width: 125px;
    height: 125px;
}

.mediaContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    background-color: #f7f7f7;
    padding: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius);
}

.mediaPreview {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

img.mediaPreview {
    width: 100% !important;
}

.mediaIcon {
    font-size: .5rem;
    color: black;
    z-index: 1;
    margin-right: 5px;
}

.filePlaceholder.large .mediaIcon {
    font-size: 1em;
}

.filePlaceholder.small .mediaIcon {
    font-size: 1.5em;
}

.document-icon {
    font-size: 2rem;
}

.mediaFooter {
    background-color: white !important;
    text-overflow: ellipsis;
    font-size: 8px;
    white-space: nowrap;
    overflow: hidden;
}

.filePlaceholder.large .mediaFooter {
    font-size: 1em;
}

.nameflex {
    display: flex;
    align-items: center;
}

.nameflex .mediaIcon {
    width: 30px;
}

.nameflex .filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}