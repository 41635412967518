.grid {
    display: flex;
    margin-bottom: 10px;
    overflow-x: auto;
    overflow-y: hidden;
}

.grid .gridItem {
    cursor: pointer;
    margin-right: 12px;
}

.iconCard {
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    background-color: white;
    color: rgba(93, 140, 166, 1);
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F0F7F7;
    cursor: pointer;
}

.iconCard .icon {
    font-size: 1.2rem;
    padding: 5px;
}

.title {
    margin-top: 3px;
    color: rgba(93, 140, 166, 1);
    font-size: .6rem;
    text-align: center;
    width: 60px;
}

.gridItem.active .iconCard {
    border-color: #86b5ac;
    color:  #458d80;
}

.gridItem.active .title {
    color:  #458d80;
}