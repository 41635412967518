.caption {
    font-size: 1.2rem;
    margin-top: 16px;
}

.analyticsPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    margin-top: 16px;
    padding: 14px 12px;

    .numbers {
        display: flex;

        .numberItem {
            text-align: center;
            min-width: 110px;
            border-right: 1px solid #dbdada;

            &:last-child {
                border-right: none;
            }

            .numberCount {
                font-weight: bold;
                font-size: 1.2rem;
            }

            .numberLabel {
                font-size: 0.6rem;
                color: #7a7a7a;
                display: flex;
                justify-content: center;
            }
        }
    }

    .daterange {
        display: flex;

        .dateFilter,
        .calenderInput {
            width: 225px;
            margin-right: 10px;
        }

        .dateFilter {
            width: 175px;
            max-height: 120px; /* Adjust this value based on your needs */
            overflow-y: auto; /* Enables vertical scrolling */
        }

        .calenderInput {
            cursor: pointer;
            width: 75%;

            i {
                color: #4A4A4A;
                margin-right: 10px;
            }
        }
    }
}

.select {
    width: 150px;
    margin-right: 10px;
}