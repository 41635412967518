.recording-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .record-button {
    background-color: transparent;
    border: 1px solid #5d8ca6;
    color: #5d8ca6;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px;
  }
  
  .stop-button {
    background-color: rgb(255, 101, 101);
    border: none;
    color: white;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
   
  /* Container styling for timer, waves, and stop button */
.recording-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 2px solid grey;
  border-radius: 10px;
  margin-right: 25%;
  margin-left: 25%;
  align-items: center;
  margin-top: 30px;
}

/* Timer styling */
.timerTick {
  font-size: 18px;
  color: grey;
  width: 25%;
}

/* Stop button styling */
.stop-button {
  background-color: rgb(255, 101, 101);
  border: none;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

/* Recording indicator styling */
.recording-indicator {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Wave animation styling */
.wave {
  width: 3.5px;
  height: 20px;
  background: grey;
  border-radius: 4px;
  animation: waveAnimation 1.2s infinite ease-in-out;
}

.wave:nth-child(2) {
  animation-delay: -1.1s;
}

.wave:nth-child(3) {
  animation-delay: -1s;
}

.wave:nth-child(4) {
  animation-delay: -0.9s;
}

.wave:nth-child(5) {
  animation-delay: -0.8s;
}

.wave:nth-child(6) {
  animation-delay: -0.7s;
}

@keyframes waveAnimation {
  0%, 100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
}
