.tagsContainer {
    display: flex;
    flex-wrap: wrap !important;
    .contactTag {
        background-color: #fff !important;

        button {
            background-color: #fff !important;
        }
    }
}

.background {
    background-color: #efefef;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.radio {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.productLabel {
    margin-left: -17px;
    margin-bottom: 4px;
    margin-right: 60px;
}