.name {
  width: 80% !important;
}

.expiryTime {
  width: 80% !important;
}

.tabContent {
  height: auto;
}

.offCanvas {
  min-height: 400px;
  max-height: 1000px;
  overflow-x: hidden;
}

.trashIcon {
  margin-bottom: 2rem;
  margin-right: 20px;
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.configId {
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
}

.infoIcon {
  margin-left: 10px;
  cursor: pointer;
}

.configDiv {
  display: flex;
  align-items: center;
}

.copyIcon {
  margin-left: 10px;
  cursor: pointer;
  color: black;
  font-size: 17px;
}

.isDisabled {
  cursor: not-allowed !important;
  opacity: 0.5;
  pointer-events: none;
}
