.footer {
    display: block;
    color: #898989;
    font-size: 7px;
}

.header {
    font-weight: bold;
    font-size: 10px;
}

.item {
    padding: 0 5px;
    margin: 0;
}