.resendBtn {
    color: rgb(87, 165, 61) !important;
    background-color: white;
    border-radius: 4px;
    border-color: rgb(205, 231, 196);
    padding: 1px 4px;
    font-size: 10px;
    box-shadow: 3px 2px rgb(143, 209, 121);

    &:hover {
        color: white !important;
        background-color: rgb(163, 212, 146);
        border-color: rgb(205, 231, 196);
        border-radius: 4px;
        padding: 1px 4px;
        font-size: 10px;
        box-shadow: none;
    }

    &:focus {
        color: white !important;
        background-color: rgb(163, 212, 146);
        border-color: rgb(205, 231, 196);
        border-radius: 4px;
        padding: 1px 4px;
        font-size: 10px;
        box-shadow: none;
    }

    &:active {
        color: rgb(87, 165, 61) !important;
        background-color: white !important;
        border-radius: 4px;
        border-color: rgb(205, 231, 196) !important;
        padding: 1px 4px;
        font-size: 10px;
        box-shadow: 3px 2px rgb(143, 209, 121) !important;
    }
}

.lastLoginTime {
    padding-left: 5px;
    color: gray;
    font-size: 10.5px;
}

.inviteloader {
    width: 20px;
    height: 20px;
    border: 4px dotted #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
}

 .nav-tabs .nav-link.active {
    color: #049fdb;
    padding: 0px;
    border: 1px solid #c8ced3;
    border-bottom: none;
  }

  .addBUttons {
    background-color: rgb(213, 245, 213);
    border-radius: 4px;
    font-size: 10px;
    border:1px solid rgb(163, 243, 163);
  }

  .addedBUttons {
    background-color: rgb(245, 230, 213);
    border-radius: 4px;
    font-size: 10px;
    border:1px solid rgb(248, 196, 128);
  }

  .margincount {
    margin-left: 58%;
  }

  .numberCounts {
    position: relative;
    left: 93%;
  }

  .restoreIcon {
    color: #6d7483;
    margin-left: 25px;
    cursor: pointer;
  }

  .disabled-svg {
    opacity: 0.5; 
    pointer-events: none; 
  }

  .status {
    padding: 5px;
    border-radius: 7px;
  }
  .toggle-switch {
    display: flex;
    justify-content: space-between; /* Space between label and toggle */
    align-items: center; /* Align items vertically */
    width: 100%; /* Full width to align properly */
  }
  
  .toggle-label {
    font-size: 14px;
    color: #333;
    line-height: 20px; 
    margin: 0;
    flex: 1; /* Allow label to take available space */
    padding-right: 20px; /* Add some padding on the right for spacing */
    text-align: left; /* Align text to the left */
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 40px; 
    height: 19.5px; 
    margin-left: 10px; /* Margin on the left for separation */
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;  
    cursor: pointer;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 13px;  
    width: 13px;
    left: 3px;      
    top: 3px;      
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #007ec3;
  }
  
  input:checked + .slider:before {
    transform: translateX(20px);
  }
  
  .slider.round {
    border-radius: 20px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  #customLink {
    color: #4a91d4;
    font-weight: 500;
    margin-left: 5px;
    cursor: pointer;
  }

  .isDisabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    pointer-events: none !important;
  }

.editorFooter {
  padding: 12px;
  overflow: hidden !important;
  position: fixed;
  display: flex;
  justify-content: right;
  background-color: #efefef;
  bottom: 0px !important;
  right: 0;
  width: 498px;
  z-index: 1200;
  align-items: center;
}


.formContainer {
  padding-bottom: 80px; 
}


  
.btn-connected:focus,
.btn-connected:active
{
  box-shadow: none !important;
  background-color: #458D80 !important;
  border: #458D80;
  color: white;
}

.btn-connected:hover{
  background-color: #458D80 !important;
}
.btn-connected:hover .icon-desc {
  color: #fff !important;
}

.selected-card {
  border: 1px solid #458D80 !important;
  background-color: #f1fdf9 !important;
}

.unselected-card {
  border: 1px solid grey !important;
}