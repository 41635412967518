.refreshIconHistory {
    background-color: white;
    border: 1px solid #e2e2e2;
    padding: .1em .1rem;
    // margin-left: 32%;
    // margin-top: 2%;
    padding: 6px 6px;

    &:focus,
    &:hover {
        border: 1px solid #e2e2e2;
        box-shadow: none !important;
        background: white;
    }

    &:active {
        background: white !important;
        border: 1px solid #e2e2e2 !important;
    }
}

.whatsTable {

    // position: absolute;
    .react-bs-table-tool-bar .form-group {
        // position: relative;
        // left: -311%;
        // top: -70%;
        // width: 70%;
        position: absolute;
        top: 40%;
        right: 3%;
        float: left;
    }
}

.rotate {
    animation: rotation 1s linear;
}

.linkBtn {
    padding: 8px 8px;
    border-radius: 5px;
    margin-left: 33%;
}

.creatediv {
    // margin-left: 35%;
    // width: auto;
    margin-top: -4%;
}

.draft {
    background-color: #fefcbf;
    color: #dca51f;
    font-weight: 400;
}

.publish {
    background-color: #C6F6D5;
    color: #4c8e6a;
    font-weight: 400;
}


.delete {
    background-color: #ffcbb6;
    color: #d64005;
    font-weight: 400;
}

.disableFlow {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}