.uploadFiles {
    text-align: right;
    display: block;
    margin-top: 2%;
}

.uploadFiles span {
    background: #007EC3;
    border-radius: 8px;
    padding: 5px 20px;
    cursor: pointer;
    color: #fff;
}

// .uploadFiles.disable {
//     background: #f8bd8d;
// }

.uploadFilesdisable {
    text-align: right;
    display: block;
    margin-top: 2%;
}

.uploadFilesdisable span {
    background: #b2c7d3;
    border-radius: 8px;
    padding: 7px 20px;
    cursor: pointer;
    color: #fff;
}
