.optionBox {
    background-color: #efefef;
    margin-bottom: 12px;
    padding: 12px 5px;
    border-radius: var(--bs-border-radius);
}


.idCol {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 5px;
}

.questionRow {
    align-items: center;
}

.description {
    position: absolute !important;
    bottom: -20px;
    font-size: 0.8rem;
    font-weight: 400;
    color: #A3A3A3;
    padding: 1px 3px;
    right: 0;
  }

  .fieldWrapper {
    position: relative;
    margin-bottom: 20px;
  }
  

  .textLength {
    position: absolute;
    right: 0;
    bottom: -20;
    font-size: .8rem;
    font-weight: 400;
    color: #A3A3A3;
    padding: 1px 3px;
  }