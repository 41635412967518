@import "../../botFlows/styles/mixins";

.caption {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @include lowResolution {
    font-size: 14px;
  }
}

.tableAction {
  display: flex;
  align-items: center;
  width: 33%;

  .downloadBtn {
    background: white !important;
    border: 1px solid #efefef !important;
    padding-left: 18px;
    padding-right: 18px;
    margin-right: 6px;
  }

  .searchArea {
    // max-width: 300px;
    width: 70%;
  }
}

.CTWA {
  padding: 12px 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttons {
      display: flex;
      align-items: center;
      justify-content: right;

      &>* {
        margin-left: 10px;
      }

      :global(.btn) {
        padding-left: 28px;
        padding-right: 28px;

        @include lowResolution {
          padding-left: 18px;
          padding-right: 18px;
        }
      }

      .calenderInput {
        width: 250px;
        margin-right: 10px;
        cursor: pointer;

        i {
          color: #4A4A4A;
          margin-right: 10px;
        }
      }
    }
  }

  .card {
    border-radius: 16px;
    border: 1px solid #ECECEC;
    background: var(--white, #5c2d2d);
    box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.05);
    align-items: center;
    padding-right: 3%;
  }

  .stats {
    margin-top: 16px;

    .statsCard {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 12px;
      background-color: white;
      border-radius: 12px;

      img {
        width: 50px;
        height: 50px;

        @include lowResolution {
          width: 35px;
          height: 35px;
        }
      }

      div {
        margin-left: 16px;
        text-align: center;

        .count {
          display: block;
          color: #5d8ca6;
          font-size: 35px;
          font-weight: 600;

          @include lowResolution {
            font-size: 26px;
          }
        }

        .label {
          color: #959595;
          font-size: 16px;
          font-weight: 400;
          white-space: nowrap;
         text-overflow: ellipsis;

          @include lowResolution {
            font-size: 14px;
          }
        }
      }
    }
  }

  .graphSection {
    padding: 12px;
    margin-right: 12px;

    :global(.btn-outline-dark) {
      border-color: #737373 !important;
      color: #737373 !important;

      &:hover {
        background-color: transparent !important;
      }
    }

    .filters {
      padding: 0 24px;
      display: flex;
      justify-content: space-between;

      .selectedDuration {
        button {
          margin-right: 5px;
        }
      }
    }

    .graph {
      margin-top: 12px;
    }
  }

  .datatable {
    position: relative;

    :global(.table-bordered) thead th,
    :global(.table-bordered) thead td {
      background-color: transparent !important;
      color: #666E7D;
    }

    :global(.react-bs-table-search-form) {
      position: absolute;
      top: -40px;
      right: 0;
      left: -0px;
    }
  }
}