@mixin flowCard {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  }
}

.flowsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-top: 1rem;

  .flowCard {
    @include flowCard;


    .flowCardTitle {
      display: block;
      margin: 0;
      color: #000;
      text-decoration: none;
      font-size: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .dropdownToggle {
      color: #333;
      outline: none !important;
    }
  }
}

.createFlowCard {
  @include flowCard;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;


  &:hover {
    background-color: #f9f9f9 !important;
  }

  button {
    border: none;
    background: none;
    color: var(--bs-primary);
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.templatesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-top: 1rem;

  .templateCard {
    @include flowCard;
    cursor: pointer;
    text-align: center;

    .templateCardImage {
      width: 100%;
      height: 80px;
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem;
    }

    .templateCardTitle {
      display: block;
      margin: 0;
      color: #000;
      text-decoration: none;
      font-size: 1rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
