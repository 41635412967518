$theme-background: rgb(236, 248, 240);
$theme-color: #00b598;
$warning-solid: #a88300;
$warning-background: #fff5dc;
$warning-red: #e53e3e;
$light-text-color: #718096;
$border-line: #e0e0e0;



#insight-table-status{
  width: max-content;
  text-align: center;
  padding: 0.3rem 0.6rem;
  border-radius: 0.5rem;
}

.table-st-completed{
  background-color: #C6F6D5;
    color:#4c8e6a;
}

.table-st-pending{
  background-color: #fefcbf;
    color: #dca51f;
}

.table-st-failed{
  background-color: #fed7d7;
  color:#c53030;
}

.cs-dropdown-item{
  &:hover{
    background: rgb(236, 248, 240);
    color: #00b598;
  }
}



.importContacts-new {
  display: flex;
  justify-content: center;

  .form-check-input {
    width: 3em;
    height: 1.5em;
    background-color: #ddd;
    border-radius: 1.5em;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  .form-check-input:checked {
    background-color: #4caf50;
  }
  
  .form-check-input::before {
    content: "";
    position: absolute;
    top: 0.25em;
    left: 0.2em;
    width: 1.1em;
    height: 1.1em;
    // background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .form-check-input:checked::before {
    transform: translateX(1.5em);
  }

  .importContacts-new-container {
    // width: 45vw;

    .prg-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;

      .prg {
        display: flex;
        align-items: center;

        .prg-status-completed{
          margin-right: 6px;
        }

        .prg-status {
          border-radius: 2rem;
          padding: 0.2rem 0.67rem;
          border: 2px solid $theme-color;
          color: $theme-color;
          background: $theme-background;
          margin-right: 8px;
        }

        .prg-status-inactive {
          border-radius: 2rem;
          padding: 0.2rem 0.6rem;
          border: 1px solid $border-line;
          color: #2d3748;
          // background: $theme-background;
          margin-right: 8px;
        }

        .prg-label {
          color: #2d3748;
          font-size: small;
          margin-right: 8px;
          font-weight: 500;
        }

        .line {
          border-bottom: 1px solid $theme-background;
          width: 3rem;
          margin-right: 8px;
        }
        
        .line-completed {
          border-bottom: 2px solid $theme-color;
          // background-color: #38a169; /* Green color for completed steps */
        }
      }
    }

    .download-sample {
      .importContacts-new-downloadSample {
        margin-top: 1rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
        
        .sampleExample {
          border: 1px solid $border-line;
          padding: 1rem;
          border-radius: 6px;

          .downloadSample {
            font-weight: 500;
            margin-right: 4px;
          }

          .label {
            color: $theme-color;
            font-weight: 500;
          }

          .content {
            color: #718096;
            margin-bottom: 0.5rem;
          }

          .sample-btn {
            border: 1px solid $border-line;
            font-weight: bold;
            padding: 6px 12px;
            border-radius: 6px;
            cursor: pointer;
          }
        }
        .lineContainer{
          display: flex;
          align-items: center;
          justify-content: center;
          .or-line{
            width: 100%;
            border: .8px solid $border-line;
            color: #718096;
          }
        }
        .separation-line {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0.5rem 0;
          color: #718096;
        }
      }

      .download-sample-submit {
        display: flex;
        justify-content: end;
        margin-top: 1rem;
      }
    }

    .upload-file {
      margin-top: 1rem;

      .avoid-common-errors {
        background: $warning-background;
        padding: 1rem;
        border-radius: 8px;
        border: 1px solid $border-line;

        .avoid-common-header {
          margin-bottom: 4px;
          font-size: 14px;
        }

        .error-points {
          color: $light-text-color;
          display: flex;
          margin-left: 1rem;
        }
      }

      .upload-import-file {
        border: 2px dashed $border-line;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        padding: 1rem;
        background: $theme-background;
        margin-top: 1rem;

        .display {
          // width: 60%;
          display: flex;
          justify-content: center;

          .drop-files-header {
            margin-bottom: 0.3rem;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
          }

          .file-upload-box {
            display: flex;
            justify-content: center;

            .upload-file-stage-one {
              border: 1px solid $border-line;
              padding: 0.5rem;
              border-radius: 6px;
              width: 22rem;
              display: flex;
              justify-content: space-between;
              margin-bottom: 0.5rem;
              background: white;

              .upload-button {
                background: $theme-background;
                color: $theme-color;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 8px;
                border-radius: 4px;
                cursor: pointer;
              }
            }
          }

          .file-example {
            small {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    .upload-file-form {
      margin: 1rem 0;

      .importType-info {
        background: $theme-background;
        padding: 1rem 1rem 1rem 0.25rem;
        border-radius: 6px;
        border: 1px solid $border-line;
        color: $light-text-color;
        .info-text {
          display: flex;
          font-weight: 500;

          .information {
            font-weight: 500;
          }
        }
        .info-text-two {
          font-size: smaller;
        }
      }

      .add-contact-tags {
        color: $theme-color;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
      }
      .addContactTags {
        border: 1px solid $border-line;
        background: $theme-background;
        padding: 0.6rem;
        border-radius: 6px;

        .optional-label {
          display: flex;
          align-items: center;

          .opt-label {
            background: $theme-background;
            padding: 3px 6px;
            border: 1px solid $border-line;
            font-size: smaller;
            font-weight: 600;
            border-radius: 3px;
            margin-left: 6px;
            margin-right: 6px;
          }
        }
      }
    }


  }
}

.map-fields-container {
  .map-fields {
    margin-top: 1rem;

    .map-fields-body {
      table {
        border: 1px solid $border-line;
        border-radius: 12px !important;
        .column-third-header {
          color: transparent;
        }
        
        .column-first-header,
        .column-second-header,
        .column-third-header {
          padding: 6px 2rem;
          background: $theme-background !important;
          font-size: small !important;
          font-weight: 500 !important;
          text-align: left !important;
        }

        .column-second-body, .column-third-body{
          padding: 1rem 2rem;
        }

        .column-first-body{
          font-size: small;
          text-align: left;
          padding: 1rem 2rem;
          .value{
            color: $light-text-color;
            font-size: smaller;
          }
        }

        .body-column{
          height: 60vh;
          overflow-y: scroll;
          width: 100%;
        }
      }
    }

    .table-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
      
      .reset-table{
        color: #3182ce;
        margin-left: 0.5rem;
        cursor: pointer;
      }

      .footer-left{
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .footer-right{
        display: flex;
        align-items: center;
      }
    }
  }
}

.spinner-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#rotate{
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #bcbcbd;
  margin-right: 0.5rem;
  cursor: pointer;
}

.rotate {
  display: inline-block;
  animation: spin 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}