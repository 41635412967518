:global(.PhoneInputInput) {
    background-color: transparent !important;
}


.inputGroup {
    width: 100px;
    padding: 8.5px 12px;
    height: 40px;
}

.addVariable {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: relative;
    text-align: center;
}

.addVariable button {
    display: flex;
    height: 60%;
    color: white;
    border: 1px solid #D4D4D4 !important;
}