@import "../../styles/mixins";

.flowElementCategory {
    padding: 12px 19px;
    border: 1px solid #efefef;
    border-radius: 12px;
    margin-top: 16px;
    background-color: white !important;
    cursor: pointer;

    @include lowResolution {
        padding: 12px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .caption {
            margin-left: 12px;

            span {
                display: block;
            }

            .title {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 5px;

                @include lowResolution {
                    font-size: 14px;
                }
            }

            .description {
                color: #8D8D8D;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @include lowResolution {
                    font-size: 10px;
                }
            }
        }
    }

    .wrapper {
        overflow: hidden;

        .content {
            padding: 12px 0;
            margin-top: -120%;
            transition: all 1s;
        }
    }

    &.animOff {
        transition: none !important;

        .wrapper {
            .content {
                transition: none !important;
            }
        }
    }

    &.expanded {
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);

        .wrapper {
            .content {
                margin-top: 0 !important;
            }
        }
    }

}